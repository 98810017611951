//sass-lint:disable no-important
.flex {
    display: flex !important;
    &-col {
        flex-direction: column !important;
    }
    &-col-reverse {
        flex-direction: column-reverse !important;
    }
    &-row {
        flex-direction: row !important;
    }
    &-row-reverse {
        flex-direction: row-reverse !important;
    }
    &-wrap {
        flex-wrap: wrap !important;
    }
    &-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }
    &-nowrap {
        flex-wrap: nowrap !important;
    }
}
.items {
    &-center {
        align-items: center !important;
    }
    &-start {
        align-items: start !important;
    }
    &-end {
        align-items: end !important;
    }
    &-baseline {
        align-items: baseline !important;
    }
    &-revert {
        align-items: revert !important;
    }
}
.justify {
    &-center {
        justify-content: center !important;
    }
    &-start {
        justify-content: start !important;
    }
    &-end {
        justify-content: end !important;
    }
    &-between {
        justify-content: space-between !important;
    }
    &-around {
        justify-content: space-around !important;
    }
    &-evenly {
        justify-content: space-evenly !important;
    }
    &-revert {
        justify-content: revert !important;
    }
    &-stretch {
        justify-content: stretch !important;
    }
}
@include max-screen($screen-m - 1) {
    .xs {
        &-flex {
            display: flex !important;
            &-col {
                flex-direction: column !important;
            }
            &-col-reverse {
                flex-direction: column-reverse !important;
            }
            &-row {
                flex-direction: row !important;
            }
            &-row-reverse {
                flex-direction: row-reverse !important;
            }
            &-wrap {
                flex-wrap: wrap !important;
            }
            &-wrap-reverse {
                flex-wrap: wrap-reverse !important;
            }
            &-nowrap {
                flex-wrap: nowrap !important;
            }
        }
        &-items {
            &-center {
                align-items: center !important;
            }
            &-start {
                align-items: start !important;
            }
            &-end {
                align-items: end !important;
            }
            &-baseline {
                align-items: baseline !important;
            }
            &-revert {
                align-items: revert !important;
            }
        }
        &-justify {
            &-center {
                justify-content: center !important;
            }
            &-start {
                justify-content: start !important;
            }
            &-end {
                justify-content: end !important;
            }
            &-between {
                justify-content: space-between !important;
            }
            &-around {
                justify-content: space-around !important;
            }
            &-evenly {
                justify-content: space-evenly !important;
            }
            &-revert {
                justify-content: revert !important;
            }
            &-stretch {
                justify-content: stretch !important;
            }
        }
    }
}
@include min-screen($screen-m) {
    .md {
        &-flex {
            display: flex !important;
            &-col {
                flex-direction: column !important;
            }
            &-col-reverse {
                flex-direction: column-reverse !important;
            }
            &-row {
                flex-direction: row !important;
            }
            &-row-reverse {
                flex-direction: row-reverse !important;
            }
            &-wrap {
                flex-wrap: wrap !important;
            }
            &-wrap-reverse {
                flex-wrap: wrap-reverse !important;
            }
            &-nowrap {
                flex-wrap: nowrap !important;
            }
        }
        &-items {
            &-center {
                align-items: center !important;
            }
            &-start {
                align-items: start !important;
            }
            &-end {
                align-items: end !important;
            }
            &-baseline {
                align-items: baseline !important;
            }
            &-revert {
                align-items: revert !important;
            }
        }
        &-justify {
            &-center {
                justify-content: center !important;
            }
            &-start {
                justify-content: start !important;
            }
            &-end {
                justify-content: end !important;
            }
            &-between {
                justify-content: space-between !important;
            }
            &-around {
                justify-content: space-around !important;
            }
            &-evenly {
                justify-content: space-evenly !important;
            }
            &-revert {
                justify-content: revert !important;
            }
            &-stretch {
                justify-content: stretch !important;
            }
        }
    }
}
@include min-screen($screen-l) {
    .lg {
        &-flex {
            display: flex !important;
            &-col {
                flex-direction: column !important;
            }
            &-col-reverse {
                flex-direction: column-reverse !important;
            }
            &-row {
                flex-direction: row !important;
            }
            &-row-reverse {
                flex-direction: row-reverse !important;
            }
            &-wrap {
                flex-wrap: wrap !important;
            }
            &-wrap-reverse {
                flex-wrap: wrap-reverse !important;
            }
            &-nowrap {
                flex-wrap: nowrap !important;
            }
        }
        &-items {
            &-center {
                align-items: center !important;
            }
            &-start {
                align-items: start !important;
            }
            &-end {
                align-items: end !important;
            }
            &-baseline {
                align-items: baseline !important;
            }
            &-revert {
                align-items: revert !important;
            }
        }
        &-justify {
            &-center {
                justify-content: center !important;
            }
            &-start {
                justify-content: start !important;
            }
            &-end {
                justify-content: end !important;
            }
            &-between {
                justify-content: space-between !important;
            }
            &-around {
                justify-content: space-around !important;
            }
            &-evenly {
                justify-content: space-evenly !important;
            }
            &-revert {
                justify-content: revert !important;
            }
            &-stretch {
                justify-content: stretch !important;
            }
        }
    }
}
@include min-screen($screen-xl) {
    .xl {
        &-flex {
            display: flex !important;
            &-col {
                flex-direction: column !important;
            }
            &-col-reverse {
                flex-direction: column-reverse !important;
            }
            &-row {
                flex-direction: row !important;
            }
            &-row-reverse {
                flex-direction: row-reverse !important;
            }
            &-wrap {
                flex-wrap: wrap !important;
            }
            &-wrap-reverse {
                flex-wrap: wrap-reverse !important;
            }
            &-nowrap {
                flex-wrap: nowrap !important;
            }
        }
        &-items {
            &-center {
                align-items: center !important;
            }
            &-start {
                align-items: start !important;
            }
            &-end {
                align-items: end !important;
            }
            &-baseline {
                align-items: baseline !important;
            }
            &-revert {
                align-items: revert !important;
            }
        }
        &-justify {
            &-center {
                justify-content: center !important;
            }
            &-start {
                justify-content: start !important;
            }
            &-end {
                justify-content: end !important;
            }
            &-between {
                justify-content: space-between !important;
            }
            &-around {
                justify-content: space-around !important;
            }
            &-evenly {
                justify-content: space-evenly !important;
            }
            &-revert {
                justify-content: revert !important;
            }
            &-stretch {
                justify-content: stretch !important;
            }
        }
    }
}
@include min-screen($screen-2xl) {
    .x2l {
        &-flex {
            display: flex !important;
            &-col {
                flex-direction: column !important;
            }
            &-col-reverse {
                flex-direction: column-reverse !important;
            }
            &-row {
                flex-direction: row !important;
            }
            &-row-reverse {
                flex-direction: row-reverse !important;
            }
            &-wrap {
                flex-wrap: wrap !important;
            }
            &-wrap-reverse {
                flex-wrap: wrap-reverse !important;
            }
            &-nowrap {
                flex-wrap: nowrap !important;
            }
        }
        &-items {
            &-center {
                align-items: center !important;
            }
            &-start {
                align-items: start !important;
            }
            &-end {
                align-items: end !important;
            }
            &-baseline {
                align-items: baseline !important;
            }
            &-revert {
                align-items: revert !important;
            }
        }
        &-justify {
            &-center {
                justify-content: center !important;
            }
            &-start {
                justify-content: start !important;
            }
            &-end {
                justify-content: end !important;
            }
            &-between {
                justify-content: space-between !important;
            }
            &-around {
                justify-content: space-around !important;
            }
            &-evenly {
                justify-content: space-evenly !important;
            }
            &-revert {
                justify-content: revert !important;
            }
            &-stretch {
                justify-content: stretch !important;
            }
        }
    }
}
@include min-screen($screen-xxl) {
    .xxl {
        &-flex {
            display: flex !important;
            &-col {
                flex-direction: column !important;
            }
            &-col-reverse {
                flex-direction: column-reverse !important;
            }
            &-row {
                flex-direction: row !important;
            }
            &-row-reverse {
                flex-direction: row-reverse !important;
            }
            &-wrap {
                flex-wrap: wrap !important;
            }
            &-wrap-reverse {
                flex-wrap: wrap-reverse !important;
            }
            &-nowrap {
                flex-wrap: nowrap !important;
            }
        }
        &-items {
            &-center {
                align-items: center !important;
            }
            &-start {
                align-items: start !important;
            }
            &-end {
                align-items: end !important;
            }
            &-baseline {
                align-items: baseline !important;
            }
            &-revert {
                align-items: revert !important;
            }
        }
        &-justify {
            &-center {
                justify-content: center !important;
            }
            &-start {
                justify-content: start !important;
            }
            &-end {
                justify-content: end !important;
            }
            &-between {
                justify-content: space-between !important;
            }
            &-around {
                justify-content: space-around !important;
            }
            &-evenly {
                justify-content: space-evenly !important;
            }
            &-revert {
                justify-content: revert !important;
            }
            &-stretch {
                justify-content: stretch !important;
            }
        }
    }
}
@include min-screen($screen-3xl) {
    .x3l {
        &-flex {
            display: flex !important;
            &-col {
                flex-direction: column !important;
            }
            &-col-reverse {
                flex-direction: column-reverse !important;
            }
            &-row {
                flex-direction: row !important;
            }
            &-row-reverse {
                flex-direction: row-reverse !important;
            }
            &-wrap {
                flex-wrap: wrap !important;
            }
            &-wrap-reverse {
                flex-wrap: wrap-reverse !important;
            }
            &-nowrap {
                flex-wrap: nowrap !important;
            }
        }
        &-items {
            &-center {
                align-items: center !important;
            }
            &-start {
                align-items: start !important;
            }
            &-end {
                align-items: end !important;
            }
            &-baseline {
                align-items: baseline !important;
            }
            &-revert {
                align-items: revert !important;
            }
        }
        &-justify {
            &-center {
                justify-content: center !important;
            }
            &-start {
                justify-content: start !important;
            }
            &-end {
                justify-content: end !important;
            }
            &-between {
                justify-content: space-between !important;
            }
            &-around {
                justify-content: space-around !important;
            }
            &-evenly {
                justify-content: space-evenly !important;
            }
            &-revert {
                justify-content: revert !important;
            }
            &-stretch {
                justify-content: stretch !important;
            }
        }
    }
}
@include min-screen($screen-xxxl) {
    .xxxl {        &-flex {
            display: flex !important;
            &-col {
                flex-direction: column !important;
            }
            &-col-reverse {
                flex-direction: column-reverse !important;
            }
            &-row {
                flex-direction: row !important;
            }
            &-row-reverse {
                flex-direction: row-reverse !important;
            }
            &-wrap {
                flex-wrap: wrap !important;
            }
            &-wrap-reverse {
                flex-wrap: wrap-reverse !important;
            }
            &-nowrap {
                flex-wrap: nowrap !important;
            }
        }
        &-items {
            &-center {
                align-items: center !important;
            }
            &-start {
                align-items: start !important;
            }
            &-end {
                align-items: end !important;
            }
            &-baseline {
                align-items: baseline !important;
            }
            &-revert {
                align-items: revert !important;
            }
        }
        &-justify {
            &-center {
                justify-content: center !important;
            }
            &-start {
                justify-content: start !important;
            }
            &-end {
                justify-content: end !important;
            }
            &-between {
                justify-content: space-between !important;
            }
            &-around {
                justify-content: space-around !important;
            }
            &-evenly {
                justify-content: space-evenly !important;
            }
            &-revert {
                justify-content: revert !important;
            }
            &-stretch {
                justify-content: stretch !important;
            }
        }
    }
}
@include min-screen($screen-4xl) {
    .x4l {
        &-flex {
            display: flex !important;
            &-col {
                flex-direction: column !important;
            }
            &-col-reverse {
                flex-direction: column-reverse !important;
            }
            &-row {
                flex-direction: row !important;
            }
            &-row-reverse {
                flex-direction: row-reverse !important;
            }
            &-wrap {
                flex-wrap: wrap !important;
            }
            &-wrap-reverse {
                flex-wrap: wrap-reverse !important;
            }
            &-nowrap {
                flex-wrap: nowrap !important;
            }
        }
        &-items {
            &-center {
                align-items: center !important;
            }
            &-start {
                align-items: start !important;
            }
            &-end {
                align-items: end !important;
            }
            &-baseline {
                align-items: baseline !important;
            }
            &-revert {
                align-items: revert !important;
            }
        }
        &-justify {
            &-center {
                justify-content: center !important;
            }
            &-start {
                justify-content: start !important;
            }
            &-end {
                justify-content: end !important;
            }
            &-between {
                justify-content: space-between !important;
            }
            &-around {
                justify-content: space-around !important;
            }
            &-evenly {
                justify-content: space-evenly !important;
            }
            &-revert {
                justify-content: revert !important;
            }
            &-stretch {
                justify-content: stretch !important;
            }
        }
    }
}
//sass-lint:enable no-important
