$file-upload__margin                                  : $spacer--medium !default;
$file-upload__font-size                               : $font-size-base !default;
$file-upload__button-padding                          : 0 $spacer--large !default;
$file-upload__label-margin--native                    : 0 !default;

$file-upload__attachments-label-margin                : 0 $spacer 0 0 !default;
$file-upload__attachments-button-padding              : 0 !default;
$file-upload__attachments-button-background           : none !default;

$file-upload__attachments-button-content              : none !default;
$file-upload__attachments-button-icon-left            : calc(50% - 12px) !default;
$file-upload__attachments-button-icon-top             : calc(50% - 12px) !default;
$file-upload__attachments-button-icon-width           : 24px !default;
$file-upload__attachments-button-icon-height          : 24px !default;
$file-upload__attachments-button-icon-padding         : 0 !default;
$file-upload__attachments-button-icon-opacity         : 1 !default;
$file-upload__attachments-button-icon-transform       : translateY(0) !default;
$file-upload__attachments-button-icon-transform--hover: rotate(180deg) !default;
