$container__max-width     : $max-content-width !default;
$container__margin        : $spacer !default;
$container__margin\@medium: $spacer !default;
$container__margin\@large : $spacer--semi-large !default;

.container {
    margin: 0 $container__margin;
    max-width: calc(100% - 16px);
    @include min-screen($screen-m) {
        margin: 0 $container__margin\@medium;
    }

    @include min-screen($screen-l) {
        max-width: $container__max-width;
        margin: 0 $container__margin\@large;
    }

    @include min-screen($container__max-width + 2 * $container__margin) {
        margin: 0 auto;
    }
}
