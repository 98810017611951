//prevent document from scrolling
//sass-lint:disable no-important
.no-scroll {
    overflow: hidden !important;
    height: 100% !important;
}
.overflow-hidden {
    overflow: hidden !important;
}
.margin {
    &-0 {
        margin: 0 !important;
    }

    &-xs {
        margin: $spacer !important;
    }

    &-s {
        margin: $spacer--small !important;
    }

    &-m {
        margin: $spacer--medium !important;
    }

    &-sm {
        margin: $spacer--semi-medium !important;
    }

    &-l {
        margin: $spacer--large !important;
    }

    &-sl {
        margin: $spacer--semi-large !important;
    }

    &-xl {
        margin: $spacer--extra-large !important;
    }

    &-auto {
        margin: auto !important;
    }

    // margin-top
    &-top {
        &-0 {
            margin-top: 0 !important;
        }

        &-xs {
            margin-top: $spacer !important;
        }

        &-s {
            margin-top: $spacer--small !important;
        }

        &-m {
            margin-top: $spacer--medium !important;
        }

        &-sm {
            margin-top: $spacer--semi-medium !important;
        }

        &-l {
            margin-top: $spacer--large !important;
        }

        &-sl {
            margin-top: $spacer--semi-large !important;
        }

        &-xl {
            margin-top: $spacer--extra-large !important;
        }

        &-auto {
            margin-top: auto !important;
        }
    }

    // margin-bottom
    &-bottom {
        &-0 {
            margin-bottom: 0 !important;
        }

        &-xs {
            margin-bottom: $spacer !important;
        }

        &-s {
            margin-bottom: $spacer--small !important;
        }

        &-m {
            margin-bottom: $spacer--medium !important;
        }

        &-sm {
            margin-bottom: $spacer--semi-medium !important;
        }

        &-l {
            margin-bottom: $spacer--large !important;
        }

        &-sl {
            margin-bottom: $spacer--semi-large !important;
        }

        &-xl {
            margin-bottom: $spacer--extra-large !important;
        }

        &-auto {
            margin-bottom: auto !important;
        }
    }

    // margin-left
    &-left {
        &-0 {
            margin-left: 0 !important;
        }

        &-xs {
            margin-left: $spacer !important;
        }

        &-s {
            margin-left: $spacer--small !important;
        }

        &-m {
            margin-left: $spacer--medium !important;
        }

        &-sm {
            margin-left: $spacer--semi-medium !important;
        }

        &-l {
            margin-left: $spacer--large !important;
        }

        &-sl {
            margin-left: $spacer--semi-large !important;
        }

        &-xl {
            margin-left: $spacer--extra-large !important;
        }

        &-auto {
            margin-left: auto !important;
        }
    }

    // margin-right
    &-right {
        &-0 {
            margin-right: 0 !important;
        }

        &-xs {
            margin-right: $spacer !important;
        }

        &-s {
            margin-right: $spacer--small !important;
        }

        &-m {
            margin-right: $spacer--medium !important;
        }

        &-sm {
            margin-right: $spacer--semi-medium !important;
        }

        &-l {
            margin-right: $spacer--large !important;
        }

        &-sl {
            margin-right: $spacer--semi-large !important;
        }

        &-xl {
            margin-right: $spacer--extra-large !important;
        }

        &-auto {
            margin-right: auto !important;
        }
    }

    // margin horizontal
    &-hz {
        &-0 {
            margin-left: 0 !important;
            margin-right: 0 !important;
        }

        &-xs {
            margin-left: $spacer !important;
            margin-right: $spacer !important;
        }

        &-s {
            margin-left: $spacer--small !important;
            margin-right: $spacer--small !important;
        }

        &-m {
            margin-left: $spacer--medium !important;
            margin-right: $spacer--medium !important;
        }

        &-sm {
            margin-left: $spacer--semi-medium !important;
            margin-right: $spacer--semi-medium !important;
        }

        &-l {
            margin-left: $spacer--large !important;
            margin-right: $spacer--large !important;
        }

        &-sl {
            margin-left: $spacer--semi-large !important;
            margin-right: $spacer--semi-large !important;
        }

        &-xl {
            margin-left: $spacer--extra-large !important;
            margin-right: $spacer--extra-large !important;
        }

        &-auto {
            margin-left: auto !important;
            margin-right: auto !important;
        }
    }

    // margin vertical
    &-vc {
        &-0 {
            margin-top: 0 !important;
            margin-bottom: 0 !important;
        }

        &-xs {
            margin-top: $spacer !important;
            margin-bottom: $spacer !important;
        }

        &-s {
            margin-top: $spacer--small !important;
            margin-bottom: $spacer--small !important;
        }

        &-m {
            margin-top: $spacer--medium !important;
            margin-bottom: $spacer--medium !important;
        }

        &-sm {
            margin-top: $spacer--semi-medium !important;
            margin-bottom: $spacer--semi-medium !important;
        }

        &-l {
            margin-top: $spacer--large !important;
            margin-bottom: $spacer--large !important;
        }

        &-sl {
            margin-top: $spacer--semi-large !important;
            margin-bottom: $spacer--semi-large !important;
        }

        &-xl {
            margin-top: $spacer--extra-large !important;
            margin-bottom: $spacer--extra-large !important;
        }

        &-auto {
            margin-top: auto !important;
            margin-bottom: auto !important;
        }
    }
}
.m {
    &-0 {
        margin: 0 !important;
    }
    &-1 {
        margin: $spacer--extra-small !important;
    }
    &-2 {
        margin: $spacer !important;
    }
    &-3 {
        margin: $spacer--small !important;
    }
    &-4 {
        margin: $spacer--medium !important;
    }
    &-5 {
        margin: $spacer--m !important;
    }
    &-6 {
        margin: $spacer--semi-medium !important;
    }
    &-7 {
        margin: $spacer--large !important;
    }
    &-8 {
        margin: $spacer--l !important;
    }
    &-9 {
        margin: $spacer--semi-large !important;
    }
    &-10 {
        margin: $spacer--xl !important;
    }
    &-11 {
        margin: $spacer--extra-large !important;
    }
    &-12 {
        margin: $spacer--xxl !important;
    }
    &-13 {
        margin: $spacer--extra-extra-large !important;
    }
    &-14 {
        margin: $spacer--larger !important;
    }
    &-xs {
        margin: $spacer--extra-small !important;
    }
    &-s {
        margin: $spacer--small !important;
    }
    &-default {
        margin: $spacer !important;
    }
    &-m {
        margin: $spacer--medium !important;
    }
    &-l {
        margin: $spacer--large !important;
    }
    &-xl {
        margin: $spacer--extra-large !important;
    }
    &-xxl {
        margin: $spacer--extra-extra-large !important;
    }
    &-vc {
        &-0 {
            margin-top: 0 !important;
            margin-bottom: 0 !important;
        }
        &-1 {
            margin-top: $spacer--extra-small !important;
            margin-bottom: $spacer--extra-small !important;
        }
        &-2 {
            margin-top: $spacer !important;
            margin-bottom: $spacer !important;
        }
        &-3 {
            margin-top: $spacer--small !important;
            margin-bottom: $spacer--small !important;
        }
        &-4 {
            margin-top: $spacer--medium !important;
            margin-bottom: $spacer--medium !important;
        }
        &-5 {
            margin-top: $spacer--m !important;
            margin-bottom: $spacer--m !important;
        }
        &-6 {
            margin-top: $spacer--semi-medium !important;
            margin-bottom: $spacer--semi-medium !important;
        }
        &-7 {
            margin-top: $spacer--large !important;
            margin-bottom: $spacer--large !important;
        }
        &-8 {
            margin-top: $spacer--l !important;
            margin-bottom: $spacer--l !important;
        }
        &-9 {
            margin-top: $spacer--semi-large !important;
            margin-bottom: $spacer--semi-large !important;
        }
        &-10 {
            margin-top: $spacer--xl !important;
            margin-bottom: $spacer--xl !important;
        }
        &-11 {
            margin-top: $spacer--extra-large !important;
            margin-bottom: $spacer--extra-large !important;
        }
        &-12 {
            margin-top: $spacer--xxl !important;
            margin-bottom: $spacer--xxl !important;
        }
        &-13 {
            margin-top: $spacer--extra-extra-large !important;
            margin-bottom: $spacer--extra-extra-large !important;
        }
        &-14 {
            margin-top: $spacer--larger !important;
            margin-bottom: $spacer--larger !important;
        }
        &-xs {
            margin-top: $spacer--extra-small !important;
            margin-bottom: $spacer--extra-small !important;
        }
        &-s {
            margin-top: $spacer--small !important;
            margin-bottom: $spacer--small !important;
        }
        &-default {
            margin-top: $spacer !important;
            margin-bottom: $spacer !important;
        }
        &-m {
            margin-top: $spacer--medium !important;
            margin-bottom: $spacer--medium !important;
        }
        &-l {
            margin-top: $spacer--large !important;
            margin-bottom: $spacer--large !important;
        }
        &-xl {
            margin-top: $spacer--extra-large !important;
            margin-bottom: $spacer--extra-large !important;
        }
        &-xxl {
            margin-top: $spacer--extra-extra-large !important;
            margin-bottom: $spacer--extra-extra-large !important;
        }
    }
    &-hz {
        &-0 {
            margin-right: 0 !important;
            margin-left: 0 !important;
        }
        &-1 {
            margin-right: $spacer--extra-small !important;
            margin-left: $spacer--extra-small !important;
        }
        &-2 {
            margin-right: $spacer !important;
            margin-left: $spacer !important;
        }
        &-3 {
            margin-right: $spacer--small !important;
            margin-left: $spacer--small !important;
        }
        &-4 {
            margin-right: $spacer--medium !important;
            margin-left: $spacer--medium !important;
        }
        &-5 {
            margin-right: $spacer--m !important;
            margin-left: $spacer--m !important;
        }
        &-6 {
            margin-right: $spacer--semi-medium !important;
            margin-left: $spacer--semi-medium !important;
        }
        &-7 {
            margin-right: $spacer--large !important;
            margin-left: $spacer--large !important;
        }
        &-8 {
            margin-right: $spacer--l !important;
            margin-left: $spacer--l !important;
        }
        &-9 {
            margin-right: $spacer--semi-large !important;
            margin-left: $spacer--semi-large !important;
        }
        &-10 {
            margin-right: $spacer--xl !important;
            margin-left: $spacer--xl !important;
        }
        &-11 {
            margin-right: $spacer--extra-large !important;
            margin-left: $spacer--extra-large !important;
        }
        &-12 {
            margin-right: $spacer--xxl !important;
            margin-left: $spacer--xxl !important;
        }
        &-13 {
            margin-right: $spacer--extra-extra-large !important;
            margin-left: $spacer--extra-extra-large !important;
        }
        &-14 {
            margin-right: $spacer--larger !important;
            margin-left: $spacer--larger !important;
        }
        &-xs {
            margin-left: $spacer--extra-small !important;
            margin-right: $spacer--extra-small !important;
        }
        &-s {
            margin-left: $spacer--small !important;
            margin-right: $spacer--small !important;
        }
        &-default {
            margin-left: $spacer !important;
            margin-right: $spacer !important;
        }
        &-m {
            margin-left: $spacer--medium !important;
            margin-right: $spacer--medium !important;
        }
        &-l {
            margin-left: $spacer--large !important;
            margin-right: $spacer--large !important;
        }
        &-xl {
            margin-left: $spacer--extra-large !important;
            margin-right: $spacer--extra-large !important;
        }
        &-xxl {
            margin-left: $spacer--extra-extra-large !important;
            margin-right: $spacer--extra-extra-large !important;
        }
    }
    &t {
        &-0 {
            margin-top: 0 !important;
        }
        &-1 {
            margin-top: $spacer--extra-small !important;
        }
        &-2 {
            margin-top: $spacer !important;
        }
        &-3 {
            margin-top: $spacer--small !important;
        }
        &-4 {
            margin-top: $spacer--medium !important;
        }
        &-5 {
            margin-top: $spacer--m !important;
        }
        &-6 {
            margin-top: $spacer--semi-medium !important;
        }
        &-7 {
            margin-top: $spacer--large !important;
        }
        &-8 {
            margin-top: $spacer--l !important;
        }
        &-9 {
            margin-top: $spacer--semi-large !important;
        }
        &-10 {
            margin-top: $spacer--xl !important;
        }
        &-11 {
            margin-top: $spacer--extra-large !important;
        }
        &-12 {
            margin-top: $spacer--xxl !important;
        }
        &-13 {
            margin-top: $spacer--extra-extra-large !important;
        }
        &-14 {
            margin-top: $spacer--larger !important;
        }
        &-xs {
            margin-top: $spacer--extra-small !important;
        }
        &-s {
            margin-top: $spacer--small !important;
        }
        &-default {
            margin-top: $spacer !important;
        }
        &-m {
            margin-top: $spacer--medium !important;
        }
        &-l {
            margin-top: $spacer--large !important;
        }
        &-xl {
            margin-top: $spacer--extra-large !important;
        }
        &-xxl {
            margin-top: $spacer--extra-extra-large !important;
        }
    }
    &r {
        &-0 {
            margin-right: 0 !important;
        }
        &-1 {
            margin-right: $spacer--extra-small !important;
        }
        &-2 {
            margin-right: $spacer !important;
        }
        &-3 {
            margin-right: $spacer--small !important;
        }
        &-4 {
            margin-right: $spacer--medium !important;
        }
        &-5 {
            margin-right: $spacer--m !important;
        }
        &-6 {
            margin-right: $spacer--semi-medium !important;
        }
        &-7 {
            margin-right: $spacer--large !important;
        }
        &-8 {
            margin-right: $spacer--l !important;
        }
        &-9 {
            margin-right: $spacer--semi-large !important;
        }
        &-10 {
            margin-right: $spacer--xl !important;
        }
        &-11 {
            margin-right: $spacer--extra-large !important;
        }
        &-12 {
            margin-right: $spacer--xxl !important;
        }
        &-13 {
            margin-right: $spacer--extra-extra-large !important;
        }
        &-14 {
            margin-right: $spacer--larger !important;
        }
        &-xs {
            margin-right: $spacer--extra-small !important;
        }
        &-s {
            margin-right: $spacer--small !important;
        }
        &-default {
            margin-right: $spacer !important;
        }
        &-m {
            margin-right: $spacer--medium !important;
        }
        &-l {
            margin-right: $spacer--large !important;
        }
        &-xl {
            margin-right: $spacer--extra-large !important;
        }
        &-xxl {
            margin-right: $spacer--extra-extra-large !important;
        }
    }
    &b {
        &-0 {
            margin-bottom: 0 !important;
        }
        &-1 {
            margin-bottom: $spacer--extra-small !important;
        }
        &-2 {
            margin-bottom: $spacer !important;
        }
        &-3 {
            margin-bottom: $spacer--small !important;
        }
        &-4 {
            margin-bottom: $spacer--medium !important;
        }
        &-5 {
            margin-bottom: $spacer--m !important;
        }
        &-6 {
            margin-bottom: $spacer--semi-medium !important;
        }
        &-7 {
            margin-bottom: $spacer--large !important;
        }
        &-8 {
            margin-bottom: $spacer--l !important;
        }
        &-9 {
            margin-bottom: $spacer--semi-large !important;
        }
        &-10 {
            margin-bottom: $spacer--xl !important;
        }
        &-11 {
            margin-bottom: $spacer--extra-large !important;
        }
        &-12 {
            margin-bottom: $spacer--xxl !important;
        }
        &-13 {
            margin-bottom: $spacer--extra-extra-large !important;
        }
        &-14 {
            margin-bottom: $spacer--larger !important;
        }
        &-xs {
            margin-bottom: $spacer--extra-small !important;
        }
        &-s {
            margin-bottom: $spacer--small !important;
        }
        &-default {
            margin-bottom: $spacer !important;
        }
        &-m {
            margin-bottom: $spacer--medium !important;
        }
        &-l {
            margin-bottom: $spacer--large !important;
        }
        &-xl {
            margin-bottom: $spacer--extra-large !important;
        }
        &-xxl {
            margin-bottom: $spacer--extra-extra-large !important;
        }
    }
    &l {
        &-0 {
            margin-left: 0 !important;
        }
        &-1 {
            margin-left: $spacer--extra-small !important;
        }
        &-2 {
            margin-left: $spacer !important;
        }
        &-3 {
            margin-left: $spacer--small !important;
        }
        &-4 {
            margin-left: $spacer--medium !important;
        }
        &-5 {
            margin-left: $spacer--m !important;
        }
        &-6 {
            margin-left: $spacer--semi-medium !important;
        }
        &-7 {
            margin-left: $spacer--large !important;
        }
        &-8 {
            margin-left: $spacer--l !important;
        }
        &-9 {
            margin-left: $spacer--semi-large !important;
        }
        &-10 {
            margin-left: $spacer--xl !important;
        }
        &-11 {
            margin-left: $spacer--extra-large !important;
        }
        &-12 {
            margin-left: $spacer--xxl !important;
        }
        &-13 {
            margin-left: $spacer--extra-extra-large !important;
        }
        &-14 {
            margin-left: $spacer--larger !important;
        }
        &-xs {
            margin-left: $spacer--extra-small !important;
        }
        &-s {
            margin-left: $spacer--small !important;
        }
        &-default {
            margin-left: $spacer !important;
        }
        &-m {
            margin-left: $spacer--medium !important;
        }
        &-l {
            margin-left: $spacer--large !important;
        }
        &-xl {
            margin-left: $spacer--extra-large !important;
        }
        &-xxl {
            margin-left: $spacer--extra-extra-large !important;
        }
    }
}
@include max-screen($screen-m) {
    .xs {
        &-m {
            &-0 {
                margin: 0 !important;
            }
            &-1 {
                margin: $spacer--extra-small !important;
            }
            &-2 {
                margin: $spacer !important;
            }
            &-3 {
                margin: $spacer--small !important;
            }
            &-4 {
                margin: $spacer--medium !important;
            }
            &-5 {
                margin: $spacer--m !important;
            }
            &-6 {
                margin: $spacer--semi-medium !important;
            }
            &-7 {
                margin: $spacer--large !important;
            }
            &-8 {
                margin: $spacer--l !important;
            }
            &-9 {
                margin: $spacer--semi-large !important;
            }
            &-10 {
                margin: $spacer--xl !important;
            }
            &-11 {
                margin: $spacer--extra-large !important;
            }
            &-12 {
                margin: $spacer--xxl !important;
            }
            &-13 {
                margin: $spacer--extra-extra-large !important;
            }
            &-14 {
                margin: $spacer--larger !important;
            }
            &-xs {
                margin: $spacer--extra-small !important;
            }
            &-s {
                margin: $spacer--small !important;
            }
            &-default {
                margin: $spacer !important;
            }
            &-m {
                margin: $spacer--medium !important;
            }
            &-l {
                margin: $spacer--large !important;
            }
            &-xl {
                margin: $spacer--extra-large !important;
            }
            &-xxl {
                margin: $spacer--extra-extra-large !important;
            }
            &-vc {
                &-0 {
                    margin-top: 0 !important;
                    margin-bottom: 0 !important;
                }
                &-1 {
                    margin-top: $spacer--extra-small !important;
                    margin-bottom: $spacer--extra-small !important;
                }
                &-2 {
                    margin-top: $spacer !important;
                    margin-bottom: $spacer !important;
                }
                &-3 {
                    margin-top: $spacer--small !important;
                    margin-bottom: $spacer--small !important;
                }
                &-4 {
                    margin-top: $spacer--medium !important;
                    margin-bottom: $spacer--medium !important;
                }
                &-5 {
                    margin-top: $spacer--m !important;
                    margin-bottom: $spacer--m !important;
                }
                &-6 {
                    margin-top: $spacer--semi-medium !important;
                    margin-bottom: $spacer--semi-medium !important;
                }
                &-7 {
                    margin-top: $spacer--large !important;
                    margin-bottom: $spacer--large !important;
                }
                &-8 {
                    margin-top: $spacer--l !important;
                    margin-bottom: $spacer--l !important;
                }
                &-9 {
                    margin-top: $spacer--semi-large !important;
                    margin-bottom: $spacer--semi-large !important;
                }
                &-10 {
                    margin-top: $spacer--xl !important;
                    margin-bottom: $spacer--xl !important;
                }
                &-11 {
                    margin-top: $spacer--extra-large !important;
                    margin-bottom: $spacer--extra-large !important;
                }
                &-12 {
                    margin-top: $spacer--xxl !important;
                    margin-bottom: $spacer--xxl !important;
                }
                &-13 {
                    margin-top: $spacer--extra-extra-large !important;
                    margin-bottom: $spacer--extra-extra-large !important;
                }
                &-14 {
                    margin-top: $spacer--larger !important;
                    margin-bottom: $spacer--larger !important;
                }
                &-xs {
                    margin-top: $spacer--extra-small !important;
                    margin-bottom: $spacer--extra-small !important;
                }
                &-s {
                    margin-top: $spacer--small !important;
                    margin-bottom: $spacer--small !important;
                }
                &-default {
                    margin-top: $spacer !important;
                    margin-bottom: $spacer !important;
                }
                &-m {
                    margin-top: $spacer--medium !important;
                    margin-bottom: $spacer--medium !important;
                }
                &-l {
                    margin-top: $spacer--large !important;
                    margin-bottom: $spacer--large !important;
                }
                &-xl {
                    margin-top: $spacer--extra-large !important;
                    margin-bottom: $spacer--extra-large !important;
                }
                &-xxl {
                    margin-top: $spacer--extra-extra-large !important;
                    margin-bottom: $spacer--extra-extra-large !important;
                }
            }
            &-hz {
                &-0 {
                    margin-right: 0 !important;
                    margin-left: 0 !important;
                }
                &-1 {
                    margin-right: $spacer--extra-small !important;
                    margin-left: $spacer--extra-small !important;
                }
                &-2 {
                    margin-right: $spacer !important;
                    margin-left: $spacer !important;
                }
                &-3 {
                    margin-right: $spacer--small !important;
                    margin-left: $spacer--small !important;
                }
                &-4 {
                    margin-right: $spacer--medium !important;
                    margin-left: $spacer--medium !important;
                }
                &-5 {
                    margin-right: $spacer--m !important;
                    margin-left: $spacer--m !important;
                }
                &-6 {
                    margin-right: $spacer--semi-medium !important;
                    margin-left: $spacer--semi-medium !important;
                }
                &-7 {
                    margin-right: $spacer--large !important;
                    margin-left: $spacer--large !important;
                }
                &-8 {
                    margin-right: $spacer--l !important;
                    margin-left: $spacer--l !important;
                }
                &-9 {
                    margin-right: $spacer--semi-large !important;
                    margin-left: $spacer--semi-large !important;
                }
                &-10 {
                    margin-right: $spacer--xl !important;
                    margin-left: $spacer--xl !important;
                }
                &-11 {
                    margin-right: $spacer--extra-large !important;
                    margin-left: $spacer--extra-large !important;
                }
                &-12 {
                    margin-right: $spacer--xxl !important;
                    margin-left: $spacer--xxl !important;
                }
                &-13 {
                    margin-right: $spacer--extra-extra-large !important;
                    margin-left: $spacer--extra-extra-large !important;
                }
                &-14 {
                    margin-right: $spacer--larger !important;
                    margin-left: $spacer--larger !important;
                }
                &-xs {
                    margin-left: $spacer--extra-small !important;
                    margin-right: $spacer--extra-small !important;
                }
                &-s {
                    margin-left: $spacer--small !important;
                    margin-right: $spacer--small !important;
                }
                &-default {
                    margin-left: $spacer !important;
                    margin-right: $spacer !important;
                }
                &-m {
                    margin-left: $spacer--medium !important;
                    margin-right: $spacer--medium !important;
                }
                &-l {
                    margin-left: $spacer--large !important;
                    margin-right: $spacer--large !important;
                }
                &-xl {
                    margin-left: $spacer--extra-large !important;
                    margin-right: $spacer--extra-large !important;
                }
                &-xxl {
                    margin-left: $spacer--extra-extra-large !important;
                    margin-right: $spacer--extra-extra-large !important;
                }
            }
            &t {
                &-0 {
                    margin-top: 0 !important;
                }
                &-1 {
                    margin-top: $spacer--extra-small !important;
                }
                &-2 {
                    margin-top: $spacer !important;
                }
                &-3 {
                    margin-top: $spacer--small !important;
                }
                &-4 {
                    margin-top: $spacer--medium !important;
                }
                &-5 {
                    margin-top: $spacer--m !important;
                }
                &-6 {
                    margin-top: $spacer--semi-medium !important;
                }
                &-7 {
                    margin-top: $spacer--large !important;
                }
                &-8 {
                    margin-top: $spacer--l !important;
                }
                &-9 {
                    margin-top: $spacer--semi-large !important;
                }
                &-10 {
                    margin-top: $spacer--xl !important;
                }
                &-11 {
                    margin-top: $spacer--extra-large !important;
                }
                &-12 {
                    margin-top: $spacer--xxl !important;
                }
                &-13 {
                    margin-top: $spacer--extra-extra-large !important;
                }
                &-14 {
                    margin-top: $spacer--larger !important;
                }
                &-xs {
                    margin-top: $spacer--extra-small !important;
                }
                &-s {
                    margin-top: $spacer--small !important;
                }
                &-default {
                    margin-top: $spacer !important;
                }
                &-m {
                    margin-top: $spacer--medium !important;
                }
                &-l {
                    margin-top: $spacer--large !important;
                }
                &-xl {
                    margin-top: $spacer--extra-large !important;
                }
                &-xxl {
                    margin-top: $spacer--extra-extra-large !important;
                }
            }
            &r {
                &-0 {
                    margin-right: 0 !important;
                }
                &-1 {
                    margin-right: $spacer--extra-small !important;
                }
                &-2 {
                    margin-right: $spacer !important;
                }
                &-3 {
                    margin-right: $spacer--small !important;
                }
                &-4 {
                    margin-right: $spacer--medium !important;
                }
                &-5 {
                    margin-right: $spacer--m !important;
                }
                &-6 {
                    margin-right: $spacer--semi-medium !important;
                }
                &-7 {
                    margin-right: $spacer--large !important;
                }
                &-8 {
                    margin-right: $spacer--l !important;
                }
                &-9 {
                    margin-right: $spacer--semi-large !important;
                }
                &-10 {
                    margin-right: $spacer--xl !important;
                }
                &-11 {
                    margin-right: $spacer--extra-large !important;
                }
                &-12 {
                    margin-right: $spacer--xxl !important;
                }
                &-13 {
                    margin-right: $spacer--extra-extra-large !important;
                }
                &-14 {
                    margin-right: $spacer--larger !important;
                }
                &-xs {
                    margin-right: $spacer--extra-small !important;
                }
                &-s {
                    margin-right: $spacer--small !important;
                }
                &-default {
                    margin-right: $spacer !important;
                }
                &-m {
                    margin-right: $spacer--medium !important;
                }
                &-l {
                    margin-right: $spacer--large !important;
                }
                &-xl {
                    margin-right: $spacer--extra-large !important;
                }
                &-xxl {
                    margin-right: $spacer--extra-extra-large !important;
                }
            }
            &b {
                &-0 {
                    margin-bottom: 0 !important;
                }
                &-1 {
                    margin-bottom: $spacer--extra-small !important;
                }
                &-2 {
                    margin-bottom: $spacer !important;
                }
                &-3 {
                    margin-bottom: $spacer--small !important;
                }
                &-4 {
                    margin-bottom: $spacer--medium !important;
                }
                &-5 {
                    margin-bottom: $spacer--m !important;
                }
                &-6 {
                    margin-bottom: $spacer--semi-medium !important;
                }
                &-7 {
                    margin-bottom: $spacer--large !important;
                }
                &-8 {
                    margin-bottom: $spacer--l !important;
                }
                &-9 {
                    margin-bottom: $spacer--semi-large !important;
                }
                &-10 {
                    margin-bottom: $spacer--xl !important;
                }
                &-11 {
                    margin-bottom: $spacer--extra-large !important;
                }
                &-12 {
                    margin-bottom: $spacer--xxl !important;
                }
                &-13 {
                    margin-bottom: $spacer--extra-extra-large !important;
                }
                &-14 {
                    margin-bottom: $spacer--larger !important;
                }
                &-xs {
                    margin-bottom: $spacer--extra-small !important;
                }
                &-s {
                    margin-bottom: $spacer--small !important;
                }
                &-default {
                    margin-bottom: $spacer !important;
                }
                &-m {
                    margin-bottom: $spacer--medium !important;
                }
                &-l {
                    margin-bottom: $spacer--large !important;
                }
                &-xl {
                    margin-bottom: $spacer--extra-large !important;
                }
                &-xxl {
                    margin-bottom: $spacer--extra-extra-large !important;
                }
            }
            &l {
                &-0 {
                    margin-left: 0 !important;
                }
                &-1 {
                    margin-left: $spacer--extra-small !important;
                }
                &-2 {
                    margin-left: $spacer !important;
                }
                &-3 {
                    margin-left: $spacer--small !important;
                }
                &-4 {
                    margin-left: $spacer--medium !important;
                }
                &-5 {
                    margin-left: $spacer--m !important;
                }
                &-6 {
                    margin-left: $spacer--semi-medium !important;
                }
                &-7 {
                    margin-left: $spacer--large !important;
                }
                &-8 {
                    margin-left: $spacer--l !important;
                }
                &-9 {
                    margin-left: $spacer--semi-large !important;
                }
                &-10 {
                    margin-left: $spacer--xl !important;
                }
                &-11 {
                    margin-left: $spacer--extra-large !important;
                }
                &-12 {
                    margin-left: $spacer--xxl !important;
                }
                &-13 {
                    margin-left: $spacer--extra-extra-large !important;
                }
                &-14 {
                    margin-left: $spacer--larger !important;
                }
                &-xs {
                    margin-left: $spacer--extra-small !important;
                }
                &-s {
                    margin-left: $spacer--small !important;
                }
                &-default {
                    margin-left: $spacer !important;
                }
                &-m {
                    margin-left: $spacer--medium !important;
                }
                &-l {
                    margin-left: $spacer--large !important;
                }
                &-xl {
                    margin-left: $spacer--extra-large !important;
                }
                &-xxl {
                    margin-left: $spacer--extra-extra-large !important;
                }
            }
        }
    }
}
@include min-screen($screen-m) {
    .md {
        &-m {
            &-0 {
                margin: 0 !important;
            }
            &-1 {
                margin: $spacer--extra-small !important;
            }
            &-2 {
                margin: $spacer !important;
            }
            &-3 {
                margin: $spacer--small !important;
            }
            &-4 {
                margin: $spacer--medium !important;
            }
            &-5 {
                margin: $spacer--m !important;
            }
            &-6 {
                margin: $spacer--semi-medium !important;
            }
            &-7 {
                margin: $spacer--large !important;
            }
            &-8 {
                margin: $spacer--l !important;
            }
            &-9 {
                margin: $spacer--semi-large !important;
            }
            &-10 {
                margin: $spacer--xl !important;
            }
            &-11 {
                margin: $spacer--extra-large !important;
            }
            &-12 {
                margin: $spacer--xxl !important;
            }
            &-13 {
                margin: $spacer--extra-extra-large !important;
            }
            &-14 {
                margin: $spacer--larger !important;
            }
            &-xs {
                margin: $spacer--extra-small !important;
            }
            &-s {
                margin: $spacer--small !important;
            }
            &-default {
                margin: $spacer !important;
            }
            &-m {
                margin: $spacer--medium !important;
            }
            &-l {
                margin: $spacer--large !important;
            }
            &-xl {
                margin: $spacer--extra-large !important;
            }
            &-xxl {
                margin: $spacer--extra-extra-large !important;
            }
            &-vc {
                &-0 {
                    margin-top: 0 !important;
                    margin-bottom: 0 !important;
                }
                &-1 {
                    margin-top: $spacer--extra-small !important;
                    margin-bottom: $spacer--extra-small !important;
                }
                &-2 {
                    margin-top: $spacer !important;
                    margin-bottom: $spacer !important;
                }
                &-3 {
                    margin-top: $spacer--small !important;
                    margin-bottom: $spacer--small !important;
                }
                &-4 {
                    margin-top: $spacer--medium !important;
                    margin-bottom: $spacer--medium !important;
                }
                &-5 {
                    margin-top: $spacer--m !important;
                    margin-bottom: $spacer--m !important;
                }
                &-6 {
                    margin-top: $spacer--semi-medium !important;
                    margin-bottom: $spacer--semi-medium !important;
                }
                &-7 {
                    margin-top: $spacer--large !important;
                    margin-bottom: $spacer--large !important;
                }
                &-8 {
                    margin-top: $spacer--l !important;
                    margin-bottom: $spacer--l !important;
                }
                &-9 {
                    margin-top: $spacer--semi-large !important;
                    margin-bottom: $spacer--semi-large !important;
                }
                &-10 {
                    margin-top: $spacer--xl !important;
                    margin-bottom: $spacer--xl !important;
                }
                &-11 {
                    margin-top: $spacer--extra-large !important;
                    margin-bottom: $spacer--extra-large !important;
                }
                &-12 {
                    margin-top: $spacer--xxl !important;
                    margin-bottom: $spacer--xxl !important;
                }
                &-13 {
                    margin-top: $spacer--extra-extra-large !important;
                    margin-bottom: $spacer--extra-extra-large !important;
                }
                &-14 {
                    margin-top: $spacer--larger !important;
                    margin-bottom: $spacer--larger !important;
                }
                &-xs {
                    margin-top: $spacer--extra-small !important;
                    margin-bottom: $spacer--extra-small !important;
                }
                &-s {
                    margin-top: $spacer--small !important;
                    margin-bottom: $spacer--small !important;
                }
                &-default {
                    margin-top: $spacer !important;
                    margin-bottom: $spacer !important;
                }
                &-m {
                    margin-top: $spacer--medium !important;
                    margin-bottom: $spacer--medium !important;
                }
                &-l {
                    margin-top: $spacer--large !important;
                    margin-bottom: $spacer--large !important;
                }
                &-xl {
                    margin-top: $spacer--extra-large !important;
                    margin-bottom: $spacer--extra-large !important;
                }
                &-xxl {
                    margin-top: $spacer--extra-extra-large !important;
                    margin-bottom: $spacer--extra-extra-large !important;
                }
            }
            &-hz {
                &-0 {
                    margin-right: 0 !important;
                    margin-left: 0 !important;
                }
                &-1 {
                    margin-right: $spacer--extra-small !important;
                    margin-left: $spacer--extra-small !important;
                }
                &-2 {
                    margin-right: $spacer !important;
                    margin-left: $spacer !important;
                }
                &-3 {
                    margin-right: $spacer--small !important;
                    margin-left: $spacer--small !important;
                }
                &-4 {
                    margin-right: $spacer--medium !important;
                    margin-left: $spacer--medium !important;
                }
                &-5 {
                    margin-right: $spacer--m !important;
                    margin-left: $spacer--m !important;
                }
                &-6 {
                    margin-right: $spacer--semi-medium !important;
                    margin-left: $spacer--semi-medium !important;
                }
                &-7 {
                    margin-right: $spacer--large !important;
                    margin-left: $spacer--large !important;
                }
                &-8 {
                    margin-right: $spacer--l !important;
                    margin-left: $spacer--l !important;
                }
                &-9 {
                    margin-right: $spacer--semi-large !important;
                    margin-left: $spacer--semi-large !important;
                }
                &-10 {
                    margin-right: $spacer--xl !important;
                    margin-left: $spacer--xl !important;
                }
                &-11 {
                    margin-right: $spacer--extra-large !important;
                    margin-left: $spacer--extra-large !important;
                }
                &-12 {
                    margin-right: $spacer--xxl !important;
                    margin-left: $spacer--xxl !important;
                }
                &-13 {
                    margin-right: $spacer--extra-extra-large !important;
                    margin-left: $spacer--extra-extra-large !important;
                }
                &-14 {
                    margin-right: $spacer--larger !important;
                    margin-left: $spacer--larger !important;
                }
                &-xs {
                    margin-left: $spacer--extra-small !important;
                    margin-right: $spacer--extra-small !important;
                }
                &-s {
                    margin-left: $spacer--small !important;
                    margin-right: $spacer--small !important;
                }
                &-default {
                    margin-left: $spacer !important;
                    margin-right: $spacer !important;
                }
                &-m {
                    margin-left: $spacer--medium !important;
                    margin-right: $spacer--medium !important;
                }
                &-l {
                    margin-left: $spacer--large !important;
                    margin-right: $spacer--large !important;
                }
                &-xl {
                    margin-left: $spacer--extra-large !important;
                    margin-right: $spacer--extra-large !important;
                }
                &-xxl {
                    margin-left: $spacer--extra-extra-large !important;
                    margin-right: $spacer--extra-extra-large !important;
                }
            }
            &t {
                &-0 {
                    margin-top: 0 !important;
                }
                &-1 {
                    margin-top: $spacer--extra-small !important;
                }
                &-2 {
                    margin-top: $spacer !important;
                }
                &-3 {
                    margin-top: $spacer--small !important;
                }
                &-4 {
                    margin-top: $spacer--medium !important;
                }
                &-5 {
                    margin-top: $spacer--m !important;
                }
                &-6 {
                    margin-top: $spacer--semi-medium !important;
                }
                &-7 {
                    margin-top: $spacer--large !important;
                }
                &-8 {
                    margin-top: $spacer--l !important;
                }
                &-9 {
                    margin-top: $spacer--semi-large !important;
                }
                &-10 {
                    margin-top: $spacer--xl !important;
                }
                &-11 {
                    margin-top: $spacer--extra-large !important;
                }
                &-12 {
                    margin-top: $spacer--xxl !important;
                }
                &-13 {
                    margin-top: $spacer--extra-extra-large !important;
                }
                &-14 {
                    margin-top: $spacer--larger !important;
                }
                &-xs {
                    margin-top: $spacer--extra-small !important;
                }
                &-s {
                    margin-top: $spacer--small !important;
                }
                &-default {
                    margin-top: $spacer !important;
                }
                &-m {
                    margin-top: $spacer--medium !important;
                }
                &-l {
                    margin-top: $spacer--large !important;
                }
                &-xl {
                    margin-top: $spacer--extra-large !important;
                }
                &-xxl {
                    margin-top: $spacer--extra-extra-large !important;
                }
            }
            &r {
                &-0 {
                    margin-right: 0 !important;
                }
                &-1 {
                    margin-right: $spacer--extra-small !important;
                }
                &-2 {
                    margin-right: $spacer !important;
                }
                &-3 {
                    margin-right: $spacer--small !important;
                }
                &-4 {
                    margin-right: $spacer--medium !important;
                }
                &-5 {
                    margin-right: $spacer--m !important;
                }
                &-6 {
                    margin-right: $spacer--semi-medium !important;
                }
                &-7 {
                    margin-right: $spacer--large !important;
                }
                &-8 {
                    margin-right: $spacer--l !important;
                }
                &-9 {
                    margin-right: $spacer--semi-large !important;
                }
                &-10 {
                    margin-right: $spacer--xl !important;
                }
                &-11 {
                    margin-right: $spacer--extra-large !important;
                }
                &-12 {
                    margin-right: $spacer--xxl !important;
                }
                &-13 {
                    margin-right: $spacer--extra-extra-large !important;
                }
                &-14 {
                    margin-right: $spacer--larger !important;
                }
                &-xs {
                     margin-right: $spacer--extra-small !important;
                 }
                &-s {
                    margin-right: $spacer--small !important;
                }
                &-default {
                    margin-right: $spacer !important;
                }
                &-m {
                    margin-right: $spacer--medium !important;
                }
                &-l {
                    margin-right: $spacer--large !important;
                }
                &-xl {
                    margin-right: $spacer--extra-large !important;
                }
                &-xxl {
                    margin-right: $spacer--extra-extra-large !important;
                }
            }
            &b {
                &-0 {
                    margin-bottom: 0 !important;
                }
                &-1 {
                    margin-bottom: $spacer--extra-small !important;
                }
                &-2 {
                    margin-bottom: $spacer !important;
                }
                &-3 {
                    margin-bottom: $spacer--small !important;
                }
                &-4 {
                    margin-bottom: $spacer--medium !important;
                }
                &-5 {
                    margin-bottom: $spacer--m !important;
                }
                &-6 {
                    margin-bottom: $spacer--semi-medium !important;
                }
                &-7 {
                    margin-bottom: $spacer--large !important;
                }
                &-8 {
                    margin-bottom: $spacer--l !important;
                }
                &-9 {
                    margin-bottom: $spacer--semi-large !important;
                }
                &-10 {
                    margin-bottom: $spacer--xl !important;
                }
                &-11 {
                    margin-bottom: $spacer--extra-large !important;
                }
                &-12 {
                    margin-bottom: $spacer--xxl !important;
                }
                &-13 {
                    margin-bottom: $spacer--extra-extra-large !important;
                }
                &-14 {
                    margin-bottom: $spacer--larger !important;
                }
                &-xs {
                    margin-bottom: $spacer--extra-small !important;
                }
                &-s {
                    margin-bottom: $spacer--small !important;
                }
                &-default {
                    margin-bottom: $spacer !important;
                }
                &-m {
                    margin-bottom: $spacer--medium !important;
                }
                &-l {
                    margin-bottom: $spacer--large !important;
                }
                &-xl {
                    margin-bottom: $spacer--extra-large !important;
                }
                &-xxl {
                    margin-bottom: $spacer--extra-extra-large !important;
                }
            }
            &l {
                &-0 {
                    margin-left: 0 !important;
                }
                &-1 {
                    margin-left: $spacer--extra-small !important;
                }
                &-2 {
                    margin-left: $spacer !important;
                }
                &-3 {
                    margin-left: $spacer--small !important;
                }
                &-4 {
                    margin-left: $spacer--medium !important;
                }
                &-5 {
                    margin-left: $spacer--m !important;
                }
                &-6 {
                    margin-left: $spacer--semi-medium !important;
                }
                &-7 {
                    margin-left: $spacer--large !important;
                }
                &-8 {
                    margin-left: $spacer--l !important;
                }
                &-9 {
                    margin-left: $spacer--semi-large !important;
                }
                &-10 {
                    margin-left: $spacer--xl !important;
                }
                &-11 {
                    margin-left: $spacer--extra-large !important;
                }
                &-12 {
                    margin-left: $spacer--xxl !important;
                }
                &-13 {
                    margin-left: $spacer--extra-extra-large !important;
                }
                &-14 {
                    margin-left: $spacer--larger !important;
                }
                &-xs {
                    margin-left: $spacer--extra-small !important;
                }
                &-s {
                    margin-left: $spacer--small !important;
                }
                &-default {
                    margin-left: $spacer !important;
                }
                &-m {
                    margin-left: $spacer--medium !important;
                }
                &-l {
                    margin-left: $spacer--large !important;
                }
                &-xl {
                    margin-left: $spacer--extra-large !important;
                }
                &-xxl {
                    margin-left: $spacer--extra-extra-large !important;
                }
            }
        }
    }
}
@include min-screen($screen-l) {
    .lg {
        &-m {
            &-0 {
                margin: 0 !important;
            }
            &-1 {
                margin: $spacer--extra-small !important;
            }
            &-2 {
                margin: $spacer !important;
            }
            &-3 {
                margin: $spacer--small !important;
            }
            &-4 {
                margin: $spacer--medium !important;
            }
            &-5 {
                margin: $spacer--m !important;
            }
            &-6 {
                margin: $spacer--semi-medium !important;
            }
            &-7 {
                margin: $spacer--large !important;
            }
            &-8 {
                margin: $spacer--l !important;
            }
            &-9 {
                margin: $spacer--semi-large !important;
            }
            &-10 {
                margin: $spacer--xl !important;
            }
            &-11 {
                margin: $spacer--extra-large !important;
            }
            &-12 {
                margin: $spacer--xxl !important;
            }
            &-13 {
                margin: $spacer--extra-extra-large !important;
            }
            &-14 {
                margin: $spacer--larger !important;
            }
            &-xs {
                margin: $spacer--extra-small !important;
            }
            &-s {
                margin: $spacer--small !important;
            }
            &-default {
                margin: $spacer !important;
            }
            &-m {
                margin: $spacer--medium !important;
            }
            &-l {
                margin: $spacer--large !important;
            }
            &-xl {
                margin: $spacer--extra-large !important;
            }
            &-xxl {
                margin: $spacer--extra-extra-large !important;
            }
            &-vc {
                &-0 {
                    margin-top: 0 !important;
                    margin-bottom: 0 !important;
                }
                &-1 {
                    margin-top: $spacer--extra-small !important;
                    margin-bottom: $spacer--extra-small !important;
                }
                &-2 {
                    margin-top: $spacer !important;
                    margin-bottom: $spacer !important;
                }
                &-3 {
                    margin-top: $spacer--small !important;
                    margin-bottom: $spacer--small !important;
                }
                &-4 {
                    margin-top: $spacer--medium !important;
                    margin-bottom: $spacer--medium !important;
                }
                &-5 {
                    margin-top: $spacer--m !important;
                    margin-bottom: $spacer--m !important;
                }
                &-6 {
                    margin-top: $spacer--semi-medium !important;
                    margin-bottom: $spacer--semi-medium !important;
                }
                &-7 {
                    margin-top: $spacer--large !important;
                    margin-bottom: $spacer--large !important;
                }
                &-8 {
                    margin-top: $spacer--l !important;
                    margin-bottom: $spacer--l !important;
                }
                &-9 {
                    margin-top: $spacer--semi-large !important;
                    margin-bottom: $spacer--semi-large !important;
                }
                &-10 {
                    margin-top: $spacer--xl !important;
                    margin-bottom: $spacer--xl !important;
                }
                &-11 {
                    margin-top: $spacer--extra-large !important;
                    margin-bottom: $spacer--extra-large !important;
                }
                &-12 {
                    margin-top: $spacer--xxl !important;
                    margin-bottom: $spacer--xxl !important;
                }
                &-13 {
                    margin-top: $spacer--extra-extra-large !important;
                    margin-bottom: $spacer--extra-extra-large !important;
                }
                &-14 {
                    margin-top: $spacer--larger !important;
                    margin-bottom: $spacer--larger !important;
                }
                &-xs {
                    margin-top: $spacer--extra-small !important;
                    margin-bottom: $spacer--extra-small !important;
                }
                &-s {
                    margin-top: $spacer--small !important;
                    margin-bottom: $spacer--small !important;
                }
                &-default {
                    margin-top: $spacer !important;
                    margin-bottom: $spacer !important;
                }
                &-m {
                    margin-top: $spacer--medium !important;
                    margin-bottom: $spacer--medium !important;
                }
                &-l {
                    margin-top: $spacer--large !important;
                    margin-bottom: $spacer--large !important;
                }
                &-xl {
                    margin-top: $spacer--extra-large !important;
                    margin-bottom: $spacer--extra-large !important;
                }
                &-xxl {
                    margin-top: $spacer--extra-extra-large !important;
                    margin-bottom: $spacer--extra-extra-large !important;
                }
            }
            &-hz {
                &-0 {
                    margin-right: 0 !important;
                    margin-left: 0 !important;
                }
                &-1 {
                    margin-right: $spacer--extra-small !important;
                    margin-left: $spacer--extra-small !important;
                }
                &-2 {
                    margin-right: $spacer !important;
                    margin-left: $spacer !important;
                }
                &-3 {
                    margin-right: $spacer--small !important;
                    margin-left: $spacer--small !important;
                }
                &-4 {
                    margin-right: $spacer--medium !important;
                    margin-left: $spacer--medium !important;
                }
                &-5 {
                    margin-right: $spacer--m !important;
                    margin-left: $spacer--m !important;
                }
                &-6 {
                    margin-right: $spacer--semi-medium !important;
                    margin-left: $spacer--semi-medium !important;
                }
                &-7 {
                    margin-right: $spacer--large !important;
                    margin-left: $spacer--large !important;
                }
                &-8 {
                    margin-right: $spacer--l !important;
                    margin-left: $spacer--l !important;
                }
                &-9 {
                    margin-right: $spacer--semi-large !important;
                    margin-left: $spacer--semi-large !important;
                }
                &-10 {
                    margin-right: $spacer--xl !important;
                    margin-left: $spacer--xl !important;
                }
                &-11 {
                    margin-right: $spacer--extra-large !important;
                    margin-left: $spacer--extra-large !important;
                }
                &-12 {
                    margin-right: $spacer--xxl !important;
                    margin-left: $spacer--xxl !important;
                }
                &-13 {
                    margin-right: $spacer--extra-extra-large !important;
                    margin-left: $spacer--extra-extra-large !important;
                }
                &-14 {
                    margin-right: $spacer--larger !important;
                    margin-left: $spacer--larger !important;
                }
                &-xs {
                    margin-left: $spacer--extra-small !important;
                    margin-right: $spacer--extra-small !important;
                }
                &-s {
                    margin-left: $spacer--small !important;
                    margin-right: $spacer--small !important;
                }
                &-default {
                    margin-left: $spacer !important;
                    margin-right: $spacer !important;
                }
                &-m {
                    margin-left: $spacer--medium !important;
                    margin-right: $spacer--medium !important;
                }
                &-l {
                    margin-left: $spacer--large !important;
                    margin-right: $spacer--large !important;
                }
                &-xl {
                    margin-left: $spacer--extra-large !important;
                    margin-right: $spacer--extra-large !important;
                }
                &-xxl {
                    margin-left: $spacer--extra-extra-large !important;
                    margin-right: $spacer--extra-extra-large !important;
                }
            }
            &t {
                &-0 {
                    margin-top: 0 !important;
                }
                &-1 {
                    margin-top: $spacer--extra-small !important;
                }
                &-2 {
                    margin-top: $spacer !important;
                }
                &-3 {
                    margin-top: $spacer--small !important;
                }
                &-4 {
                    margin-top: $spacer--medium !important;
                }
                &-5 {
                    margin-top: $spacer--m !important;
                }
                &-6 {
                    margin-top: $spacer--semi-medium !important;
                }
                &-7 {
                    margin-top: $spacer--large !important;
                }
                &-8 {
                    margin-top: $spacer--l !important;
                }
                &-9 {
                    margin-top: $spacer--semi-large !important;
                }
                &-10 {
                    margin-top: $spacer--xl !important;
                }
                &-11 {
                    margin-top: $spacer--extra-large !important;
                }
                &-12 {
                    margin-top: $spacer--xxl !important;
                }
                &-13 {
                    margin-top: $spacer--extra-extra-large !important;
                }
                &-14 {
                    margin-top: $spacer--larger !important;
                }
                &-xs {
                    margin-top: $spacer--extra-small !important;
                }
                &-s {
                    margin-top: $spacer--small !important;
                }
                &-default {
                    margin-top: $spacer !important;
                }
                &-m {
                    margin-top: $spacer--medium !important;
                }
                &-l {
                    margin-top: $spacer--large !important;
                }
                &-xl {
                    margin-top: $spacer--extra-large !important;
                }
                &-xxl {
                    margin-top: $spacer--extra-extra-large !important;
                }
            }
            &r {
                &-0 {
                    margin-right: 0 !important;
                }
                &-1 {
                    margin-right: $spacer--extra-small !important;
                }
                &-2 {
                    margin-right: $spacer !important;
                }
                &-3 {
                    margin-right: $spacer--small !important;
                }
                &-4 {
                    margin-right: $spacer--medium !important;
                }
                &-5 {
                    margin-right: $spacer--m !important;
                }
                &-6 {
                    margin-right: $spacer--semi-medium !important;
                }
                &-7 {
                    margin-right: $spacer--large !important;
                }
                &-8 {
                    margin-right: $spacer--l !important;
                }
                &-9 {
                    margin-right: $spacer--semi-large !important;
                }
                &-10 {
                    margin-right: $spacer--xl !important;
                }
                &-11 {
                    margin-right: $spacer--extra-large !important;
                }
                &-12 {
                    margin-right: $spacer--xxl !important;
                }
                &-13 {
                    margin-right: $spacer--extra-extra-large !important;
                }
                &-14 {
                    margin-right: $spacer--larger !important;
                }
                &-xs {
                    margin-right: $spacer--extra-small !important;
                }
                &-s {
                    margin-right: $spacer--small !important;
                }
                &-default {
                    margin-right: $spacer !important;
                }
                &-m {
                    margin-right: $spacer--medium !important;
                }
                &-l {
                    margin-right: $spacer--large !important;
                }
                &-xl {
                    margin-right: $spacer--extra-large !important;
                }
                &-xxl {
                    margin-right: $spacer--extra-extra-large !important;
                }
            }
            &b {
                &-0 {
                    margin-bottom: 0 !important;
                }
                &-1 {
                    margin-bottom: $spacer--extra-small !important;
                }
                &-2 {
                    margin-bottom: $spacer !important;
                }
                &-3 {
                    margin-bottom: $spacer--small !important;
                }
                &-4 {
                    margin-bottom: $spacer--medium !important;
                }
                &-5 {
                    margin-bottom: $spacer--m !important;
                }
                &-6 {
                    margin-bottom: $spacer--semi-medium !important;
                }
                &-7 {
                    margin-bottom: $spacer--large !important;
                }
                &-8 {
                    margin-bottom: $spacer--l !important;
                }
                &-9 {
                    margin-bottom: $spacer--semi-large !important;
                }
                &-10 {
                    margin-bottom: $spacer--xl !important;
                }
                &-11 {
                    margin-bottom: $spacer--extra-large !important;
                }
                &-12 {
                    margin-bottom: $spacer--xxl !important;
                }
                &-13 {
                    margin-bottom: $spacer--extra-extra-large !important;
                }
                &-14 {
                    margin-bottom: $spacer--larger !important;
                }
                &-xs {
                    margin-bottom: $spacer--extra-small !important;
                }
                &-s {
                    margin-bottom: $spacer--small !important;
                }
                &-default {
                    margin-bottom: $spacer !important;
                }
                &-m {
                    margin-bottom: $spacer--medium !important;
                }
                &-l {
                    margin-bottom: $spacer--large !important;
                }
                &-xl {
                    margin-bottom: $spacer--extra-large !important;
                }
                &-xxl {
                    margin-bottom: $spacer--extra-extra-large !important;
                }
            }
            &l {
                &-0 {
                    margin-left: 0 !important;
                }
                &-1 {
                    margin-left: $spacer--extra-small !important;
                }
                &-2 {
                    margin-left: $spacer !important;
                }
                &-3 {
                    margin-left: $spacer--small !important;
                }
                &-4 {
                    margin-left: $spacer--medium !important;
                }
                &-5 {
                    margin-left: $spacer--m !important;
                }
                &-6 {
                    margin-left: $spacer--semi-medium !important;
                }
                &-7 {
                    margin-left: $spacer--large !important;
                }
                &-8 {
                    margin-left: $spacer--l !important;
                }
                &-9 {
                    margin-left: $spacer--semi-large !important;
                }
                &-10 {
                    margin-left: $spacer--xl !important;
                }
                &-11 {
                    margin-left: $spacer--extra-large !important;
                }
                &-12 {
                    margin-left: $spacer--xxl !important;
                }
                &-13 {
                    margin-left: $spacer--extra-extra-large !important;
                }
                &-14 {
                    margin-left: $spacer--larger !important;
                }
                &-xs {
                    margin-left: $spacer--extra-small !important;
                }
                &-s {
                    margin-left: $spacer--small !important;
                }
                &-default {
                    margin-left: $spacer !important;
                }
                &-m {
                    margin-left: $spacer--medium !important;
                }
                &-l {
                    margin-left: $spacer--large !important;
                }
                &-xl {
                    margin-left: $spacer--extra-large !important;
                }
                &-xxl {
                    margin-left: $spacer--extra-extra-large !important;
                }
            }
        }
    }
}
@include min-screen($screen-xl) {
     .xl {
         &-m {
             &-0 {
                 margin: 0 !important;
             }
             &-1 {
                 margin: $spacer--extra-small !important;
             }
             &-2 {
                 margin: $spacer !important;
             }
             &-3 {
                 margin: $spacer--small !important;
             }
             &-4 {
                 margin: $spacer--medium !important;
             }
             &-5 {
                 margin: $spacer--m !important;
             }
             &-6 {
                 margin: $spacer--semi-medium !important;
             }
             &-7 {
                 margin: $spacer--large !important;
             }
             &-8 {
                 margin: $spacer--l !important;
             }
             &-9 {
                 margin: $spacer--semi-large !important;
             }
             &-10 {
                 margin: $spacer--xl !important;
             }
             &-11 {
                 margin: $spacer--extra-large !important;
             }
             &-12 {
                 margin: $spacer--xxl !important;
             }
             &-13 {
                 margin: $spacer--extra-extra-large !important;
             }
             &-14 {
                 margin: $spacer--larger !important;
             }
             &-xs {
                 margin: $spacer--extra-small !important;
             }
             &-s {
                 margin: $spacer--small !important;
             }
             &-default {
                 margin: $spacer !important;
             }
             &-m {
                 margin: $spacer--medium !important;
             }
             &-l {
                 margin: $spacer--large !important;
             }
             &-xl {
                 margin: $spacer--extra-large !important;
             }
             &-xxl {
                 margin: $spacer--extra-extra-large !important;
             }
             &-vc {
                 &-0 {
                     margin-top: 0 !important;
                     margin-bottom: 0 !important;
                 }
                 &-1 {
                     margin-top: $spacer--extra-small !important;
                     margin-bottom: $spacer--extra-small !important;
                 }
                 &-2 {
                     margin-top: $spacer !important;
                     margin-bottom: $spacer !important;
                 }
                 &-3 {
                     margin-top: $spacer--small !important;
                     margin-bottom: $spacer--small !important;
                 }
                 &-4 {
                     margin-top: $spacer--medium !important;
                     margin-bottom: $spacer--medium !important;
                 }
                 &-5 {
                     margin-top: $spacer--m !important;
                     margin-bottom: $spacer--m !important;
                 }
                 &-6 {
                     margin-top: $spacer--semi-medium !important;
                     margin-bottom: $spacer--semi-medium !important;
                 }
                 &-7 {
                     margin-top: $spacer--large !important;
                     margin-bottom: $spacer--large !important;
                 }
                 &-8 {
                     margin-top: $spacer--l !important;
                     margin-bottom: $spacer--l !important;
                 }
                 &-9 {
                     margin-top: $spacer--semi-large !important;
                     margin-bottom: $spacer--semi-large !important;
                 }
                 &-10 {
                     margin-top: $spacer--xl !important;
                     margin-bottom: $spacer--xl !important;
                 }
                 &-11 {
                     margin-top: $spacer--extra-large !important;
                     margin-bottom: $spacer--extra-large !important;
                 }
                 &-12 {
                     margin-top: $spacer--xxl !important;
                     margin-bottom: $spacer--xxl !important;
                 }
                 &-13 {
                     margin-top: $spacer--extra-extra-large !important;
                     margin-bottom: $spacer--extra-extra-large !important;
                 }
                 &-14 {
                     margin-top: $spacer--larger !important;
                     margin-bottom: $spacer--larger !important;
                 }
                 &-xs {
                     margin-top: $spacer--extra-small !important;
                     margin-bottom: $spacer--extra-small !important;
                 }
                 &-s {
                     margin-top: $spacer--small !important;
                     margin-bottom: $spacer--small !important;
                 }
                 &-default {
                     margin-top: $spacer !important;
                     margin-bottom: $spacer !important;
                 }
                 &-m {
                     margin-top: $spacer--medium !important;
                     margin-bottom: $spacer--medium !important;
                 }
                 &-l {
                     margin-top: $spacer--large !important;
                     margin-bottom: $spacer--large !important;
                 }
                 &-xl {
                     margin-top: $spacer--extra-large !important;
                     margin-bottom: $spacer--extra-large !important;
                 }
                 &-xxl {
                     margin-top: $spacer--extra-extra-large !important;
                     margin-bottom: $spacer--extra-extra-large !important;
                 }
             }
             &-hz {
                 &-0 {
                     margin-right: 0 !important;
                     margin-left: 0 !important;
                 }
                 &-1 {
                     margin-right: $spacer--extra-small !important;
                     margin-left: $spacer--extra-small !important;
                 }
                 &-2 {
                     margin-right: $spacer !important;
                     margin-left: $spacer !important;
                 }
                 &-3 {
                     margin-right: $spacer--small !important;
                     margin-left: $spacer--small !important;
                 }
                 &-4 {
                     margin-right: $spacer--medium !important;
                     margin-left: $spacer--medium !important;
                 }
                 &-5 {
                     margin-right: $spacer--m !important;
                     margin-left: $spacer--m !important;
                 }
                 &-6 {
                     margin-right: $spacer--semi-medium !important;
                     margin-left: $spacer--semi-medium !important;
                 }
                 &-7 {
                     margin-right: $spacer--large !important;
                     margin-left: $spacer--large !important;
                 }
                 &-8 {
                     margin-right: $spacer--l !important;
                     margin-left: $spacer--l !important;
                 }
                 &-9 {
                     margin-right: $spacer--semi-large !important;
                     margin-left: $spacer--semi-large !important;
                 }
                 &-10 {
                     margin-right: $spacer--xl !important;
                     margin-left: $spacer--xl !important;
                 }
                 &-11 {
                     margin-right: $spacer--extra-large !important;
                     margin-left: $spacer--extra-large !important;
                 }
                 &-12 {
                     margin-right: $spacer--xxl !important;
                     margin-left: $spacer--xxl !important;
                 }
                 &-13 {
                     margin-right: $spacer--extra-extra-large !important;
                     margin-left: $spacer--extra-extra-large !important;
                 }
                 &-14 {
                     margin-right: $spacer--larger !important;
                     margin-left: $spacer--larger !important;
                 }
                 &-xs {
                     margin-left: $spacer--extra-small !important;
                     margin-right: $spacer--extra-small !important;
                 }
                 &-s {
                     margin-left: $spacer--small !important;
                     margin-right: $spacer--small !important;
                 }
                 &-default {
                     margin-left: $spacer !important;
                     margin-right: $spacer !important;
                 }
                 &-m {
                     margin-left: $spacer--medium !important;
                     margin-right: $spacer--medium !important;
                 }
                 &-l {
                     margin-left: $spacer--large !important;
                     margin-right: $spacer--large !important;
                 }
                 &-xl {
                     margin-left: $spacer--extra-large !important;
                     margin-right: $spacer--extra-large !important;
                 }
                 &-xxl {
                     margin-left: $spacer--extra-extra-large !important;
                     margin-right: $spacer--extra-extra-large !important;
                 }
             }
             &t {
                 &-0 {
                     margin-top: 0 !important;
                 }
                 &-1 {
                     margin-top: $spacer--extra-small !important;
                 }
                 &-2 {
                     margin-top: $spacer !important;
                 }
                 &-3 {
                     margin-top: $spacer--small !important;
                 }
                 &-4 {
                     margin-top: $spacer--medium !important;
                 }
                 &-5 {
                     margin-top: $spacer--m !important;
                 }
                 &-6 {
                     margin-top: $spacer--semi-medium !important;
                 }
                 &-7 {
                     margin-top: $spacer--large !important;
                 }
                 &-8 {
                     margin-top: $spacer--l !important;
                 }
                 &-9 {
                     margin-top: $spacer--semi-large !important;
                 }
                 &-10 {
                     margin-top: $spacer--xl !important;
                 }
                 &-11 {
                     margin-top: $spacer--extra-large !important;
                 }
                 &-12 {
                     margin-top: $spacer--xxl !important;
                 }
                 &-13 {
                     margin-top: $spacer--extra-extra-large !important;
                 }
                 &-14 {
                     margin-top: $spacer--larger !important;
                 }
                 &-xs {
                     margin-top: $spacer--extra-small !important;
                 }
                 &-s {
                     margin-top: $spacer--small !important;
                 }
                 &-default {
                     margin-top: $spacer !important;
                 }
                 &-m {
                     margin-top: $spacer--medium !important;
                 }
                 &-l {
                     margin-top: $spacer--large !important;
                 }
                 &-xl {
                     margin-top: $spacer--extra-large !important;
                 }
                 &-xxl {
                     margin-top: $spacer--extra-extra-large !important;
                 }
             }
             &r {
                 &-0 {
                     margin-right: 0 !important;
                 }
                 &-1 {
                     margin-right: $spacer--extra-small !important;
                 }
                 &-2 {
                     margin-right: $spacer !important;
                 }
                 &-3 {
                     margin-right: $spacer--small !important;
                 }
                 &-4 {
                     margin-right: $spacer--medium !important;
                 }
                 &-5 {
                     margin-right: $spacer--m !important;
                 }
                 &-6 {
                     margin-right: $spacer--semi-medium !important;
                 }
                 &-7 {
                     margin-right: $spacer--large !important;
                 }
                 &-8 {
                     margin-right: $spacer--l !important;
                 }
                 &-9 {
                     margin-right: $spacer--semi-large !important;
                 }
                 &-10 {
                     margin-right: $spacer--xl !important;
                 }
                 &-11 {
                     margin-right: $spacer--extra-large !important;
                 }
                 &-12 {
                     margin-right: $spacer--xxl !important;
                 }
                 &-13 {
                     margin-right: $spacer--extra-extra-large !important;
                 }
                 &-14 {
                     margin-right: $spacer--larger !important;
                 }
                 &-xs {
                     margin-right: $spacer--extra-small !important;
                 }
                 &-s {
                     margin-right: $spacer--small !important;
                 }
                 &-default {
                     margin-right: $spacer !important;
                 }
                 &-m {
                     margin-right: $spacer--medium !important;
                 }
                 &-l {
                     margin-right: $spacer--large !important;
                 }
                 &-xl {
                     margin-right: $spacer--extra-large !important;
                 }
                 &-xxl {
                     margin-right: $spacer--extra-extra-large !important;
                 }
             }
             &b {
                 &-0 {
                     margin-bottom: 0 !important;
                 }
                 &-1 {
                     margin-bottom: $spacer--extra-small !important;
                 }
                 &-2 {
                     margin-bottom: $spacer !important;
                 }
                 &-3 {
                     margin-bottom: $spacer--small !important;
                 }
                 &-4 {
                     margin-bottom: $spacer--medium !important;
                 }
                 &-5 {
                     margin-bottom: $spacer--m !important;
                 }
                 &-6 {
                     margin-bottom: $spacer--semi-medium !important;
                 }
                 &-7 {
                     margin-bottom: $spacer--large !important;
                 }
                 &-8 {
                     margin-bottom: $spacer--l !important;
                 }
                 &-9 {
                     margin-bottom: $spacer--semi-large !important;
                 }
                 &-10 {
                     margin-bottom: $spacer--xl !important;
                 }
                 &-11 {
                     margin-bottom: $spacer--extra-large !important;
                 }
                 &-12 {
                     margin-bottom: $spacer--xxl !important;
                 }
                 &-13 {
                     margin-bottom: $spacer--extra-extra-large !important;
                 }
                 &-14 {
                     margin-bottom: $spacer--larger !important;
                 }
                 &-xs {
                     margin-bottom: $spacer--extra-small !important;
                 }
                 &-s {
                     margin-bottom: $spacer--small !important;
                 }
                 &-default {
                     margin-bottom: $spacer !important;
                 }
                 &-m {
                     margin-bottom: $spacer--medium !important;
                 }
                 &-l {
                     margin-bottom: $spacer--large !important;
                 }
                 &-xl {
                     margin-bottom: $spacer--extra-large !important;
                 }
                 &-xxl {
                     margin-bottom: $spacer--extra-extra-large !important;
                 }
             }
             &l {
                 &-0 {
                     margin-left: 0 !important;
                 }
                 &-1 {
                     margin-left: $spacer--extra-small !important;
                 }
                 &-2 {
                     margin-left: $spacer !important;
                 }
                 &-3 {
                     margin-left: $spacer--small !important;
                 }
                 &-4 {
                     margin-left: $spacer--medium !important;
                 }
                 &-5 {
                     margin-left: $spacer--m !important;
                 }
                 &-6 {
                     margin-left: $spacer--semi-medium !important;
                 }
                 &-7 {
                     margin-left: $spacer--large !important;
                 }
                 &-8 {
                     margin-left: $spacer--l !important;
                 }
                 &-9 {
                     margin-left: $spacer--semi-large !important;
                 }
                 &-10 {
                     margin-left: $spacer--xl !important;
                 }
                 &-11 {
                     margin-left: $spacer--extra-large !important;
                 }
                 &-12 {
                     margin-left: $spacer--xxl !important;
                 }
                 &-13 {
                     margin-left: $spacer--extra-extra-large !important;
                 }
                 &-14 {
                     margin-left: $spacer--larger !important;
                 }
                 &-xs {
                     margin-left: $spacer--extra-small !important;
                 }
                 &-s {
                     margin-left: $spacer--small !important;
                 }
                 &-default {
                     margin-left: $spacer !important;
                 }
                 &-m {
                     margin-left: $spacer--medium !important;
                 }
                 &-l {
                     margin-left: $spacer--large !important;
                 }
                 &-xl {
                     margin-left: $spacer--extra-large !important;
                 }
                 &-xxl {
                     margin-left: $spacer--extra-extra-large !important;
                 }
             }
         }
     }
}
@include min-screen($screen-2xl) {
    .x2l {
        &-m {
            &-0 {
                margin: 0 !important;
            }
            &-1 {
                margin: $spacer--extra-small !important;
            }
            &-2 {
                margin: $spacer !important;
            }
            &-3 {
                margin: $spacer--small !important;
            }
            &-4 {
                margin: $spacer--medium !important;
            }
            &-5 {
                margin: $spacer--m !important;
            }
            &-6 {
                margin: $spacer--semi-medium !important;
            }
            &-7 {
                margin: $spacer--large !important;
            }
            &-8 {
                margin: $spacer--l !important;
            }
            &-9 {
                margin: $spacer--semi-large !important;
            }
            &-10 {
                margin: $spacer--xl !important;
            }
            &-11 {
                margin: $spacer--extra-large !important;
            }
            &-12 {
                margin: $spacer--xxl !important;
            }
            &-13 {
                margin: $spacer--extra-extra-large !important;
            }
            &-14 {
                margin: $spacer--larger !important;
            }
            &-xs {
                margin: $spacer--extra-small !important;
            }
            &-s {
                margin: $spacer--small !important;
            }
            &-default {
                margin: $spacer !important;
            }
            &-m {
                margin: $spacer--medium !important;
            }
            &-l {
                margin: $spacer--large !important;
            }
            &-xl {
                margin: $spacer--extra-large !important;
            }
            &-xxl {
                margin: $spacer--extra-extra-large !important;
            }
            &-vc {
                &-0 {
                    margin-top: 0 !important;
                    margin-bottom: 0 !important;
                }
                &-1 {
                    margin-top: $spacer--extra-small !important;
                    margin-bottom: $spacer--extra-small !important;
                }
                &-2 {
                    margin-top: $spacer !important;
                    margin-bottom: $spacer !important;
                }
                &-3 {
                    margin-top: $spacer--small !important;
                    margin-bottom: $spacer--small !important;
                }
                &-4 {
                    margin-top: $spacer--medium !important;
                    margin-bottom: $spacer--medium !important;
                }
                &-5 {
                    margin-top: $spacer--m !important;
                    margin-bottom: $spacer--m !important;
                }
                &-6 {
                    margin-top: $spacer--semi-medium !important;
                    margin-bottom: $spacer--semi-medium !important;
                }
                &-7 {
                    margin-top: $spacer--large !important;
                    margin-bottom: $spacer--large !important;
                }
                &-8 {
                    margin-top: $spacer--l !important;
                    margin-bottom: $spacer--l !important;
                }
                &-9 {
                    margin-top: $spacer--semi-large !important;
                    margin-bottom: $spacer--semi-large !important;
                }
                &-10 {
                    margin-top: $spacer--xl !important;
                    margin-bottom: $spacer--xl !important;
                }
                &-11 {
                    margin-top: $spacer--extra-large !important;
                    margin-bottom: $spacer--extra-large !important;
                }
                &-12 {
                    margin-top: $spacer--xxl !important;
                    margin-bottom: $spacer--xxl !important;
                }
                &-13 {
                    margin-top: $spacer--extra-extra-large !important;
                    margin-bottom: $spacer--extra-extra-large !important;
                }
                &-14 {
                    margin-top: $spacer--larger !important;
                    margin-bottom: $spacer--larger !important;
                }
                &-xs {
                    margin-top: $spacer--extra-small !important;
                    margin-bottom: $spacer--extra-small !important;
                }
                &-s {
                    margin-top: $spacer--small !important;
                    margin-bottom: $spacer--small !important;
                }
                &-default {
                    margin-top: $spacer !important;
                    margin-bottom: $spacer !important;
                }
                &-m {
                    margin-top: $spacer--medium !important;
                    margin-bottom: $spacer--medium !important;
                }
                &-l {
                    margin-top: $spacer--large !important;
                    margin-bottom: $spacer--large !important;
                }
                &-xl {
                    margin-top: $spacer--extra-large !important;
                    margin-bottom: $spacer--extra-large !important;
                }
                &-xxl {
                    margin-top: $spacer--extra-extra-large !important;
                    margin-bottom: $spacer--extra-extra-large !important;
                }
            }
            &-hz {
                &-0 {
                    margin-right: 0 !important;
                    margin-left: 0 !important;
                }
                &-1 {
                    margin-right: $spacer--extra-small !important;
                    margin-left: $spacer--extra-small !important;
                }
                &-2 {
                    margin-right: $spacer !important;
                    margin-left: $spacer !important;
                }
                &-3 {
                    margin-right: $spacer--small !important;
                    margin-left: $spacer--small !important;
                }
                &-4 {
                    margin-right: $spacer--medium !important;
                    margin-left: $spacer--medium !important;
                }
                &-5 {
                    margin-right: $spacer--m !important;
                    margin-left: $spacer--m !important;
                }
                &-6 {
                    margin-right: $spacer--semi-medium !important;
                    margin-left: $spacer--semi-medium !important;
                }
                &-7 {
                    margin-right: $spacer--large !important;
                    margin-left: $spacer--large !important;
                }
                &-8 {
                    margin-right: $spacer--l !important;
                    margin-left: $spacer--l !important;
                }
                &-9 {
                    margin-right: $spacer--semi-large !important;
                    margin-left: $spacer--semi-large !important;
                }
                &-10 {
                    margin-right: $spacer--xl !important;
                    margin-left: $spacer--xl !important;
                }
                &-11 {
                    margin-right: $spacer--extra-large !important;
                    margin-left: $spacer--extra-large !important;
                }
                &-12 {
                    margin-right: $spacer--xxl !important;
                    margin-left: $spacer--xxl !important;
                }
                &-13 {
                    margin-right: $spacer--extra-extra-large !important;
                    margin-left: $spacer--extra-extra-large !important;
                }
                &-14 {
                    margin-right: $spacer--larger !important;
                    margin-left: $spacer--larger !important;
                }
                &-xs {
                    margin-left: $spacer--extra-small !important;
                    margin-right: $spacer--extra-small !important;
                }
                &-s {
                    margin-left: $spacer--small !important;
                    margin-right: $spacer--small !important;
                }
                &-default {
                    margin-left: $spacer !important;
                    margin-right: $spacer !important;
                }
                &-m {
                    margin-left: $spacer--medium !important;
                    margin-right: $spacer--medium !important;
                }
                &-l {
                    margin-left: $spacer--large !important;
                    margin-right: $spacer--large !important;
                }
                &-xl {
                    margin-left: $spacer--extra-large !important;
                    margin-right: $spacer--extra-large !important;
                }
                &-xxl {
                    margin-left: $spacer--extra-extra-large !important;
                    margin-right: $spacer--extra-extra-large !important;
                }
            }
            &t {
                &-0 {
                    margin-top: 0 !important;
                }
                &-1 {
                    margin-top: $spacer--extra-small !important;
                }
                &-2 {
                    margin-top: $spacer !important;
                }
                &-3 {
                    margin-top: $spacer--small !important;
                }
                &-4 {
                    margin-top: $spacer--medium !important;
                }
                &-5 {
                    margin-top: $spacer--m !important;
                }
                &-6 {
                    margin-top: $spacer--semi-medium !important;
                }
                &-7 {
                    margin-top: $spacer--large !important;
                }
                &-8 {
                    margin-top: $spacer--l !important;
                }
                &-9 {
                    margin-top: $spacer--semi-large !important;
                }
                &-10 {
                    margin-top: $spacer--xl !important;
                }
                &-11 {
                    margin-top: $spacer--extra-large !important;
                }
                &-12 {
                    margin-top: $spacer--xxl !important;
                }
                &-13 {
                    margin-top: $spacer--extra-extra-large !important;
                }
                &-14 {
                    margin-top: $spacer--larger !important;
                }
                &-xs {
                    margin-top: $spacer--extra-small !important;
                }
                &-s {
                    margin-top: $spacer--small !important;
                }
                &-default {
                    margin-top: $spacer !important;
                }
                &-m {
                    margin-top: $spacer--medium !important;
                }
                &-l {
                    margin-top: $spacer--large !important;
                }
                &-xl {
                    margin-top: $spacer--extra-large !important;
                }
                &-xxl {
                    margin-top: $spacer--extra-extra-large !important;
                }
            }
            &r {
                &-0 {
                    margin-right: 0 !important;
                }
                &-1 {
                    margin-right: $spacer--extra-small !important;
                }
                &-2 {
                    margin-right: $spacer !important;
                }
                &-3 {
                    margin-right: $spacer--small !important;
                }
                &-4 {
                    margin-right: $spacer--medium !important;
                }
                &-5 {
                    margin-right: $spacer--m !important;
                }
                &-6 {
                    margin-right: $spacer--semi-medium !important;
                }
                &-7 {
                    margin-right: $spacer--large !important;
                }
                &-8 {
                    margin-right: $spacer--l !important;
                }
                &-9 {
                    margin-right: $spacer--semi-large !important;
                }
                &-10 {
                    margin-right: $spacer--xl !important;
                }
                &-11 {
                    margin-right: $spacer--extra-large !important;
                }
                &-12 {
                    margin-right: $spacer--xxl !important;
                }
                &-13 {
                    margin-right: $spacer--extra-extra-large !important;
                }
                &-14 {
                    margin-right: $spacer--larger !important;
                }
                &-xs {
                    margin-right: $spacer--extra-small !important;
                }
                &-s {
                    margin-right: $spacer--small !important;
                }
                &-default {
                    margin-right: $spacer !important;
                }
                &-m {
                    margin-right: $spacer--medium !important;
                }
                &-l {
                    margin-right: $spacer--large !important;
                }
                &-xl {
                    margin-right: $spacer--extra-large !important;
                }
                &-xxl {
                    margin-right: $spacer--extra-extra-large !important;
                }
            }
            &b {
                &-0 {
                    margin-bottom: 0 !important;
                }
                &-1 {
                    margin-bottom: $spacer--extra-small !important;
                }
                &-2 {
                    margin-bottom: $spacer !important;
                }
                &-3 {
                    margin-bottom: $spacer--small !important;
                }
                &-4 {
                    margin-bottom: $spacer--medium !important;
                }
                &-5 {
                    margin-bottom: $spacer--m !important;
                }
                &-6 {
                    margin-bottom: $spacer--semi-medium !important;
                }
                &-7 {
                    margin-bottom: $spacer--large !important;
                }
                &-8 {
                    margin-bottom: $spacer--l !important;
                }
                &-9 {
                    margin-bottom: $spacer--semi-large !important;
                }
                &-10 {
                    margin-bottom: $spacer--xl !important;
                }
                &-11 {
                    margin-bottom: $spacer--extra-large !important;
                }
                &-12 {
                    margin-bottom: $spacer--xxl !important;
                }
                &-13 {
                    margin-bottom: $spacer--extra-extra-large !important;
                }
                &-14 {
                    margin-bottom: $spacer--larger !important;
                }
                &-xs {
                    margin-bottom: $spacer--extra-small !important;
                }
                &-s {
                    margin-bottom: $spacer--small !important;
                }
                &-default {
                    margin-bottom: $spacer !important;
                }
                &-m {
                    margin-bottom: $spacer--medium !important;
                }
                &-l {
                    margin-bottom: $spacer--large !important;
                }
                &-xl {
                    margin-bottom: $spacer--extra-large !important;
                }
                &-xxl {
                    margin-bottom: $spacer--extra-extra-large !important;
                }
            }
            &l {
                &-0 {
                    margin-left: 0 !important;
                }
                &-1 {
                    margin-left: $spacer--extra-small !important;
                }
                &-2 {
                    margin-left: $spacer !important;
                }
                &-3 {
                    margin-left: $spacer--small !important;
                }
                &-4 {
                    margin-left: $spacer--medium !important;
                }
                &-5 {
                    margin-left: $spacer--m !important;
                }
                &-6 {
                    margin-left: $spacer--semi-medium !important;
                }
                &-7 {
                    margin-left: $spacer--large !important;
                }
                &-8 {
                    margin-left: $spacer--l !important;
                }
                &-9 {
                    margin-left: $spacer--semi-large !important;
                }
                &-10 {
                    margin-left: $spacer--xl !important;
                }
                &-11 {
                    margin-left: $spacer--extra-large !important;
                }
                &-12 {
                    margin-left: $spacer--xxl !important;
                }
                &-13 {
                    margin-left: $spacer--extra-extra-large !important;
                }
                &-14 {
                    margin-left: $spacer--larger !important;
                }
                &-xs {
                    margin-left: $spacer--extra-small !important;
                }
                &-s {
                    margin-left: $spacer--small !important;
                }
                &-default {
                    margin-left: $spacer !important;
                }
                &-m {
                    margin-left: $spacer--medium !important;
                }
                &-l {
                    margin-left: $spacer--large !important;
                }
                &-xl {
                    margin-left: $spacer--extra-large !important;
                }
                &-xxl {
                    margin-left: $spacer--extra-extra-large !important;
                }
            }
        }
    }
}
@include min-screen($screen-xxl) {
    .xxl {
        &-m {
            &-0 {
                margin: 0 !important;
            }
            &-1 {
                margin: $spacer--extra-small !important;
            }
            &-2 {
                margin: $spacer !important;
            }
            &-3 {
                margin: $spacer--small !important;
            }
            &-4 {
                margin: $spacer--medium !important;
            }
            &-5 {
                margin: $spacer--m !important;
            }
            &-6 {
                margin: $spacer--semi-medium !important;
            }
            &-7 {
                margin: $spacer--large !important;
            }
            &-8 {
                margin: $spacer--l !important;
            }
            &-9 {
                margin: $spacer--semi-large !important;
            }
            &-10 {
                margin: $spacer--xl !important;
            }
            &-11 {
                margin: $spacer--extra-large !important;
            }
            &-12 {
                margin: $spacer--xxl !important;
            }
            &-13 {
                margin: $spacer--extra-extra-large !important;
            }
            &-14 {
                margin: $spacer--larger !important;
            }
            &-xs {
                margin: $spacer--extra-small !important;
            }
            &-s {
                margin: $spacer--small !important;
            }
            &-default {
                margin: $spacer !important;
            }
            &-m {
                margin: $spacer--medium !important;
            }
            &-l {
                margin: $spacer--large !important;
            }
            &-xl {
                margin: $spacer--extra-large !important;
            }
            &-xxl {
                margin: $spacer--extra-extra-large !important;
            }
            &-vc {
                &-0 {
                    margin-top: 0 !important;
                    margin-bottom: 0 !important;
                }
                &-1 {
                    margin-top: $spacer--extra-small !important;
                    margin-bottom: $spacer--extra-small !important;
                }
                &-2 {
                    margin-top: $spacer !important;
                    margin-bottom: $spacer !important;
                }
                &-3 {
                    margin-top: $spacer--small !important;
                    margin-bottom: $spacer--small !important;
                }
                &-4 {
                    margin-top: $spacer--medium !important;
                    margin-bottom: $spacer--medium !important;
                }
                &-5 {
                    margin-top: $spacer--m !important;
                    margin-bottom: $spacer--m !important;
                }
                &-6 {
                    margin-top: $spacer--semi-medium !important;
                    margin-bottom: $spacer--semi-medium !important;
                }
                &-7 {
                    margin-top: $spacer--large !important;
                    margin-bottom: $spacer--large !important;
                }
                &-8 {
                    margin-top: $spacer--l !important;
                    margin-bottom: $spacer--l !important;
                }
                &-9 {
                    margin-top: $spacer--semi-large !important;
                    margin-bottom: $spacer--semi-large !important;
                }
                &-10 {
                    margin-top: $spacer--xl !important;
                    margin-bottom: $spacer--xl !important;
                }
                &-11 {
                    margin-top: $spacer--extra-large !important;
                    margin-bottom: $spacer--extra-large !important;
                }
                &-12 {
                    margin-top: $spacer--xxl !important;
                    margin-bottom: $spacer--xxl !important;
                }
                &-13 {
                    margin-top: $spacer--extra-extra-large !important;
                    margin-bottom: $spacer--extra-extra-large !important;
                }
                &-14 {
                    margin-top: $spacer--larger !important;
                    margin-bottom: $spacer--larger !important;
                }
                &-xs {
                    margin-top: $spacer--extra-small !important;
                    margin-bottom: $spacer--extra-small !important;
                }
                &-s {
                    margin-top: $spacer--small !important;
                    margin-bottom: $spacer--small !important;
                }
                &-default {
                    margin-top: $spacer !important;
                    margin-bottom: $spacer !important;
                }
                &-m {
                    margin-top: $spacer--medium !important;
                    margin-bottom: $spacer--medium !important;
                }
                &-l {
                    margin-top: $spacer--large !important;
                    margin-bottom: $spacer--large !important;
                }
                &-xl {
                    margin-top: $spacer--extra-large !important;
                    margin-bottom: $spacer--extra-large !important;
                }
                &-xxl {
                    margin-top: $spacer--extra-extra-large !important;
                    margin-bottom: $spacer--extra-extra-large !important;
                }
            }
            &-hz {
                &-0 {
                    margin-right: 0 !important;
                    margin-left: 0 !important;
                }
                &-1 {
                    margin-right: $spacer--extra-small !important;
                    margin-left: $spacer--extra-small !important;
                }
                &-2 {
                    margin-right: $spacer !important;
                    margin-left: $spacer !important;
                }
                &-3 {
                    margin-right: $spacer--small !important;
                    margin-left: $spacer--small !important;
                }
                &-4 {
                    margin-right: $spacer--medium !important;
                    margin-left: $spacer--medium !important;
                }
                &-5 {
                    margin-right: $spacer--m !important;
                    margin-left: $spacer--m !important;
                }
                &-6 {
                    margin-right: $spacer--semi-medium !important;
                    margin-left: $spacer--semi-medium !important;
                }
                &-7 {
                    margin-right: $spacer--large !important;
                    margin-left: $spacer--large !important;
                }
                &-8 {
                    margin-right: $spacer--l !important;
                    margin-left: $spacer--l !important;
                }
                &-9 {
                    margin-right: $spacer--semi-large !important;
                    margin-left: $spacer--semi-large !important;
                }
                &-10 {
                    margin-right: $spacer--xl !important;
                    margin-left: $spacer--xl !important;
                }
                &-11 {
                    margin-right: $spacer--extra-large !important;
                    margin-left: $spacer--extra-large !important;
                }
                &-12 {
                    margin-right: $spacer--xxl !important;
                    margin-left: $spacer--xxl !important;
                }
                &-13 {
                    margin-right: $spacer--extra-extra-large !important;
                    margin-left: $spacer--extra-extra-large !important;
                }
                &-14 {
                    margin-right: $spacer--larger !important;
                    margin-left: $spacer--larger !important;
                }
                &-xs {
                    margin-left: $spacer--extra-small !important;
                    margin-right: $spacer--extra-small !important;
                }
                &-s {
                    margin-left: $spacer--small !important;
                    margin-right: $spacer--small !important;
                }
                &-default {
                    margin-left: $spacer !important;
                    margin-right: $spacer !important;
                }
                &-m {
                    margin-left: $spacer--medium !important;
                    margin-right: $spacer--medium !important;
                }
                &-l {
                    margin-left: $spacer--large !important;
                    margin-right: $spacer--large !important;
                }
                &-xl {
                    margin-left: $spacer--extra-large !important;
                    margin-right: $spacer--extra-large !important;
                }
                &-xxl {
                    margin-left: $spacer--extra-extra-large !important;
                    margin-right: $spacer--extra-extra-large !important;
                }
            }
            &t {
                &-0 {
                    margin-top: 0 !important;
                }
                &-1 {
                    margin-top: $spacer--extra-small !important;
                }
                &-2 {
                    margin-top: $spacer !important;
                }
                &-3 {
                    margin-top: $spacer--small !important;
                }
                &-4 {
                    margin-top: $spacer--medium !important;
                }
                &-5 {
                    margin-top: $spacer--m !important;
                }
                &-6 {
                    margin-top: $spacer--semi-medium !important;
                }
                &-7 {
                    margin-top: $spacer--large !important;
                }
                &-8 {
                    margin-top: $spacer--l !important;
                }
                &-9 {
                    margin-top: $spacer--semi-large !important;
                }
                &-10 {
                    margin-top: $spacer--xl !important;
                }
                &-11 {
                    margin-top: $spacer--extra-large !important;
                }
                &-12 {
                    margin-top: $spacer--xxl !important;
                }
                &-13 {
                    margin-top: $spacer--extra-extra-large !important;
                }
                &-14 {
                    margin-top: $spacer--larger !important;
                }
                &-xs {
                    margin-top: $spacer--extra-small !important;
                }
                &-s {
                    margin-top: $spacer--small !important;
                }
                &-default {
                    margin-top: $spacer !important;
                }
                &-m {
                    margin-top: $spacer--medium !important;
                }
                &-l {
                    margin-top: $spacer--large !important;
                }
                &-xl {
                    margin-top: $spacer--extra-large !important;
                }
                &-xxl {
                    margin-top: $spacer--extra-extra-large !important;
                }
            }
            &r {
                &-0 {
                    margin-right: 0 !important;
                }
                &-1 {
                    margin-right: $spacer--extra-small !important;
                }
                &-2 {
                    margin-right: $spacer !important;
                }
                &-3 {
                    margin-right: $spacer--small !important;
                }
                &-4 {
                    margin-right: $spacer--medium !important;
                }
                &-5 {
                    margin-right: $spacer--m !important;
                }
                &-6 {
                    margin-right: $spacer--semi-medium !important;
                }
                &-7 {
                    margin-right: $spacer--large !important;
                }
                &-8 {
                    margin-right: $spacer--l !important;
                }
                &-9 {
                    margin-right: $spacer--semi-large !important;
                }
                &-10 {
                    margin-right: $spacer--xl !important;
                }
                &-11 {
                    margin-right: $spacer--extra-large !important;
                }
                &-12 {
                    margin-right: $spacer--xxl !important;
                }
                &-13 {
                    margin-right: $spacer--extra-extra-large !important;
                }
                &-14 {
                    margin-right: $spacer--larger !important;
                }
                &-xs {
                    margin-right: $spacer--extra-small !important;
                }
                &-s {
                    margin-right: $spacer--small !important;
                }
                &-default {
                    margin-right: $spacer !important;
                }
                &-m {
                    margin-right: $spacer--medium !important;
                }
                &-l {
                    margin-right: $spacer--large !important;
                }
                &-xl {
                    margin-right: $spacer--extra-large !important;
                }
                &-xxl {
                    margin-right: $spacer--extra-extra-large !important;
                }
            }
            &b {
                &-0 {
                    margin-bottom: 0 !important;
                }
                &-1 {
                    margin-bottom: $spacer--extra-small !important;
                }
                &-2 {
                    margin-bottom: $spacer !important;
                }
                &-3 {
                    margin-bottom: $spacer--small !important;
                }
                &-4 {
                    margin-bottom: $spacer--medium !important;
                }
                &-5 {
                    margin-bottom: $spacer--m !important;
                }
                &-6 {
                    margin-bottom: $spacer--semi-medium !important;
                }
                &-7 {
                    margin-bottom: $spacer--large !important;
                }
                &-8 {
                    margin-bottom: $spacer--l !important;
                }
                &-9 {
                    margin-bottom: $spacer--semi-large !important;
                }
                &-10 {
                    margin-bottom: $spacer--xl !important;
                }
                &-11 {
                    margin-bottom: $spacer--extra-large !important;
                }
                &-12 {
                    margin-bottom: $spacer--xxl !important;
                }
                &-13 {
                    margin-bottom: $spacer--extra-extra-large !important;
                }
                &-14 {
                    margin-bottom: $spacer--larger !important;
                }
                &-xs {
                    margin-bottom: $spacer--extra-small !important;
                }
                &-s {
                    margin-bottom: $spacer--small !important;
                }
                &-default {
                    margin-bottom: $spacer !important;
                }
                &-m {
                    margin-bottom: $spacer--medium !important;
                }
                &-l {
                    margin-bottom: $spacer--large !important;
                }
                &-xl {
                    margin-bottom: $spacer--extra-large !important;
                }
                &-xxl {
                    margin-bottom: $spacer--extra-extra-large !important;
                }
            }
            &l {
                &-0 {
                    margin-left: 0 !important;
                }
                &-1 {
                    margin-left: $spacer--extra-small !important;
                }
                &-2 {
                    margin-left: $spacer !important;
                }
                &-3 {
                    margin-left: $spacer--small !important;
                }
                &-4 {
                    margin-left: $spacer--medium !important;
                }
                &-5 {
                    margin-left: $spacer--m !important;
                }
                &-6 {
                    margin-left: $spacer--semi-medium !important;
                }
                &-7 {
                    margin-left: $spacer--large !important;
                }
                &-8 {
                    margin-left: $spacer--l !important;
                }
                &-9 {
                    margin-left: $spacer--semi-large !important;
                }
                &-10 {
                    margin-left: $spacer--xl !important;
                }
                &-11 {
                    margin-left: $spacer--extra-large !important;
                }
                &-12 {
                    margin-left: $spacer--xxl !important;
                }
                &-13 {
                    margin-left: $spacer--extra-extra-large !important;
                }
                &-14 {
                    margin-left: $spacer--larger !important;
                }
                &-xs {
                    margin-left: $spacer--extra-small !important;
                }
                &-s {
                    margin-left: $spacer--small !important;
                }
                &-default {
                    margin-left: $spacer !important;
                }
                &-m {
                    margin-left: $spacer--medium !important;
                }
                &-l {
                    margin-left: $spacer--large !important;
                }
                &-xl {
                    margin-left: $spacer--extra-large !important;
                }
                &-xxl {
                    margin-left: $spacer--extra-extra-large !important;
                }
            }
        }
    }
}
@include min-screen($screen-3xl) {
    .x3l {
        &-m {
            &-0 {
                margin: 0 !important;
            }
            &-1 {
                margin: $spacer--extra-small !important;
            }
            &-2 {
                margin: $spacer !important;
            }
            &-3 {
                margin: $spacer--small !important;
            }
            &-4 {
                margin: $spacer--medium !important;
            }
            &-5 {
                margin: $spacer--m !important;
            }
            &-6 {
                margin: $spacer--semi-medium !important;
            }
            &-7 {
                margin: $spacer--large !important;
            }
            &-8 {
                margin: $spacer--l !important;
            }
            &-9 {
                margin: $spacer--semi-large !important;
            }
            &-10 {
                margin: $spacer--xl !important;
            }
            &-11 {
                margin: $spacer--extra-large !important;
            }
            &-12 {
                margin: $spacer--xxl !important;
            }
            &-13 {
                margin: $spacer--extra-extra-large !important;
            }
            &-14 {
                margin: $spacer--larger !important;
            }
            &-xs {
                margin: $spacer--extra-small !important;
            }
            &-s {
                margin: $spacer--small !important;
            }
            &-default {
                margin: $spacer !important;
            }
            &-m {
                margin: $spacer--medium !important;
            }
            &-l {
                margin: $spacer--large !important;
            }
            &-xl {
                margin: $spacer--extra-large !important;
            }
            &-xxl {
                margin: $spacer--extra-extra-large !important;
            }
            &-vc {
                &-0 {
                    margin-top: 0 !important;
                    margin-bottom: 0 !important;
                }
                &-1 {
                    margin-top: $spacer--extra-small !important;
                    margin-bottom: $spacer--extra-small !important;
                }
                &-2 {
                    margin-top: $spacer !important;
                    margin-bottom: $spacer !important;
                }
                &-3 {
                    margin-top: $spacer--small !important;
                    margin-bottom: $spacer--small !important;
                }
                &-4 {
                    margin-top: $spacer--medium !important;
                    margin-bottom: $spacer--medium !important;
                }
                &-5 {
                    margin-top: $spacer--m !important;
                    margin-bottom: $spacer--m !important;
                }
                &-6 {
                    margin-top: $spacer--semi-medium !important;
                    margin-bottom: $spacer--semi-medium !important;
                }
                &-7 {
                    margin-top: $spacer--large !important;
                    margin-bottom: $spacer--large !important;
                }
                &-8 {
                    margin-top: $spacer--l !important;
                    margin-bottom: $spacer--l !important;
                }
                &-9 {
                    margin-top: $spacer--semi-large !important;
                    margin-bottom: $spacer--semi-large !important;
                }
                &-10 {
                    margin-top: $spacer--xl !important;
                    margin-bottom: $spacer--xl !important;
                }
                &-11 {
                    margin-top: $spacer--extra-large !important;
                    margin-bottom: $spacer--extra-large !important;
                }
                &-12 {
                    margin-top: $spacer--xxl !important;
                    margin-bottom: $spacer--xxl !important;
                }
                &-13 {
                    margin-top: $spacer--extra-extra-large !important;
                    margin-bottom: $spacer--extra-extra-large !important;
                }
                &-14 {
                    margin-top: $spacer--larger !important;
                    margin-bottom: $spacer--larger !important;
                }
                &-xs {
                    margin-top: $spacer--extra-small !important;
                    margin-bottom: $spacer--extra-small !important;
                }
                &-s {
                    margin-top: $spacer--small !important;
                    margin-bottom: $spacer--small !important;
                }
                &-default {
                    margin-top: $spacer !important;
                    margin-bottom: $spacer !important;
                }
                &-m {
                    margin-top: $spacer--medium !important;
                    margin-bottom: $spacer--medium !important;
                }
                &-l {
                    margin-top: $spacer--large !important;
                    margin-bottom: $spacer--large !important;
                }
                &-xl {
                    margin-top: $spacer--extra-large !important;
                    margin-bottom: $spacer--extra-large !important;
                }
                &-xxl {
                    margin-top: $spacer--extra-extra-large !important;
                    margin-bottom: $spacer--extra-extra-large !important;
                }
            }
            &-hz {
                &-0 {
                    margin-right: 0 !important;
                    margin-left: 0 !important;
                }
                &-1 {
                    margin-right: $spacer--extra-small !important;
                    margin-left: $spacer--extra-small !important;
                }
                &-2 {
                    margin-right: $spacer !important;
                    margin-left: $spacer !important;
                }
                &-3 {
                    margin-right: $spacer--small !important;
                    margin-left: $spacer--small !important;
                }
                &-4 {
                    margin-right: $spacer--medium !important;
                    margin-left: $spacer--medium !important;
                }
                &-5 {
                    margin-right: $spacer--m !important;
                    margin-left: $spacer--m !important;
                }
                &-6 {
                    margin-right: $spacer--semi-medium !important;
                    margin-left: $spacer--semi-medium !important;
                }
                &-7 {
                    margin-right: $spacer--large !important;
                    margin-left: $spacer--large !important;
                }
                &-8 {
                    margin-right: $spacer--l !important;
                    margin-left: $spacer--l !important;
                }
                &-9 {
                    margin-right: $spacer--semi-large !important;
                    margin-left: $spacer--semi-large !important;
                }
                &-10 {
                    margin-right: $spacer--xl !important;
                    margin-left: $spacer--xl !important;
                }
                &-11 {
                    margin-right: $spacer--extra-large !important;
                    margin-left: $spacer--extra-large !important;
                }
                &-12 {
                    margin-right: $spacer--xxl !important;
                    margin-left: $spacer--xxl !important;
                }
                &-13 {
                    margin-right: $spacer--extra-extra-large !important;
                    margin-left: $spacer--extra-extra-large !important;
                }
                &-14 {
                    margin-right: $spacer--larger !important;
                    margin-left: $spacer--larger !important;
                }
                &-xs {
                    margin-left: $spacer--extra-small !important;
                    margin-right: $spacer--extra-small !important;
                }
                &-s {
                    margin-left: $spacer--small !important;
                    margin-right: $spacer--small !important;
                }
                &-default {
                    margin-left: $spacer !important;
                    margin-right: $spacer !important;
                }
                &-m {
                    margin-left: $spacer--medium !important;
                    margin-right: $spacer--medium !important;
                }
                &-l {
                    margin-left: $spacer--large !important;
                    margin-right: $spacer--large !important;
                }
                &-xl {
                    margin-left: $spacer--extra-large !important;
                    margin-right: $spacer--extra-large !important;
                }
                &-xxl {
                    margin-left: $spacer--extra-extra-large !important;
                    margin-right: $spacer--extra-extra-large !important;
                }
            }
            &t {
                &-0 {
                    margin-top: 0 !important;
                }
                &-1 {
                    margin-top: $spacer--extra-small !important;
                }
                &-2 {
                    margin-top: $spacer !important;
                }
                &-3 {
                    margin-top: $spacer--small !important;
                }
                &-4 {
                    margin-top: $spacer--medium !important;
                }
                &-5 {
                    margin-top: $spacer--m !important;
                }
                &-6 {
                    margin-top: $spacer--semi-medium !important;
                }
                &-7 {
                    margin-top: $spacer--large !important;
                }
                &-8 {
                    margin-top: $spacer--l !important;
                }
                &-9 {
                    margin-top: $spacer--semi-large !important;
                }
                &-10 {
                    margin-top: $spacer--xl !important;
                }
                &-11 {
                    margin-top: $spacer--extra-large !important;
                }
                &-12 {
                    margin-top: $spacer--xxl !important;
                }
                &-13 {
                    margin-top: $spacer--extra-extra-large !important;
                }
                &-14 {
                    margin-top: $spacer--larger !important;
                }
                &-xs {
                    margin-top: $spacer--extra-small !important;
                }
                &-s {
                    margin-top: $spacer--small !important;
                }
                &-default {
                    margin-top: $spacer !important;
                }
                &-m {
                    margin-top: $spacer--medium !important;
                }
                &-l {
                    margin-top: $spacer--large !important;
                }
                &-xl {
                    margin-top: $spacer--extra-large !important;
                }
                &-xxl {
                    margin-top: $spacer--extra-extra-large !important;
                }
            }
            &r {
                &-0 {
                    margin-right: 0 !important;
                }
                &-1 {
                    margin-right: $spacer--extra-small !important;
                }
                &-2 {
                    margin-right: $spacer !important;
                }
                &-3 {
                    margin-right: $spacer--small !important;
                }
                &-4 {
                    margin-right: $spacer--medium !important;
                }
                &-5 {
                    margin-right: $spacer--m !important;
                }
                &-6 {
                    margin-right: $spacer--semi-medium !important;
                }
                &-7 {
                    margin-right: $spacer--large !important;
                }
                &-8 {
                    margin-right: $spacer--l !important;
                }
                &-9 {
                    margin-right: $spacer--semi-large !important;
                }
                &-10 {
                    margin-right: $spacer--xl !important;
                }
                &-11 {
                    margin-right: $spacer--extra-large !important;
                }
                &-12 {
                    margin-right: $spacer--xxl !important;
                }
                &-13 {
                    margin-right: $spacer--extra-extra-large !important;
                }
                &-14 {
                    margin-right: $spacer--larger !important;
                }
                &-xs {
                    margin-right: $spacer--extra-small !important;
                }
                &-s {
                    margin-right: $spacer--small !important;
                }
                &-default {
                    margin-right: $spacer !important;
                }
                &-m {
                    margin-right: $spacer--medium !important;
                }
                &-l {
                    margin-right: $spacer--large !important;
                }
                &-xl {
                    margin-right: $spacer--extra-large !important;
                }
                &-xxl {
                    margin-right: $spacer--extra-extra-large !important;
                }
            }
            &b {
                &-0 {
                    margin-bottom: 0 !important;
                }
                &-1 {
                    margin-bottom: $spacer--extra-small !important;
                }
                &-2 {
                    margin-bottom: $spacer !important;
                }
                &-3 {
                    margin-bottom: $spacer--small !important;
                }
                &-4 {
                    margin-bottom: $spacer--medium !important;
                }
                &-5 {
                    margin-bottom: $spacer--m !important;
                }
                &-6 {
                    margin-bottom: $spacer--semi-medium !important;
                }
                &-7 {
                    margin-bottom: $spacer--large !important;
                }
                &-8 {
                    margin-bottom: $spacer--l !important;
                }
                &-9 {
                    margin-bottom: $spacer--semi-large !important;
                }
                &-10 {
                    margin-bottom: $spacer--xl !important;
                }
                &-11 {
                    margin-bottom: $spacer--extra-large !important;
                }
                &-12 {
                    margin-bottom: $spacer--xxl !important;
                }
                &-13 {
                    margin-bottom: $spacer--extra-extra-large !important;
                }
                &-14 {
                    margin-bottom: $spacer--larger !important;
                }
                &-xs {
                    margin-bottom: $spacer--extra-small !important;
                }
                &-s {
                    margin-bottom: $spacer--small !important;
                }
                &-default {
                    margin-bottom: $spacer !important;
                }
                &-m {
                    margin-bottom: $spacer--medium !important;
                }
                &-l {
                    margin-bottom: $spacer--large !important;
                }
                &-xl {
                    margin-bottom: $spacer--extra-large !important;
                }
                &-xxl {
                    margin-bottom: $spacer--extra-extra-large !important;
                }
            }
            &l {
                &-0 {
                    margin-left: 0 !important;
                }
                &-1 {
                    margin-left: $spacer--extra-small !important;
                }
                &-2 {
                    margin-left: $spacer !important;
                }
                &-3 {
                    margin-left: $spacer--small !important;
                }
                &-4 {
                    margin-left: $spacer--medium !important;
                }
                &-5 {
                    margin-left: $spacer--m !important;
                }
                &-6 {
                    margin-left: $spacer--semi-medium !important;
                }
                &-7 {
                    margin-left: $spacer--large !important;
                }
                &-8 {
                    margin-left: $spacer--l !important;
                }
                &-9 {
                    margin-left: $spacer--semi-large !important;
                }
                &-10 {
                    margin-left: $spacer--xl !important;
                }
                &-11 {
                    margin-left: $spacer--extra-large !important;
                }
                &-12 {
                    margin-left: $spacer--xxl !important;
                }
                &-13 {
                    margin-left: $spacer--extra-extra-large !important;
                }
                &-14 {
                    margin-left: $spacer--larger !important;
                }
                &-xs {
                    margin-left: $spacer--extra-small !important;
                }
                &-s {
                    margin-left: $spacer--small !important;
                }
                &-default {
                    margin-left: $spacer !important;
                }
                &-m {
                    margin-left: $spacer--medium !important;
                }
                &-l {
                    margin-left: $spacer--large !important;
                }
                &-xl {
                    margin-left: $spacer--extra-large !important;
                }
                &-xxl {
                    margin-left: $spacer--extra-extra-large !important;
                }
            }
        }
    }
}
@include min-screen($screen-xxxl) {
    .xxxl {
        &-m {
            &-0 {
                margin: 0 !important;
            }
            &-1 {
                margin: $spacer--extra-small !important;
            }
            &-2 {
                margin: $spacer !important;
            }
            &-3 {
                margin: $spacer--small !important;
            }
            &-4 {
                margin: $spacer--medium !important;
            }
            &-5 {
                margin: $spacer--m !important;
            }
            &-6 {
                margin: $spacer--semi-medium !important;
            }
            &-7 {
                margin: $spacer--large !important;
            }
            &-8 {
                margin: $spacer--l !important;
            }
            &-9 {
                margin: $spacer--semi-large !important;
            }
            &-10 {
                margin: $spacer--xl !important;
            }
            &-11 {
                margin: $spacer--extra-large !important;
            }
            &-12 {
                margin: $spacer--xxl !important;
            }
            &-13 {
                margin: $spacer--extra-extra-large !important;
            }
            &-14 {
                margin: $spacer--larger !important;
            }
            &-xs {
                margin: $spacer--extra-small !important;
            }
            &-s {
                margin: $spacer--small !important;
            }
            &-default {
                margin: $spacer !important;
            }
            &-m {
                margin: $spacer--medium !important;
            }
            &-l {
                margin: $spacer--large !important;
            }
            &-xl {
                margin: $spacer--extra-large !important;
            }
            &-xxl {
                margin: $spacer--extra-extra-large !important;
            }
            &-vc {
                &-0 {
                    margin-top: 0 !important;
                    margin-bottom: 0 !important;
                }
                &-1 {
                    margin-top: $spacer--extra-small !important;
                    margin-bottom: $spacer--extra-small !important;
                }
                &-2 {
                    margin-top: $spacer !important;
                    margin-bottom: $spacer !important;
                }
                &-3 {
                    margin-top: $spacer--small !important;
                    margin-bottom: $spacer--small !important;
                }
                &-4 {
                    margin-top: $spacer--medium !important;
                    margin-bottom: $spacer--medium !important;
                }
                &-5 {
                    margin-top: $spacer--m !important;
                    margin-bottom: $spacer--m !important;
                }
                &-6 {
                    margin-top: $spacer--semi-medium !important;
                    margin-bottom: $spacer--semi-medium !important;
                }
                &-7 {
                    margin-top: $spacer--large !important;
                    margin-bottom: $spacer--large !important;
                }
                &-8 {
                    margin-top: $spacer--l !important;
                    margin-bottom: $spacer--l !important;
                }
                &-9 {
                    margin-top: $spacer--semi-large !important;
                    margin-bottom: $spacer--semi-large !important;
                }
                &-10 {
                    margin-top: $spacer--xl !important;
                    margin-bottom: $spacer--xl !important;
                }
                &-11 {
                    margin-top: $spacer--extra-large !important;
                    margin-bottom: $spacer--extra-large !important;
                }
                &-12 {
                    margin-top: $spacer--xxl !important;
                    margin-bottom: $spacer--xxl !important;
                }
                &-13 {
                    margin-top: $spacer--extra-extra-large !important;
                    margin-bottom: $spacer--extra-extra-large !important;
                }
                &-14 {
                    margin-top: $spacer--larger !important;
                    margin-bottom: $spacer--larger !important;
                }
                &-xs {
                    margin-top: $spacer--extra-small !important;
                    margin-bottom: $spacer--extra-small !important;
                }
                &-s {
                    margin-top: $spacer--small !important;
                    margin-bottom: $spacer--small !important;
                }
                &-default {
                    margin-top: $spacer !important;
                    margin-bottom: $spacer !important;
                }
                &-m {
                    margin-top: $spacer--medium !important;
                    margin-bottom: $spacer--medium !important;
                }
                &-l {
                    margin-top: $spacer--large !important;
                    margin-bottom: $spacer--large !important;
                }
                &-xl {
                    margin-top: $spacer--extra-large !important;
                    margin-bottom: $spacer--extra-large !important;
                }
                &-xxl {
                    margin-top: $spacer--extra-extra-large !important;
                    margin-bottom: $spacer--extra-extra-large !important;
                }
            }
            &-hz {
                &-0 {
                    margin-right: 0 !important;
                    margin-left: 0 !important;
                }
                &-1 {
                    margin-right: $spacer--extra-small !important;
                    margin-left: $spacer--extra-small !important;
                }
                &-2 {
                    margin-right: $spacer !important;
                    margin-left: $spacer !important;
                }
                &-3 {
                    margin-right: $spacer--small !important;
                    margin-left: $spacer--small !important;
                }
                &-4 {
                    margin-right: $spacer--medium !important;
                    margin-left: $spacer--medium !important;
                }
                &-5 {
                    margin-right: $spacer--m !important;
                    margin-left: $spacer--m !important;
                }
                &-6 {
                    margin-right: $spacer--semi-medium !important;
                    margin-left: $spacer--semi-medium !important;
                }
                &-7 {
                    margin-right: $spacer--large !important;
                    margin-left: $spacer--large !important;
                }
                &-8 {
                    margin-right: $spacer--l !important;
                    margin-left: $spacer--l !important;
                }
                &-9 {
                    margin-right: $spacer--semi-large !important;
                    margin-left: $spacer--semi-large !important;
                }
                &-10 {
                    margin-right: $spacer--xl !important;
                    margin-left: $spacer--xl !important;
                }
                &-11 {
                    margin-right: $spacer--extra-large !important;
                    margin-left: $spacer--extra-large !important;
                }
                &-12 {
                    margin-right: $spacer--xxl !important;
                    margin-left: $spacer--xxl !important;
                }
                &-13 {
                    margin-right: $spacer--extra-extra-large !important;
                    margin-left: $spacer--extra-extra-large !important;
                }
                &-14 {
                    margin-right: $spacer--larger !important;
                    margin-left: $spacer--larger !important;
                }
                &-xs {
                    margin-left: $spacer--extra-small !important;
                    margin-right: $spacer--extra-small !important;
                }
                &-s {
                    margin-left: $spacer--small !important;
                    margin-right: $spacer--small !important;
                }
                &-default {
                    margin-left: $spacer !important;
                    margin-right: $spacer !important;
                }
                &-m {
                    margin-left: $spacer--medium !important;
                    margin-right: $spacer--medium !important;
                }
                &-l {
                    margin-left: $spacer--large !important;
                    margin-right: $spacer--large !important;
                }
                &-xl {
                    margin-left: $spacer--extra-large !important;
                    margin-right: $spacer--extra-large !important;
                }
                &-xxl {
                    margin-left: $spacer--extra-extra-large !important;
                    margin-right: $spacer--extra-extra-large !important;
                }
            }
            &t {
                &-0 {
                    margin-top: 0 !important;
                }
                &-1 {
                    margin-top: $spacer--extra-small !important;
                }
                &-2 {
                    margin-top: $spacer !important;
                }
                &-3 {
                    margin-top: $spacer--small !important;
                }
                &-4 {
                    margin-top: $spacer--medium !important;
                }
                &-5 {
                    margin-top: $spacer--m !important;
                }
                &-6 {
                    margin-top: $spacer--semi-medium !important;
                }
                &-7 {
                    margin-top: $spacer--large !important;
                }
                &-8 {
                    margin-top: $spacer--l !important;
                }
                &-9 {
                    margin-top: $spacer--semi-large !important;
                }
                &-10 {
                    margin-top: $spacer--xl !important;
                }
                &-11 {
                    margin-top: $spacer--extra-large !important;
                }
                &-12 {
                    margin-top: $spacer--xxl !important;
                }
                &-13 {
                    margin-top: $spacer--extra-extra-large !important;
                }
                &-14 {
                    margin-top: $spacer--larger !important;
                }
                &-xs {
                    margin-top: $spacer--extra-small !important;
                }
                &-s {
                    margin-top: $spacer--small !important;
                }
                &-default {
                    margin-top: $spacer !important;
                }
                &-m {
                    margin-top: $spacer--medium !important;
                }
                &-l {
                    margin-top: $spacer--large !important;
                }
                &-xl {
                    margin-top: $spacer--extra-large !important;
                }
                &-xxl {
                    margin-top: $spacer--extra-extra-large !important;
                }
            }
            &r {
                &-0 {
                    margin-right: 0 !important;
                }
                &-1 {
                    margin-right: $spacer--extra-small !important;
                }
                &-2 {
                    margin-right: $spacer !important;
                }
                &-3 {
                    margin-right: $spacer--small !important;
                }
                &-4 {
                    margin-right: $spacer--medium !important;
                }
                &-5 {
                    margin-right: $spacer--m !important;
                }
                &-6 {
                    margin-right: $spacer--semi-medium !important;
                }
                &-7 {
                    margin-right: $spacer--large !important;
                }
                &-8 {
                    margin-right: $spacer--l !important;
                }
                &-9 {
                    margin-right: $spacer--semi-large !important;
                }
                &-10 {
                    margin-right: $spacer--xl !important;
                }
                &-11 {
                    margin-right: $spacer--extra-large !important;
                }
                &-12 {
                    margin-right: $spacer--xxl !important;
                }
                &-13 {
                    margin-right: $spacer--extra-extra-large !important;
                }
                &-14 {
                    margin-right: $spacer--larger !important;
                }
                &-xs {
                    margin-right: $spacer--extra-small !important;
                }
                &-s {
                    margin-right: $spacer--small !important;
                }
                &-default {
                    margin-right: $spacer !important;
                }
                &-m {
                    margin-right: $spacer--medium !important;
                }
                &-l {
                    margin-right: $spacer--large !important;
                }
                &-xl {
                    margin-right: $spacer--extra-large !important;
                }
                &-xxl {
                    margin-right: $spacer--extra-extra-large !important;
                }
            }
            &b {
                &-0 {
                    margin-bottom: 0 !important;
                }
                &-1 {
                    margin-bottom: $spacer--extra-small !important;
                }
                &-2 {
                    margin-bottom: $spacer !important;
                }
                &-3 {
                    margin-bottom: $spacer--small !important;
                }
                &-4 {
                    margin-bottom: $spacer--medium !important;
                }
                &-5 {
                    margin-bottom: $spacer--m !important;
                }
                &-6 {
                    margin-bottom: $spacer--semi-medium !important;
                }
                &-7 {
                    margin-bottom: $spacer--large !important;
                }
                &-8 {
                    margin-bottom: $spacer--l !important;
                }
                &-9 {
                    margin-bottom: $spacer--semi-large !important;
                }
                &-10 {
                    margin-bottom: $spacer--xl !important;
                }
                &-11 {
                    margin-bottom: $spacer--extra-large !important;
                }
                &-12 {
                    margin-bottom: $spacer--xxl !important;
                }
                &-13 {
                    margin-bottom: $spacer--extra-extra-large !important;
                }
                &-14 {
                    margin-bottom: $spacer--larger !important;
                }
                &-xs {
                    margin-bottom: $spacer--extra-small !important;
                }
                &-s {
                    margin-bottom: $spacer--small !important;
                }
                &-default {
                    margin-bottom: $spacer !important;
                }
                &-m {
                    margin-bottom: $spacer--medium !important;
                }
                &-l {
                    margin-bottom: $spacer--large !important;
                }
                &-xl {
                    margin-bottom: $spacer--extra-large !important;
                }
                &-xxl {
                    margin-bottom: $spacer--extra-extra-large !important;
                }
            }
            &l {
                &-0 {
                    margin-left: 0 !important;
                }
                &-1 {
                    margin-left: $spacer--extra-small !important;
                }
                &-2 {
                    margin-left: $spacer !important;
                }
                &-3 {
                    margin-left: $spacer--small !important;
                }
                &-4 {
                    margin-left: $spacer--medium !important;
                }
                &-5 {
                    margin-left: $spacer--m !important;
                }
                &-6 {
                    margin-left: $spacer--semi-medium !important;
                }
                &-7 {
                    margin-left: $spacer--large !important;
                }
                &-8 {
                    margin-left: $spacer--l !important;
                }
                &-9 {
                    margin-left: $spacer--semi-large !important;
                }
                &-10 {
                    margin-left: $spacer--xl !important;
                }
                &-11 {
                    margin-left: $spacer--extra-large !important;
                }
                &-12 {
                    margin-left: $spacer--xxl !important;
                }
                &-13 {
                    margin-left: $spacer--extra-extra-large !important;
                }
                &-14 {
                    margin-left: $spacer--larger !important;
                }
                &-xs {
                    margin-left: $spacer--extra-small !important;
                }
                &-s {
                    margin-left: $spacer--small !important;
                }
                &-default {
                    margin-left: $spacer !important;
                }
                &-m {
                    margin-left: $spacer--medium !important;
                }
                &-l {
                    margin-left: $spacer--large !important;
                }
                &-xl {
                    margin-left: $spacer--extra-large !important;
                }
                &-xxl {
                    margin-left: $spacer--extra-extra-large !important;
                }
            }
        }
    }
}
@include min-screen($screen-4xl) {
    .x4l {
        &-m {
            &-0 {
                margin: 0 !important;
            }
            &-1 {
                margin: $spacer--extra-small !important;
            }
            &-2 {
                margin: $spacer !important;
            }
            &-3 {
                margin: $spacer--small !important;
            }
            &-4 {
                margin: $spacer--medium !important;
            }
            &-5 {
                margin: $spacer--m !important;
            }
            &-6 {
                margin: $spacer--semi-medium !important;
            }
            &-7 {
                margin: $spacer--large !important;
            }
            &-8 {
                margin: $spacer--l !important;
            }
            &-9 {
                margin: $spacer--semi-large !important;
            }
            &-10 {
                margin: $spacer--xl !important;
            }
            &-11 {
                margin: $spacer--extra-large !important;
            }
            &-12 {
                margin: $spacer--xxl !important;
            }
            &-13 {
                margin: $spacer--extra-extra-large !important;
            }
            &-14 {
                margin: $spacer--larger !important;
            }
            &-xs {
                margin: $spacer--extra-small !important;
            }
            &-s {
                margin: $spacer--small !important;
            }
            &-default {
                margin: $spacer !important;
            }
            &-m {
                margin: $spacer--medium !important;
            }
            &-l {
                margin: $spacer--large !important;
            }
            &-xl {
                margin: $spacer--extra-large !important;
            }
            &-xxl {
                margin: $spacer--extra-extra-large !important;
            }
            &-vc {
                &-0 {
                    margin-top: 0 !important;
                    margin-bottom: 0 !important;
                }
                &-1 {
                    margin-top: $spacer--extra-small !important;
                    margin-bottom: $spacer--extra-small !important;
                }
                &-2 {
                    margin-top: $spacer !important;
                    margin-bottom: $spacer !important;
                }
                &-3 {
                    margin-top: $spacer--small !important;
                    margin-bottom: $spacer--small !important;
                }
                &-4 {
                    margin-top: $spacer--medium !important;
                    margin-bottom: $spacer--medium !important;
                }
                &-5 {
                    margin-top: $spacer--m !important;
                    margin-bottom: $spacer--m !important;
                }
                &-6 {
                    margin-top: $spacer--semi-medium !important;
                    margin-bottom: $spacer--semi-medium !important;
                }
                &-7 {
                    margin-top: $spacer--large !important;
                    margin-bottom: $spacer--large !important;
                }
                &-8 {
                    margin-top: $spacer--l !important;
                    margin-bottom: $spacer--l !important;
                }
                &-9 {
                    margin-top: $spacer--semi-large !important;
                    margin-bottom: $spacer--semi-large !important;
                }
                &-10 {
                    margin-top: $spacer--xl !important;
                    margin-bottom: $spacer--xl !important;
                }
                &-11 {
                    margin-top: $spacer--extra-large !important;
                    margin-bottom: $spacer--extra-large !important;
                }
                &-12 {
                    margin-top: $spacer--xxl !important;
                    margin-bottom: $spacer--xxl !important;
                }
                &-13 {
                    margin-top: $spacer--extra-extra-large !important;
                    margin-bottom: $spacer--extra-extra-large !important;
                }
                &-14 {
                    margin-top: $spacer--larger !important;
                    margin-bottom: $spacer--larger !important;
                }
                &-xs {
                    margin-top: $spacer--extra-small !important;
                    margin-bottom: $spacer--extra-small !important;
                }
                &-s {
                    margin-top: $spacer--small !important;
                    margin-bottom: $spacer--small !important;
                }
                &-default {
                    margin-top: $spacer !important;
                    margin-bottom: $spacer !important;
                }
                &-m {
                    margin-top: $spacer--medium !important;
                    margin-bottom: $spacer--medium !important;
                }
                &-l {
                    margin-top: $spacer--large !important;
                    margin-bottom: $spacer--large !important;
                }
                &-xl {
                    margin-top: $spacer--extra-large !important;
                    margin-bottom: $spacer--extra-large !important;
                }
                &-xxl {
                    margin-top: $spacer--extra-extra-large !important;
                    margin-bottom: $spacer--extra-extra-large !important;
                }
            }
            &-hz {
                &-0 {
                    margin-right: 0 !important;
                    margin-left: 0 !important;
                }
                &-1 {
                    margin-right: $spacer--extra-small !important;
                    margin-left: $spacer--extra-small !important;
                }
                &-2 {
                    margin-right: $spacer !important;
                    margin-left: $spacer !important;
                }
                &-3 {
                    margin-right: $spacer--small !important;
                    margin-left: $spacer--small !important;
                }
                &-4 {
                    margin-right: $spacer--medium !important;
                    margin-left: $spacer--medium !important;
                }
                &-5 {
                    margin-right: $spacer--m !important;
                    margin-left: $spacer--m !important;
                }
                &-6 {
                    margin-right: $spacer--semi-medium !important;
                    margin-left: $spacer--semi-medium !important;
                }
                &-7 {
                    margin-right: $spacer--large !important;
                    margin-left: $spacer--large !important;
                }
                &-8 {
                    margin-right: $spacer--l !important;
                    margin-left: $spacer--l !important;
                }
                &-9 {
                    margin-right: $spacer--semi-large !important;
                    margin-left: $spacer--semi-large !important;
                }
                &-10 {
                    margin-right: $spacer--xl !important;
                    margin-left: $spacer--xl !important;
                }
                &-11 {
                    margin-right: $spacer--extra-large !important;
                    margin-left: $spacer--extra-large !important;
                }
                &-12 {
                    margin-right: $spacer--xxl !important;
                    margin-left: $spacer--xxl !important;
                }
                &-13 {
                    margin-right: $spacer--extra-extra-large !important;
                    margin-left: $spacer--extra-extra-large !important;
                }
                &-14 {
                    margin-right: $spacer--larger !important;
                    margin-left: $spacer--larger !important;
                }
                &-xs {
                    margin-left: $spacer--extra-small !important;
                    margin-right: $spacer--extra-small !important;
                }
                &-s {
                    margin-left: $spacer--small !important;
                    margin-right: $spacer--small !important;
                }
                &-default {
                    margin-left: $spacer !important;
                    margin-right: $spacer !important;
                }
                &-m {
                    margin-left: $spacer--medium !important;
                    margin-right: $spacer--medium !important;
                }
                &-l {
                    margin-left: $spacer--large !important;
                    margin-right: $spacer--large !important;
                }
                &-xl {
                    margin-left: $spacer--extra-large !important;
                    margin-right: $spacer--extra-large !important;
                }
                &-xxl {
                    margin-left: $spacer--extra-extra-large !important;
                    margin-right: $spacer--extra-extra-large !important;
                }
            }
            &t {
                &-0 {
                    margin-top: 0 !important;
                }
                &-1 {
                    margin-top: $spacer--extra-small !important;
                }
                &-2 {
                    margin-top: $spacer !important;
                }
                &-3 {
                    margin-top: $spacer--small !important;
                }
                &-4 {
                    margin-top: $spacer--medium !important;
                }
                &-5 {
                    margin-top: $spacer--m !important;
                }
                &-6 {
                    margin-top: $spacer--semi-medium !important;
                }
                &-7 {
                    margin-top: $spacer--large !important;
                }
                &-8 {
                    margin-top: $spacer--l !important;
                }
                &-9 {
                    margin-top: $spacer--semi-large !important;
                }
                &-10 {
                    margin-top: $spacer--xl !important;
                }
                &-11 {
                    margin-top: $spacer--extra-large !important;
                }
                &-12 {
                    margin-top: $spacer--xxl !important;
                }
                &-13 {
                    margin-top: $spacer--extra-extra-large !important;
                }
                &-14 {
                    margin-top: $spacer--larger !important;
                }
                &-xs {
                    margin-top: $spacer--extra-small !important;
                }
                &-s {
                    margin-top: $spacer--small !important;
                }
                &-default {
                    margin-top: $spacer !important;
                }
                &-m {
                    margin-top: $spacer--medium !important;
                }
                &-l {
                    margin-top: $spacer--large !important;
                }
                &-xl {
                    margin-top: $spacer--extra-large !important;
                }
                &-xxl {
                    margin-top: $spacer--extra-extra-large !important;
                }
            }
            &r {
                &-0 {
                    margin-right: 0 !important;
                }
                &-1 {
                    margin-right: $spacer--extra-small !important;
                }
                &-2 {
                    margin-right: $spacer !important;
                }
                &-3 {
                    margin-right: $spacer--small !important;
                }
                &-4 {
                    margin-right: $spacer--medium !important;
                }
                &-5 {
                    margin-right: $spacer--m !important;
                }
                &-6 {
                    margin-right: $spacer--semi-medium !important;
                }
                &-7 {
                    margin-right: $spacer--large !important;
                }
                &-8 {
                    margin-right: $spacer--l !important;
                }
                &-9 {
                    margin-right: $spacer--semi-large !important;
                }
                &-10 {
                    margin-right: $spacer--xl !important;
                }
                &-11 {
                    margin-right: $spacer--extra-large !important;
                }
                &-12 {
                    margin-right: $spacer--xxl !important;
                }
                &-13 {
                    margin-right: $spacer--extra-extra-large !important;
                }
                &-14 {
                    margin-right: $spacer--larger !important;
                }
                &-xs {
                    margin-right: $spacer--extra-small !important;
                }
                &-s {
                    margin-right: $spacer--small !important;
                }
                &-default {
                    margin-right: $spacer !important;
                }
                &-m {
                    margin-right: $spacer--medium !important;
                }
                &-l {
                    margin-right: $spacer--large !important;
                }
                &-xl {
                    margin-right: $spacer--extra-large !important;
                }
                &-xxl {
                    margin-right: $spacer--extra-extra-large !important;
                }
            }
            &b {
                &-0 {
                    margin-bottom: 0 !important;
                }
                &-1 {
                    margin-bottom: $spacer--extra-small !important;
                }
                &-2 {
                    margin-bottom: $spacer !important;
                }
                &-3 {
                    margin-bottom: $spacer--small !important;
                }
                &-4 {
                    margin-bottom: $spacer--medium !important;
                }
                &-5 {
                    margin-bottom: $spacer--m !important;
                }
                &-6 {
                    margin-bottom: $spacer--semi-medium !important;
                }
                &-7 {
                    margin-bottom: $spacer--large !important;
                }
                &-8 {
                    margin-bottom: $spacer--l !important;
                }
                &-9 {
                    margin-bottom: $spacer--semi-large !important;
                }
                &-10 {
                    margin-bottom: $spacer--xl !important;
                }
                &-11 {
                    margin-bottom: $spacer--extra-large !important;
                }
                &-12 {
                    margin-bottom: $spacer--xxl !important;
                }
                &-13 {
                    margin-bottom: $spacer--extra-extra-large !important;
                }
                &-14 {
                    margin-bottom: $spacer--larger !important;
                }
                &-xs {
                    margin-bottom: $spacer--extra-small !important;
                }
                &-s {
                    margin-bottom: $spacer--small !important;
                }
                &-default {
                    margin-bottom: $spacer !important;
                }
                &-m {
                    margin-bottom: $spacer--medium !important;
                }
                &-l {
                    margin-bottom: $spacer--large !important;
                }
                &-xl {
                    margin-bottom: $spacer--extra-large !important;
                }
                &-xxl {
                    margin-bottom: $spacer--extra-extra-large !important;
                }
            }
            &l {
                &-0 {
                    margin-left: 0 !important;
                }
                &-1 {
                    margin-left: $spacer--extra-small !important;
                }
                &-2 {
                    margin-left: $spacer !important;
                }
                &-3 {
                    margin-left: $spacer--small !important;
                }
                &-4 {
                    margin-left: $spacer--medium !important;
                }
                &-5 {
                    margin-left: $spacer--m !important;
                }
                &-6 {
                    margin-left: $spacer--semi-medium !important;
                }
                &-7 {
                    margin-left: $spacer--large !important;
                }
                &-8 {
                    margin-left: $spacer--l !important;
                }
                &-9 {
                    margin-left: $spacer--semi-large !important;
                }
                &-10 {
                    margin-left: $spacer--xl !important;
                }
                &-11 {
                    margin-left: $spacer--extra-large !important;
                }
                &-12 {
                    margin-left: $spacer--xxl !important;
                }
                &-13 {
                    margin-left: $spacer--extra-extra-large !important;
                }
                &-14 {
                    margin-left: $spacer--larger !important;
                }
                &-xs {
                    margin-left: $spacer--extra-small !important;
                }
                &-s {
                    margin-left: $spacer--small !important;
                }
                &-default {
                    margin-left: $spacer !important;
                }
                &-m {
                    margin-left: $spacer--medium !important;
                }
                &-l {
                    margin-left: $spacer--large !important;
                }
                &-xl {
                    margin-left: $spacer--extra-large !important;
                }
                &-xxl {
                    margin-left: $spacer--extra-extra-large !important;
                }
            }
        }
    }
}
//sass-lint:enable no-important
