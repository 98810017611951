//prevent document from scrolling
//sass-lint:disable no-important
.color,
.fill {
    &-white {
        color: $white !important;
        fill: $white !important;
    }

    &-black {
        color: $black !important;
        fill: $black !important;
    }

    &-red {
        color: $red !important;
        fill: $red !important;
    }

    &-orange {
        color: $orange !important;
        fill: $orange !important;
    }

    &-orange-dark {
        color: $orange-dark !important;
        fill: $orange-dark !important;
    }

    &-yellow {
        color: $yellow !important;
        fill: $yellow !important;
    }

    &-green {
        color: $green !important;
        fill: $green !important;
    }

    &-blue {
        color: $blue !important;
        fill: $blue !important;
    }

    &-bright-sky-blue {
        color: $bright-sky-blue !important;
        fill: $bright-sky-blue !important;
    }

    &-brown {
        color: $brown !important;
        fill: $brown !important;
    }

    &-teal {
        color: $teal !important;
        fill: $teal !important;
    }

    &-pink {
        color: $pink !important;
        fill: $pink !important;
    }

    &-purple {
        color: $purple !important;
        fill: $purple !important;
    }

    &-seafoam-blue {
        color: $seafoam-blue !important;
        fill: $seafoam-blue !important;
    }

    &-gray-darkest {
        color: $gray-darkest !important;
        fill: $gray-darkest !important;
    }

    &-gray-darker {
        color: $gray-darker !important;
        fill: $gray-darker !important;
    }

    &-gray-dark {
        color: $gray-dark !important;
        fill: $gray-dark !important;
    }

    &-gray {
        color: $gray !important;
        fill: $gray !important;
    }

    &-gray-light {
        color: $gray-light !important;
        fill: $gray-light !important;
    }

    &-gray-lighter {
        color: $gray-lighter !important;
        fill: $gray-lighter !important;
    }

    &-gray-lightest {
        color: $gray-lightest !important;
        fill: $gray-lightest !important;
    }

    &-theme-gold {
        color: $theme-gold !important;
        fill: $theme-gold !important;
    }

    &-theme-primary {
        color: $theme-primary !important;
        fill: $theme-primary !important;
    }

    &-theme-second {
        color: $theme-second !important;
        fill: $theme-second !important;
    }

    &-theme-dark {
        color: $theme-dark !important;
        fill: $theme-dark !important;
    }

    &-primary {
        color: $color-primary !important;
        fill: $color-primary !important;
    }

    &-secondary {
        color: $color-secondary !important;
        fill: $color-secondary !important;
    }

    &-tertiary {
        color: $color-tertiary !important;
        fill: $color-tertiary !important;
    }

    &-success {
        color: $color-success !important;
        fill: $color-success !important;
    }

    &-info {
        color: $color-info !important;
        fill: $color-info !important;
    }

    &-warning {
        color: $color-warning !important;
        fill: $color-warning !important;
    }

    &-error {
        color: $color-error !important;
        fill: $color-error !important;
    }

    &-inverse {
        color: $color-inverse !important;
        fill: $color-inverse !important;
    }

    &-focus {
        color: $color-focus !important;
        fill: $color-focus !important;
    }
}
@include max-screen($screen-m - 1) {
    .xs {
        &-color,
        &-fill {
            &-white {
                color: $white !important;
                fill: $white !important;
            }

            &-black {
                color: $black !important;
                fill: $black !important;
            }

            &-red {
                color: $red !important;
                fill: $red !important;
            }

            &-orange {
                color: $orange !important;
                fill: $orange !important;
            }

            &-orange-dark {
                color: $orange-dark !important;
                fill: $orange-dark !important;
            }

            &-yellow {
                color: $yellow !important;
                fill: $yellow !important;
            }

            &-green {
                color: $green !important;
                fill: $green !important;
            }

            &-blue {
                color: $blue !important;
                fill: $blue !important;
            }

            &-bright-sky-blue {
                color: $bright-sky-blue !important;
                fill: $bright-sky-blue !important;
            }

            &-brown {
                color: $brown !important;
                fill: $brown !important;
            }

            &-teal {
                color: $teal !important;
                fill: $teal !important;
            }

            &-pink {
                color: $pink !important;
                fill: $pink !important;
            }

            &-purple {
                color: $purple !important;
                fill: $purple !important;
            }

            &-seafoam-blue {
                color: $seafoam-blue !important;
                fill: $seafoam-blue !important;
            }

            &-gray-darkest {
                color: $gray-darkest !important;
                fill: $gray-darkest !important;
            }

            &-gray-darker {
                color: $gray-darker !important;
                fill: $gray-darker !important;
            }

            &-gray-dark {
                color: $gray-dark !important;
                fill: $gray-dark !important;
            }

            &-gray {
                color: $gray !important;
                fill: $gray !important;
            }

            &-gray-light {
                color: $gray-light !important;
                fill: $gray-light !important;
            }

            &-gray-lighter {
                color: $gray-lighter !important;
                fill: $gray-lighter !important;
            }

            &-gray-lightest {
                color: $gray-lightest !important;
                fill: $gray-lightest !important;
            }

            &-theme-gold {
                color: $theme-gold !important;
                fill: $theme-gold !important;
            }

            &-theme-primary {
                color: $theme-primary !important;
                fill: $theme-primary !important;
            }

            &-theme-second {
                color: $theme-second !important;
                fill: $theme-second !important;
            }

            &-theme-dark {
                color: $theme-dark !important;
                fill: $theme-dark !important;
            }

            &-primary {
                color: $color-primary !important;
                fill: $color-primary !important;
            }

            &-secondary {
                color: $color-secondary !important;
                fill: $color-secondary !important;
            }

            &-tertiary {
                color: $color-tertiary !important;
                fill: $color-tertiary !important;
            }

            &-success {
                color: $color-success !important;
                fill: $color-success !important;
            }

            &-info {
                color: $color-info !important;
                fill: $color-info !important;
            }

            &-warning {
                color: $color-warning !important;
                fill: $color-warning !important;
            }

            &-error {
                color: $color-error !important;
                fill: $color-error !important;
            }

            &-inverse {
                color: $color-inverse !important;
                fill: $color-inverse !important;
            }

            &-focus {
                color: $color-focus !important;
                fill: $color-focus !important;
            }
        }
    }
}
@include min-screen($screen-m) {
    .md {
        &-color,
        &-fill {
            &-white {
                color: $white !important;
                fill: $white !important;
            }

            &-black {
                color: $black !important;
                fill: $black !important;
            }

            &-red {
                color: $red !important;
                fill: $red !important;
            }

            &-orange {
                color: $orange !important;
                fill: $orange !important;
            }

            &-orange-dark {
                color: $orange-dark !important;
                fill: $orange-dark !important;
            }

            &-yellow {
                color: $yellow !important;
                fill: $yellow !important;
            }

            &-green {
                color: $green !important;
                fill: $green !important;
            }

            &-blue {
                color: $blue !important;
                fill: $blue !important;
            }

            &-bright-sky-blue {
                color: $bright-sky-blue !important;
                fill: $bright-sky-blue !important;
            }

            &-brown {
                color: $brown !important;
                fill: $brown !important;
            }

            &-teal {
                color: $teal !important;
                fill: $teal !important;
            }

            &-pink {
                color: $pink !important;
                fill: $pink !important;
            }

            &-purple {
                color: $purple !important;
                fill: $purple !important;
            }

            &-seafoam-blue {
                color: $seafoam-blue !important;
                fill: $seafoam-blue !important;
            }

            &-gray-darkest {
                color: $gray-darkest !important;
                fill: $gray-darkest !important;
            }

            &-gray-darker {
                color: $gray-darker !important;
                fill: $gray-darker !important;
            }

            &-gray-dark {
                color: $gray-dark !important;
                fill: $gray-dark !important;
            }

            &-gray {
                color: $gray !important;
                fill: $gray !important;
            }

            &-gray-light {
                color: $gray-light !important;
                fill: $gray-light !important;
            }

            &-gray-lighter {
                color: $gray-lighter !important;
                fill: $gray-lighter !important;
            }

            &-gray-lightest {
                color: $gray-lightest !important;
                fill: $gray-lightest !important;
            }

            &-theme-gold {
                color: $theme-gold !important;
                fill: $theme-gold !important;
            }

            &-theme-primary {
                color: $theme-primary !important;
                fill: $theme-primary !important;
            }

            &-theme-second {
                color: $theme-second !important;
                fill: $theme-second !important;
            }

            &-theme-dark {
                color: $theme-dark !important;
                fill: $theme-dark !important;
            }

            &-primary {
                color: $color-primary !important;
                fill: $color-primary !important;
            }

            &-secondary {
                color: $color-secondary !important;
                fill: $color-secondary !important;
            }

            &-tertiary {
                color: $color-tertiary !important;
                fill: $color-tertiary !important;
            }

            &-success {
                color: $color-success !important;
                fill: $color-success !important;
            }

            &-info {
                color: $color-info !important;
                fill: $color-info !important;
            }

            &-warning {
                color: $color-warning !important;
                fill: $color-warning !important;
            }

            &-error {
                color: $color-error !important;
                fill: $color-error !important;
            }

            &-inverse {
                color: $color-inverse !important;
                fill: $color-inverse !important;
            }

            &-focus {
                color: $color-focus !important;
                fill: $color-focus !important;
            }
        }
    }
}
@include min-screen($screen-l) {
    .lg {
        &-color,
        &-fill {
            &-white {
                color: $white !important;
                fill: $white !important;
            }

            &-black {
                color: $black !important;
                fill: $black !important;
            }

            &-red {
                color: $red !important;
                fill: $red !important;
            }

            &-orange {
                color: $orange !important;
                fill: $orange !important;
            }

            &-orange-dark {
                color: $orange-dark !important;
                fill: $orange-dark !important;
            }

            &-yellow {
                color: $yellow !important;
                fill: $yellow !important;
            }

            &-green {
                color: $green !important;
                fill: $green !important;
            }

            &-blue {
                color: $blue !important;
                fill: $blue !important;
            }

            &-bright-sky-blue {
                color: $bright-sky-blue !important;
                fill: $bright-sky-blue !important;
            }

            &-brown {
                color: $brown !important;
                fill: $brown !important;
            }

            &-teal {
                color: $teal !important;
                fill: $teal !important;
            }

            &-pink {
                color: $pink !important;
                fill: $pink !important;
            }

            &-purple {
                color: $purple !important;
                fill: $purple !important;
            }

            &-seafoam-blue {
                color: $seafoam-blue !important;
                fill: $seafoam-blue !important;
            }

            &-gray-darkest {
                color: $gray-darkest !important;
                fill: $gray-darkest !important;
            }

            &-gray-darker {
                color: $gray-darker !important;
                fill: $gray-darker !important;
            }

            &-gray-dark {
                color: $gray-dark !important;
                fill: $gray-dark !important;
            }

            &-gray {
                color: $gray !important;
                fill: $gray !important;
            }

            &-gray-light {
                color: $gray-light !important;
                fill: $gray-light !important;
            }

            &-gray-lighter {
                color: $gray-lighter !important;
                fill: $gray-lighter !important;
            }

            &-gray-lightest {
                color: $gray-lightest !important;
                fill: $gray-lightest !important;
            }

            &-theme-gold {
                color: $theme-gold !important;
                fill: $theme-gold !important;
            }

            &-theme-primary {
                color: $theme-primary !important;
                fill: $theme-primary !important;
            }

            &-theme-second {
                color: $theme-second !important;
                fill: $theme-second !important;
            }

            &-theme-dark {
                color: $theme-dark !important;
                fill: $theme-dark !important;
            }

            &-primary {
                color: $color-primary !important;
                fill: $color-primary !important;
            }

            &-secondary {
                color: $color-secondary !important;
                fill: $color-secondary !important;
            }

            &-tertiary {
                color: $color-tertiary !important;
                fill: $color-tertiary !important;
            }

            &-success {
                color: $color-success !important;
                fill: $color-success !important;
            }

            &-info {
                color: $color-info !important;
                fill: $color-info !important;
            }

            &-warning {
                color: $color-warning !important;
                fill: $color-warning !important;
            }

            &-error {
                color: $color-error !important;
                fill: $color-error !important;
            }

            &-inverse {
                color: $color-inverse !important;
                fill: $color-inverse !important;
            }

            &-focus {
                color: $color-focus !important;
                fill: $color-focus !important;
            }
        }
    }
}
@include min-screen($screen-xl) {
    .xl {
        &-color,
        &-fill {
            &-white {
                color: $white !important;
                fill: $white !important;
            }

            &-black {
                color: $black !important;
                fill: $black !important;
            }

            &-red {
                color: $red !important;
                fill: $red !important;
            }

            &-orange {
                color: $orange !important;
                fill: $orange !important;
            }

            &-orange-dark {
                color: $orange-dark !important;
                fill: $orange-dark !important;
            }

            &-yellow {
                color: $yellow !important;
                fill: $yellow !important;
            }

            &-green {
                color: $green !important;
                fill: $green !important;
            }

            &-blue {
                color: $blue !important;
                fill: $blue !important;
            }

            &-bright-sky-blue {
                color: $bright-sky-blue !important;
                fill: $bright-sky-blue !important;
            }

            &-brown {
                color: $brown !important;
                fill: $brown !important;
            }

            &-teal {
                color: $teal !important;
                fill: $teal !important;
            }

            &-pink {
                color: $pink !important;
                fill: $pink !important;
            }

            &-purple {
                color: $purple !important;
                fill: $purple !important;
            }

            &-seafoam-blue {
                color: $seafoam-blue !important;
                fill: $seafoam-blue !important;
            }

            &-gray-darkest {
                color: $gray-darkest !important;
                fill: $gray-darkest !important;
            }

            &-gray-darker {
                color: $gray-darker !important;
                fill: $gray-darker !important;
            }

            &-gray-dark {
                color: $gray-dark !important;
                fill: $gray-dark !important;
            }

            &-gray {
                color: $gray !important;
                fill: $gray !important;
            }

            &-gray-light {
                color: $gray-light !important;
                fill: $gray-light !important;
            }

            &-gray-lighter {
                color: $gray-lighter !important;
                fill: $gray-lighter !important;
            }

            &-gray-lightest {
                color: $gray-lightest !important;
                fill: $gray-lightest !important;
            }

            &-theme-gold {
                color: $theme-gold !important;
                fill: $theme-gold !important;
            }

            &-theme-primary {
                color: $theme-primary !important;
                fill: $theme-primary !important;
            }

            &-theme-second {
                color: $theme-second !important;
                fill: $theme-second !important;
            }

            &-theme-dark {
                color: $theme-dark !important;
                fill: $theme-dark !important;
            }

            &-primary {
                color: $color-primary !important;
                fill: $color-primary !important;
            }

            &-secondary {
                color: $color-secondary !important;
                fill: $color-secondary !important;
            }

            &-tertiary {
                color: $color-tertiary !important;
                fill: $color-tertiary !important;
            }

            &-success {
                color: $color-success !important;
                fill: $color-success !important;
            }

            &-info {
                color: $color-info !important;
                fill: $color-info !important;
            }

            &-warning {
                color: $color-warning !important;
                fill: $color-warning !important;
            }

            &-error {
                color: $color-error !important;
                fill: $color-error !important;
            }

            &-inverse {
                color: $color-inverse !important;
                fill: $color-inverse !important;
            }

            &-focus {
                color: $color-focus !important;
                fill: $color-focus !important;
            }
        }
    }
}
@include min-screen($screen-2xl) {
    .x2l {
        &-color,
        &-fill {
            &-white {
                color: $white !important;
                fill: $white !important;
            }

            &-black {
                color: $black !important;
                fill: $black !important;
            }

            &-red {
                color: $red !important;
                fill: $red !important;
            }

            &-orange {
                color: $orange !important;
                fill: $orange !important;
            }

            &-orange-dark {
                color: $orange-dark !important;
                fill: $orange-dark !important;
            }

            &-yellow {
                color: $yellow !important;
                fill: $yellow !important;
            }

            &-green {
                color: $green !important;
                fill: $green !important;
            }

            &-blue {
                color: $blue !important;
                fill: $blue !important;
            }

            &-bright-sky-blue {
                color: $bright-sky-blue !important;
                fill: $bright-sky-blue !important;
            }

            &-brown {
                color: $brown !important;
                fill: $brown !important;
            }

            &-teal {
                color: $teal !important;
                fill: $teal !important;
            }

            &-pink {
                color: $pink !important;
                fill: $pink !important;
            }

            &-purple {
                color: $purple !important;
                fill: $purple !important;
            }

            &-seafoam-blue {
                color: $seafoam-blue !important;
                fill: $seafoam-blue !important;
            }

            &-gray-darkest {
                color: $gray-darkest !important;
                fill: $gray-darkest !important;
            }

            &-gray-darker {
                color: $gray-darker !important;
                fill: $gray-darker !important;
            }

            &-gray-dark {
                color: $gray-dark !important;
                fill: $gray-dark !important;
            }

            &-gray {
                color: $gray !important;
                fill: $gray !important;
            }

            &-gray-light {
                color: $gray-light !important;
                fill: $gray-light !important;
            }

            &-gray-lighter {
                color: $gray-lighter !important;
                fill: $gray-lighter !important;
            }

            &-gray-lightest {
                color: $gray-lightest !important;
                fill: $gray-lightest !important;
            }

            &-theme-gold {
                color: $theme-gold !important;
                fill: $theme-gold !important;
            }

            &-theme-primary {
                color: $theme-primary !important;
                fill: $theme-primary !important;
            }

            &-theme-second {
                color: $theme-second !important;
                fill: $theme-second !important;
            }

            &-theme-dark {
                color: $theme-dark !important;
                fill: $theme-dark !important;
            }

            &-primary {
                color: $color-primary !important;
                fill: $color-primary !important;
            }

            &-secondary {
                color: $color-secondary !important;
                fill: $color-secondary !important;
            }

            &-tertiary {
                color: $color-tertiary !important;
                fill: $color-tertiary !important;
            }

            &-success {
                color: $color-success !important;
                fill: $color-success !important;
            }

            &-info {
                color: $color-info !important;
                fill: $color-info !important;
            }

            &-warning {
                color: $color-warning !important;
                fill: $color-warning !important;
            }

            &-error {
                color: $color-error !important;
                fill: $color-error !important;
            }

            &-inverse {
                color: $color-inverse !important;
                fill: $color-inverse !important;
            }

            &-focus {
                color: $color-focus !important;
                fill: $color-focus !important;
            }
        }
    }
}
@include min-screen($screen-xxl) {
    .xl {
        &-color,
        &-fill {
            &-white {
                color: $white !important;
                fill: $white !important;
            }

            &-black {
                color: $black !important;
                fill: $black !important;
            }

            &-red {
                color: $red !important;
                fill: $red !important;
            }

            &-orange {
                color: $orange !important;
                fill: $orange !important;
            }

            &-orange-dark {
                color: $orange-dark !important;
                fill: $orange-dark !important;
            }

            &-yellow {
                color: $yellow !important;
                fill: $yellow !important;
            }

            &-green {
                color: $green !important;
                fill: $green !important;
            }

            &-blue {
                color: $blue !important;
                fill: $blue !important;
            }

            &-bright-sky-blue {
                color: $bright-sky-blue !important;
                fill: $bright-sky-blue !important;
            }

            &-brown {
                color: $brown !important;
                fill: $brown !important;
            }

            &-teal {
                color: $teal !important;
                fill: $teal !important;
            }

            &-pink {
                color: $pink !important;
                fill: $pink !important;
            }

            &-purple {
                color: $purple !important;
                fill: $purple !important;
            }

            &-seafoam-blue {
                color: $seafoam-blue !important;
                fill: $seafoam-blue !important;
            }

            &-gray-darkest {
                color: $gray-darkest !important;
                fill: $gray-darkest !important;
            }

            &-gray-darker {
                color: $gray-darker !important;
                fill: $gray-darker !important;
            }

            &-gray-dark {
                color: $gray-dark !important;
                fill: $gray-dark !important;
            }

            &-gray {
                color: $gray !important;
                fill: $gray !important;
            }

            &-gray-light {
                color: $gray-light !important;
                fill: $gray-light !important;
            }

            &-gray-lighter {
                color: $gray-lighter !important;
                fill: $gray-lighter !important;
            }

            &-gray-lightest {
                color: $gray-lightest !important;
                fill: $gray-lightest !important;
            }

            &-theme-gold {
                color: $theme-gold !important;
                fill: $theme-gold !important;
            }

            &-theme-primary {
                color: $theme-primary !important;
                fill: $theme-primary !important;
            }

            &-theme-second {
                color: $theme-second !important;
                fill: $theme-second !important;
            }

            &-theme-dark {
                color: $theme-dark !important;
                fill: $theme-dark !important;
            }

            &-primary {
                color: $color-primary !important;
                fill: $color-primary !important;
            }

            &-secondary {
                color: $color-secondary !important;
                fill: $color-secondary !important;
            }

            &-tertiary {
                color: $color-tertiary !important;
                fill: $color-tertiary !important;
            }

            &-success {
                color: $color-success !important;
                fill: $color-success !important;
            }

            &-info {
                color: $color-info !important;
                fill: $color-info !important;
            }

            &-warning {
                color: $color-warning !important;
                fill: $color-warning !important;
            }

            &-error {
                color: $color-error !important;
                fill: $color-error !important;
            }

            &-inverse {
                color: $color-inverse !important;
                fill: $color-inverse !important;
            }

            &-focus {
                color: $color-focus !important;
                fill: $color-focus !important;
            }
        }
    }
}
@include min-screen($screen-3xl) {
    .x3l {
        &-color,
        &-fill {
            &-white {
                color: $white !important;
                fill: $white !important;
            }

            &-black {
                color: $black !important;
                fill: $black !important;
            }

            &-red {
                color: $red !important;
                fill: $red !important;
            }

            &-orange {
                color: $orange !important;
                fill: $orange !important;
            }

            &-orange-dark {
                color: $orange-dark !important;
                fill: $orange-dark !important;
            }

            &-yellow {
                color: $yellow !important;
                fill: $yellow !important;
            }

            &-green {
                color: $green !important;
                fill: $green !important;
            }

            &-blue {
                color: $blue !important;
                fill: $blue !important;
            }

            &-bright-sky-blue {
                color: $bright-sky-blue !important;
                fill: $bright-sky-blue !important;
            }

            &-brown {
                color: $brown !important;
                fill: $brown !important;
            }

            &-teal {
                color: $teal !important;
                fill: $teal !important;
            }

            &-pink {
                color: $pink !important;
                fill: $pink !important;
            }

            &-purple {
                color: $purple !important;
                fill: $purple !important;
            }

            &-seafoam-blue {
                color: $seafoam-blue !important;
                fill: $seafoam-blue !important;
            }

            &-gray-darkest {
                color: $gray-darkest !important;
                fill: $gray-darkest !important;
            }

            &-gray-darker {
                color: $gray-darker !important;
                fill: $gray-darker !important;
            }

            &-gray-dark {
                color: $gray-dark !important;
                fill: $gray-dark !important;
            }

            &-gray {
                color: $gray !important;
                fill: $gray !important;
            }

            &-gray-light {
                color: $gray-light !important;
                fill: $gray-light !important;
            }

            &-gray-lighter {
                color: $gray-lighter !important;
                fill: $gray-lighter !important;
            }

            &-gray-lightest {
                color: $gray-lightest !important;
                fill: $gray-lightest !important;
            }

            &-theme-gold {
                color: $theme-gold !important;
                fill: $theme-gold !important;
            }

            &-theme-primary {
                color: $theme-primary !important;
                fill: $theme-primary !important;
            }

            &-theme-second {
                color: $theme-second !important;
                fill: $theme-second !important;
            }

            &-theme-dark {
                color: $theme-dark !important;
                fill: $theme-dark !important;
            }

            &-primary {
                color: $color-primary !important;
                fill: $color-primary !important;
            }

            &-secondary {
                color: $color-secondary !important;
                fill: $color-secondary !important;
            }

            &-tertiary {
                color: $color-tertiary !important;
                fill: $color-tertiary !important;
            }

            &-success {
                color: $color-success !important;
                fill: $color-success !important;
            }

            &-info {
                color: $color-info !important;
                fill: $color-info !important;
            }

            &-warning {
                color: $color-warning !important;
                fill: $color-warning !important;
            }

            &-error {
                color: $color-error !important;
                fill: $color-error !important;
            }

            &-inverse {
                color: $color-inverse !important;
                fill: $color-inverse !important;
            }

            &-focus {
                color: $color-focus !important;
                fill: $color-focus !important;
            }
        }
    }
}
@include min-screen($screen-xxxl) {
    .xxxl {
        &-color,
        &-fill {
            &-white {
                color: $white !important;
                fill: $white !important;
            }

            &-black {
                color: $black !important;
                fill: $black !important;
            }

            &-red {
                color: $red !important;
                fill: $red !important;
            }

            &-orange {
                color: $orange !important;
                fill: $orange !important;
            }

            &-orange-dark {
                color: $orange-dark !important;
                fill: $orange-dark !important;
            }

            &-yellow {
                color: $yellow !important;
                fill: $yellow !important;
            }

            &-green {
                color: $green !important;
                fill: $green !important;
            }

            &-blue {
                color: $blue !important;
                fill: $blue !important;
            }

            &-bright-sky-blue {
                color: $bright-sky-blue !important;
                fill: $bright-sky-blue !important;
            }

            &-brown {
                color: $brown !important;
                fill: $brown !important;
            }

            &-teal {
                color: $teal !important;
                fill: $teal !important;
            }

            &-pink {
                color: $pink !important;
                fill: $pink !important;
            }

            &-purple {
                color: $purple !important;
                fill: $purple !important;
            }

            &-seafoam-blue {
                color: $seafoam-blue !important;
                fill: $seafoam-blue !important;
            }

            &-gray-darkest {
                color: $gray-darkest !important;
                fill: $gray-darkest !important;
            }

            &-gray-darker {
                color: $gray-darker !important;
                fill: $gray-darker !important;
            }

            &-gray-dark {
                color: $gray-dark !important;
                fill: $gray-dark !important;
            }

            &-gray {
                color: $gray !important;
                fill: $gray !important;
            }

            &-gray-light {
                color: $gray-light !important;
                fill: $gray-light !important;
            }

            &-gray-lighter {
                color: $gray-lighter !important;
                fill: $gray-lighter !important;
            }

            &-gray-lightest {
                color: $gray-lightest !important;
                fill: $gray-lightest !important;
            }

            &-theme-gold {
                color: $theme-gold !important;
                fill: $theme-gold !important;
            }

            &-theme-primary {
                color: $theme-primary !important;
                fill: $theme-primary !important;
            }

            &-theme-second {
                color: $theme-second !important;
                fill: $theme-second !important;
            }

            &-theme-dark {
                color: $theme-dark !important;
                fill: $theme-dark !important;
            }

            &-primary {
                color: $color-primary !important;
                fill: $color-primary !important;
            }

            &-secondary {
                color: $color-secondary !important;
                fill: $color-secondary !important;
            }

            &-tertiary {
                color: $color-tertiary !important;
                fill: $color-tertiary !important;
            }

            &-success {
                color: $color-success !important;
                fill: $color-success !important;
            }

            &-info {
                color: $color-info !important;
                fill: $color-info !important;
            }

            &-warning {
                color: $color-warning !important;
                fill: $color-warning !important;
            }

            &-error {
                color: $color-error !important;
                fill: $color-error !important;
            }

            &-inverse {
                color: $color-inverse !important;
                fill: $color-inverse !important;
            }

            &-focus {
                color: $color-focus !important;
                fill: $color-focus !important;
            }
        }
    }
}
@include min-screen($screen-4xl) {
    .x4l {
        &-color,
        &-fill {
            &-white {
                color: $white !important;
                fill: $white !important;
            }

            &-black {
                color: $black !important;
                fill: $black !important;
            }

            &-red {
                color: $red !important;
                fill: $red !important;
            }

            &-orange {
                color: $orange !important;
                fill: $orange !important;
            }

            &-orange-dark {
                color: $orange-dark !important;
                fill: $orange-dark !important;
            }

            &-yellow {
                color: $yellow !important;
                fill: $yellow !important;
            }

            &-green {
                color: $green !important;
                fill: $green !important;
            }

            &-blue {
                color: $blue !important;
                fill: $blue !important;
            }

            &-bright-sky-blue {
                color: $bright-sky-blue !important;
                fill: $bright-sky-blue !important;
            }

            &-brown {
                color: $brown !important;
                fill: $brown !important;
            }

            &-teal {
                color: $teal !important;
                fill: $teal !important;
            }

            &-pink {
                color: $pink !important;
                fill: $pink !important;
            }

            &-purple {
                color: $purple !important;
                fill: $purple !important;
            }

            &-seafoam-blue {
                color: $seafoam-blue !important;
                fill: $seafoam-blue !important;
            }

            &-gray-darkest {
                color: $gray-darkest !important;
                fill: $gray-darkest !important;
            }

            &-gray-darker {
                color: $gray-darker !important;
                fill: $gray-darker !important;
            }

            &-gray-dark {
                color: $gray-dark !important;
                fill: $gray-dark !important;
            }

            &-gray {
                color: $gray !important;
                fill: $gray !important;
            }

            &-gray-light {
                color: $gray-light !important;
                fill: $gray-light !important;
            }

            &-gray-lighter {
                color: $gray-lighter !important;
                fill: $gray-lighter !important;
            }

            &-gray-lightest {
                color: $gray-lightest !important;
                fill: $gray-lightest !important;
            }

            &-theme-gold {
                color: $theme-gold !important;
                fill: $theme-gold !important;
            }

            &-theme-primary {
                color: $theme-primary !important;
                fill: $theme-primary !important;
            }

            &-theme-second {
                color: $theme-second !important;
                fill: $theme-second !important;
            }

            &-theme-dark {
                color: $theme-dark !important;
                fill: $theme-dark !important;
            }

            &-primary {
                color: $color-primary !important;
                fill: $color-primary !important;
            }

            &-secondary {
                color: $color-secondary !important;
                fill: $color-secondary !important;
            }

            &-tertiary {
                color: $color-tertiary !important;
                fill: $color-tertiary !important;
            }

            &-success {
                color: $color-success !important;
                fill: $color-success !important;
            }

            &-info {
                color: $color-info !important;
                fill: $color-info !important;
            }

            &-warning {
                color: $color-warning !important;
                fill: $color-warning !important;
            }

            &-error {
                color: $color-error !important;
                fill: $color-error !important;
            }

            &-inverse {
                color: $color-inverse !important;
                fill: $color-inverse !important;
            }

            &-focus {
                color: $color-focus !important;
                fill: $color-focus !important;
            }
        }
    }
}
//sass-lint:enable no-important
