// Alpaca grid based on flexboxgrid2 (https://github.com/evgenyrodionov/flexboxgrid2)

$grid__gutter-width       : $spacer--medium !default;
$grid__gutter-half-width  : calc(#{$grid__gutter-width} * 0.5) !default;
$grid__gutter-compensation: calc(#{$grid__gutter-half-width} * -1) !default;

.row {
    box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: $grid__gutter-compensation;
    margin-left: $grid__gutter-compensation;

    &--no-margins {
        margin-left: 0;
        margin-right: 0;
    }
}

.row.reverse {
    flex-direction: row-reverse;
}

.col.reverse {
    flex-direction: column-reverse;
}

.col-xs,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-sm,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-md,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-lg,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-xl,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12 {
    box-sizing: border-box;
    flex: 0 0 auto;
    flex-basis: 100%;
    padding-right: $grid__gutter-half-width;
    padding-left: $grid__gutter-half-width;
    max-width: 100%;
}

.col-xs {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
}

.col-xs-1 {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
}

.col-xs-2 {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
}

.col-xs-3 {
    flex-basis: 25%;
    max-width: 25%;
}

.col-xs-4 {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
}

.col-xs-5 {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
}

.col-xs-6 {
    flex-basis: 50%;
    max-width: 50%;
}

.col-xs-7 {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
}

.col-xs-8 {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
}

.col-xs-9 {
    flex-basis: 75%;
    max-width: 75%;
}

.col-xs-10 {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
}

.col-xs-11 {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
}

.col-xs-12 {
    flex-basis: 100%;
    max-width: 100%;
}

.start-xs {
    justify-content: flex-start;
    text-align: start;
}

.center-xs {
    justify-content: center;
    text-align: center;
}

.end-xs {
    justify-content: flex-end;
    text-align: end;
}

.top-xs {
    align-items: flex-start;
}

.middle-xs {
    align-items: center;
}

.bottom-xs {
    align-items: flex-end;
}

.around-xs {
    justify-content: space-around;
}

.between-xs {
    justify-content: space-between;
}

.evenly-xs {
    justify-content: space-evenly;
}

.first-xs {
    order: -1;
}

.last-xs {
    order: 1;
}

.initial-order-xs {
    order: initial;
}

@include min-screen($screen-s) {
    .col-sm {
        flex-grow: 1;
        flex-basis: 0;
        max-width: 100%;
    }

    .col-sm-1 {
        flex-basis: 8.33333333%;
        max-width: 8.33333333%;
    }

    .col-sm-2 {
        flex-basis: 16.66666667%;
        max-width: 16.66666667%;
    }

    .col-sm-3 {
        flex-basis: 25%;
        max-width: 25%;
    }

    .col-sm-4 {
        flex-basis: 33.33333333%;
        max-width: 33.33333333%;
    }

    .col-sm-5 {
        flex-basis: 41.66666667%;
        max-width: 41.66666667%;
    }

    .col-sm-6 {
        flex-basis: 50%;
        max-width: 50%;
    }

    .col-sm-7 {
        flex-basis: 58.33333333%;
        max-width: 58.33333333%;
    }

    .col-sm-8 {
        flex-basis: 66.66666667%;
        max-width: 66.66666667%;
    }

    .col-sm-9 {
        flex-basis: 75%;
        max-width: 75%;
    }

    .col-sm-10 {
        flex-basis: 83.33333333%;
        max-width: 83.33333333%;
    }

    .col-sm-11 {
        flex-basis: 91.66666667%;
        max-width: 91.66666667%;
    }

    .col-sm-12 {
        flex-basis: 100%;
        max-width: 100%;
    }

    .start-sm {
        justify-content: flex-start;
        text-align: start;
    }

    .center-sm {
        justify-content: center;
        text-align: center;
    }

    .end-sm {
        justify-content: flex-end;
        text-align: end;
    }

    .top-sm {
        align-items: flex-start;
    }

    .middle-sm {
        align-items: center;
    }

    .bottom-sm {
        align-items: flex-end;
    }

    .around-sm {
        justify-content: space-around;
    }

    .between-sm {
        justify-content: space-between;
    }

    .evenly-sm {
        justify-content: space-evenly;
    }

    .first-sm {
        order: -1;
    }

    .last-sm {
        order: 1;
    }

    .initial-order-sm {
        order: initial;
    }
}

@include min-screen($screen-m) {
    .col-md,
    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12 {
        box-sizing: border-box;
        flex: 0 0 auto;
        padding-right: $grid__gutter-half-width;
        padding-left: $grid__gutter-half-width;
    }

    .col-md {
        flex-grow: 1;
        flex-basis: 0;
        max-width: 100%;
    }

    .col-md-1 {
        flex-basis: 8.33333333%;
        max-width: 8.33333333%;
    }

    .col-md-2 {
        flex-basis: 16.66666667%;
        max-width: 16.66666667%;
    }

    .col-md-3 {
        flex-basis: 25%;
        max-width: 25%;
    }

    .col-md-4 {
        flex-basis: 33.33333333%;
        max-width: 33.33333333%;
    }

    .col-md-5 {
        flex-basis: 41.66666667%;
        max-width: 41.66666667%;
    }

    .col-md-6 {
        flex-basis: 50%;
        max-width: 50%;
    }

    .col-md-7 {
        flex-basis: 58.33333333%;
        max-width: 58.33333333%;
    }

    .col-md-8 {
        flex-basis: 66.66666667%;
        max-width: 66.66666667%;
    }

    .col-md-9 {
        flex-basis: 75%;
        max-width: 75%;
    }

    .col-md-10 {
        flex-basis: 83.33333333%;
        max-width: 83.33333333%;
    }

    .col-md-11 {
        flex-basis: 91.66666667%;
        max-width: 91.66666667%;
    }

    .col-md-12 {
        flex-basis: 100%;
        max-width: 100%;
    }

    .start-md {
        justify-content: flex-start;
        text-align: start;
    }

    .center-md {
        justify-content: center;
        text-align: center;
    }

    .end-md {
        justify-content: flex-end;
        text-align: end;
    }

    .top-md {
        align-items: flex-start;
    }

    .middle-md {
        align-items: center;
    }

    .bottom-md {
        align-items: flex-end;
    }

    .around-md {
        justify-content: space-around;
    }

    .between-md {
        justify-content: space-between;
    }

    .evenly-md {
        justify-content: space-evenly;
    }

    .first-md {
        order: -1;
    }

    .last-md {
        order: 1;
    }

    .initial-order-md {
        order: initial;
    }
}

@include min-screen($screen-l) {
    .col-lg,
    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12 {
        box-sizing: border-box;
        flex: 0 0 auto;
        padding-right: $grid__gutter-half-width;
        padding-left: $grid__gutter-half-width;
    }

    .col-lg {
        flex-grow: 1;
        flex-basis: 0;
        max-width: 100%;
    }

    .col-lg-1 {
        flex-basis: 8.33333333%;
        max-width: 8.33333333%;
    }

    .col-lg-2 {
        flex-basis: 16.66666667%;
        max-width: 16.66666667%;
    }

    .col-lg-3 {
        flex-basis: 25%;
        max-width: 25%;
    }

    .col-lg-4 {
        flex-basis: 33.33333333%;
        max-width: 33.33333333%;
    }

    .col-lg-5 {
        flex-basis: 41.66666667%;
        max-width: 41.66666667%;
    }

    .col-lg-6 {
        flex-basis: 50%;
        max-width: 50%;
    }

    .col-lg-7 {
        flex-basis: 58.33333333%;
        max-width: 58.33333333%;
    }

    .col-lg-8 {
        flex-basis: 66.66666667%;
        max-width: 66.66666667%;
    }

    .col-lg-9 {
        flex-basis: 75%;
        max-width: 75%;
    }

    .col-lg-10 {
        flex-basis: 83.33333333%;
        max-width: 83.33333333%;
    }

    .col-lg-11 {
        flex-basis: 91.66666667%;
        max-width: 91.66666667%;
    }

    .col-lg-12 {
        flex-basis: 100%;
        max-width: 100%;
    }

    .start-lg {
        justify-content: flex-start;
        text-align: start;
    }

    .center-lg {
        justify-content: center;
        text-align: center;
    }

    .end-lg {
        justify-content: flex-end;
        text-align: end;
    }

    .top-lg {
        align-items: flex-start;
    }

    .middle-lg {
        align-items: center;
    }

    .bottom-lg {
        align-items: flex-end;
    }

    .around-lg {
        justify-content: space-around;
    }

    .between-lg {
        justify-content: space-between;
    }

    .evenly-lg {
        justify-content: space-evenly;
    }

    .first-lg {
        order: -1;
    }

    .last-lg {
        order: 1;
    }

    .initial-order-lg {
        order: initial;
    }
}

@include min-screen($screen-xl) {
    .col-xl,
    .col-xl-1,
    .col-xl-2,
    .col-xl-3,
    .col-xl-4,
    .col-xl-5,
    .col-xl-6,
    .col-xl-7,
    .col-xl-8,
    .col-xl-9,
    .col-xl-10,
    .col-xl-11,
    .col-xl-12 {
        box-sizing: border-box;
        flex: 0 0 auto;
        padding-right: $grid__gutter-half-width;
        padding-left: $grid__gutter-half-width;
    }

    .col-xl {
        flex-grow: 1;
        flex-basis: 0;
        max-width: 100%;
    }

    .col-xl-1 {
        flex-basis: 8.33333333%;
        max-width: 8.33333333%;
    }

    .col-xl-2 {
        flex-basis: 16.66666667%;
        max-width: 16.66666667%;
    }

    .col-xl-3 {
        flex-basis: 25%;
        max-width: 25%;
    }

    .col-xl-4 {
        flex-basis: 33.33333333%;
        max-width: 33.33333333%;
    }

    .col-xl-5 {
        flex-basis: 41.66666667%;
        max-width: 41.66666667%;
    }

    .col-xl-6 {
        flex-basis: 50%;
        max-width: 50%;
    }

    .col-xl-7 {
        flex-basis: 58.33333333%;
        max-width: 58.33333333%;
    }

    .col-xl-8 {
        flex-basis: 66.66666667%;
        max-width: 66.66666667%;
    }

    .col-xl-9 {
        flex-basis: 75%;
        max-width: 75%;
    }

    .col-xl-10 {
        flex-basis: 83.33333333%;
        max-width: 83.33333333%;
    }

    .col-xl-11 {
        flex-basis: 91.66666667%;
        max-width: 91.66666667%;
    }

    .col-xl-12 {
        flex-basis: 100%;
        max-width: 100%;
    }

    .start-xl {
        justify-content: flex-start;
        text-align: start;
    }

    .center-xl {
        justify-content: center;
        text-align: center;
    }

    .end-xl {
        justify-content: flex-end;
        text-align: end;
    }

    .top-xl {
        align-items: flex-start;
    }

    .middle-xl {
        align-items: center;
    }

    .bottom-xl {
        align-items: flex-end;
    }

    .around-xl {
        justify-content: space-around;
    }

    .between-xl {
        justify-content: space-between;
    }

    .evenly-xl {
        justify-content: space-evenly;
    }

    .first-xl {
        order: -1;
    }

    .last-xl {
        order: 1;
    }

    .initial-order-xl {
        order: initial;
    }
}

