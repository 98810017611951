//sass-lint: disable no-important
.column-gap {
    &-1 {
        column-gap: 1rem !important;
    }
    &-15 {
        column-gap: 1.5rem !important;
    }

    &-2 {
        column-gap: 2rem !important;
    }

    &-25 {
        column-gap: 2.5rem !important;
    }

    &-3 {
        column-gap: 3rem !important;
    }

    &-35 {
        column-gap: 3.5rem !important;
    }

    &-4 {
        column-gap: 4rem !important;
    }

    &-45 {
        column-gap: 4.5rem !important;
    }

    &-5 {
        column-gap: 5rem !important;
    }

    &-6 {
        column-gap: 6rem !important;
    }

    &-7 {
        column-gap: 7rem !important;
    }

    &-8 {
        column-gap: 8rem !important;
    }
}
.row-gap {
    &-05 {
        row-gap: 0.5rem !important; //sass-lint:disable-line no-misspelled-properties
    }

    &-1 {
        row-gap: 1rem !important; //sass-lint:disable-line no-misspelled-properties
    }

    &-15 {
        row-gap: 1.5rem !important; //sass-lint:disable-line no-misspelled-properties
    }

    &-2 {
        row-gap: 2rem !important; //sass-lint:disable-line no-misspelled-properties
    }

    &-25 {
        row-gap: 2.5rem !important; //sass-lint:disable-line no-misspelled-properties
    }

    &-3 {
        row-gap: 3rem !important; //sass-lint:disable-line no-misspelled-properties
    }

    &-35 {
        row-gap: 3.5rem !important; //sass-lint:disable-line no-misspelled-properties
    }

    &-4 {
        row-gap: 4rem !important; //sass-lint:disable-line no-misspelled-properties
    }

    &-45 {
        row-gap: 4.5rem !important; //sass-lint:disable-line no-misspelled-properties
    }

    &-5 {
        row-gap: 5rem !important; //sass-lint:disable-line no-misspelled-properties
    }

    &-6 {
        row-gap: 6rem !important; //sass-lint:disable-line no-misspelled-properties
    }

    &-7 {
        row-gap: 7rem !important; //sass-lint:disable-line no-misspelled-properties
    }

    &-8 {
        row-gap: 8rem !important; //sass-lint:disable-line no-misspelled-properties
    }
}
@include max-screen($screen-m - 1) {
    .xs {
        &-column-gap {
            &-1 {
                column-gap: 1rem !important;
            }
            &-15 {
                column-gap: 1.5rem !important;
            }

            &-2 {
                column-gap: 2rem !important;
            }

            &-25 {
                column-gap: 2.5rem !important;
            }

            &-3 {
                column-gap: 3rem !important;
            }

            &-35 {
                column-gap: 3.5rem !important;
            }

            &-4 {
                column-gap: 4rem !important;
            }

            &-45 {
                column-gap: 4.5rem !important;
            }

            &-5 {
                column-gap: 5rem !important;
            }

            &-6 {
                column-gap: 6rem !important;
            }

            &-7 {
                column-gap: 7rem !important;
            }

            &-8 {
                column-gap: 8rem !important;
            }
        }
        &-row-gap {
            &-05 {
                row-gap: 0.5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-1 {
                row-gap: 1rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-15 {
                row-gap: 1.5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-2 {
                row-gap: 2rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-25 {
                row-gap: 2.5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-3 {
                row-gap: 3rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-35 {
                row-gap: 3.5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-4 {
                row-gap: 4rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-45 {
                row-gap: 4.5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-5 {
                row-gap: 5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-6 {
                row-gap: 6rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-7 {
                row-gap: 7rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-8 {
                row-gap: 8rem !important; //sass-lint:disable-line no-misspelled-properties
            }
        }
    }
}
@include min-screen($screen-m) {
    .md {
        &-column-gap {
            &-1 {
                column-gap: 1rem !important;
            }
            &-15 {
                column-gap: 1.5rem !important;
            }

            &-2 {
                column-gap: 2rem !important;
            }

            &-25 {
                column-gap: 2.5rem !important;
            }

            &-3 {
                column-gap: 3rem !important;
            }

            &-35 {
                column-gap: 3.5rem !important;
            }

            &-4 {
                column-gap: 4rem !important;
            }

            &-45 {
                column-gap: 4.5rem !important;
            }

            &-5 {
                column-gap: 5rem !important;
            }

            &-6 {
                column-gap: 6rem !important;
            }

            &-7 {
                column-gap: 7rem !important;
            }

            &-8 {
                column-gap: 8rem !important;
            }
        }
        &-row-gap {
            &-05 {
                row-gap: 0.5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-1 {
                row-gap: 1rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-15 {
                row-gap: 1.5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-2 {
                row-gap: 2rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-25 {
                row-gap: 2.5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-3 {
                row-gap: 3rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-35 {
                row-gap: 3.5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-4 {
                row-gap: 4rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-45 {
                row-gap: 4.5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-5 {
                row-gap: 5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-6 {
                row-gap: 6rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-7 {
                row-gap: 7rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-8 {
                row-gap: 8rem !important; //sass-lint:disable-line no-misspelled-properties
            }
        }
    }
}
@include min-screen($screen-l) {
    .lg {
        &-column-gap {
            &-1 {
                column-gap: 1rem !important;
            }
            &-15 {
                column-gap: 1.5rem !important;
            }

            &-2 {
                column-gap: 2rem !important;
            }

            &-25 {
                column-gap: 2.5rem !important;
            }

            &-3 {
                column-gap: 3rem !important;
            }

            &-35 {
                column-gap: 3.5rem !important;
            }

            &-4 {
                column-gap: 4rem !important;
            }

            &-45 {
                column-gap: 4.5rem !important;
            }

            &-5 {
                column-gap: 5rem !important;
            }

            &-6 {
                column-gap: 6rem !important;
            }

            &-7 {
                column-gap: 7rem !important;
            }

            &-8 {
                column-gap: 8rem !important;
            }
        }
        &-row-gap {
            &-05 {
                row-gap: 0.5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-1 {
                row-gap: 1rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-15 {
                row-gap: 1.5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-2 {
                row-gap: 2rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-25 {
                row-gap: 2.5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-3 {
                row-gap: 3rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-35 {
                row-gap: 3.5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-4 {
                row-gap: 4rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-45 {
                row-gap: 4.5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-5 {
                row-gap: 5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-6 {
                row-gap: 6rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-7 {
                row-gap: 7rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-8 {
                row-gap: 8rem !important; //sass-lint:disable-line no-misspelled-properties
            }
        }
    }
}
@include min-screen($screen-xl) {
    .xl {
        &-column-gap {
            &-1 {
                column-gap: 1rem !important;
            }
            &-15 {
                column-gap: 1.5rem !important;
            }

            &-2 {
                column-gap: 2rem !important;
            }

            &-25 {
                column-gap: 2.5rem !important;
            }

            &-3 {
                column-gap: 3rem !important;
            }

            &-35 {
                column-gap: 3.5rem !important;
            }

            &-4 {
                column-gap: 4rem !important;
            }

            &-45 {
                column-gap: 4.5rem !important;
            }

            &-5 {
                column-gap: 5rem !important;
            }

            &-6 {
                column-gap: 6rem !important;
            }

            &-7 {
                column-gap: 7rem !important;
            }

            &-8 {
                column-gap: 8rem !important;
            }
        }
        &-row-gap {
            &-05 {
                row-gap: 0.5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-1 {
                row-gap: 1rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-15 {
                row-gap: 1.5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-2 {
                row-gap: 2rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-25 {
                row-gap: 2.5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-3 {
                row-gap: 3rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-35 {
                row-gap: 3.5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-4 {
                row-gap: 4rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-45 {
                row-gap: 4.5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-5 {
                row-gap: 5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-6 {
                row-gap: 6rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-7 {
                row-gap: 7rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-8 {
                row-gap: 8rem !important; //sass-lint:disable-line no-misspelled-properties
            }
        }
    }
}
@include min-screen($screen-2xl) {
    .x2l {
        &-column-gap {
            &-1 {
                column-gap: 1rem !important;
            }
            &-15 {
                column-gap: 1.5rem !important;
            }

            &-2 {
                column-gap: 2rem !important;
            }

            &-25 {
                column-gap: 2.5rem !important;
            }

            &-3 {
                column-gap: 3rem !important;
            }

            &-35 {
                column-gap: 3.5rem !important;
            }

            &-4 {
                column-gap: 4rem !important;
            }

            &-45 {
                column-gap: 4.5rem !important;
            }

            &-5 {
                column-gap: 5rem !important;
            }

            &-6 {
                column-gap: 6rem !important;
            }

            &-7 {
                column-gap: 7rem !important;
            }

            &-8 {
                column-gap: 8rem !important;
            }
        }
        &-row-gap {
            &-05 {
                row-gap: 0.5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-1 {
                row-gap: 1rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-15 {
                row-gap: 1.5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-2 {
                row-gap: 2rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-25 {
                row-gap: 2.5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-3 {
                row-gap: 3rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-35 {
                row-gap: 3.5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-4 {
                row-gap: 4rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-45 {
                row-gap: 4.5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-5 {
                row-gap: 5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-6 {
                row-gap: 6rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-7 {
                row-gap: 7rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-8 {
                row-gap: 8rem !important; //sass-lint:disable-line no-misspelled-properties
            }
        }
    }
}
@include min-screen($screen-xxl) {
    .xxl {
        &-column-gap {
            &-1 {
                column-gap: 1rem !important;
            }
            &-15 {
                column-gap: 1.5rem !important;
            }

            &-2 {
                column-gap: 2rem !important;
            }

            &-25 {
                column-gap: 2.5rem !important;
            }

            &-3 {
                column-gap: 3rem !important;
            }

            &-35 {
                column-gap: 3.5rem !important;
            }

            &-4 {
                column-gap: 4rem !important;
            }

            &-45 {
                column-gap: 4.5rem !important;
            }

            &-5 {
                column-gap: 5rem !important;
            }

            &-6 {
                column-gap: 6rem !important;
            }

            &-7 {
                column-gap: 7rem !important;
            }

            &-8 {
                column-gap: 8rem !important;
            }
        }
        &-row-gap {
            &-05 {
                row-gap: 0.5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-1 {
                row-gap: 1rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-15 {
                row-gap: 1.5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-2 {
                row-gap: 2rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-25 {
                row-gap: 2.5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-3 {
                row-gap: 3rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-35 {
                row-gap: 3.5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-4 {
                row-gap: 4rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-45 {
                row-gap: 4.5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-5 {
                row-gap: 5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-6 {
                row-gap: 6rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-7 {
                row-gap: 7rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-8 {
                row-gap: 8rem !important; //sass-lint:disable-line no-misspelled-properties
            }
        }
    }
}
@include min-screen($screen-3xl) {
    .x3l {
        &-column-gap {
            &-1 {
                column-gap: 1rem !important;
            }
            &-15 {
                column-gap: 1.5rem !important;
            }

            &-2 {
                column-gap: 2rem !important;
            }

            &-25 {
                column-gap: 2.5rem !important;
            }

            &-3 {
                column-gap: 3rem !important;
            }

            &-35 {
                column-gap: 3.5rem !important;
            }

            &-4 {
                column-gap: 4rem !important;
            }

            &-45 {
                column-gap: 4.5rem !important;
            }

            &-5 {
                column-gap: 5rem !important;
            }

            &-6 {
                column-gap: 6rem !important;
            }

            &-7 {
                column-gap: 7rem !important;
            }

            &-8 {
                column-gap: 8rem !important;
            }
        }
        &-row-gap {
            &-05 {
                row-gap: 0.5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-1 {
                row-gap: 1rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-15 {
                row-gap: 1.5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-2 {
                row-gap: 2rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-25 {
                row-gap: 2.5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-3 {
                row-gap: 3rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-35 {
                row-gap: 3.5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-4 {
                row-gap: 4rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-45 {
                row-gap: 4.5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-5 {
                row-gap: 5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-6 {
                row-gap: 6rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-7 {
                row-gap: 7rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-8 {
                row-gap: 8rem !important; //sass-lint:disable-line no-misspelled-properties
            }
        }
    }
}
@include min-screen($screen-xxxl) {
    .xxxl {
        &-column-gap {
            &-1 {
                column-gap: 1rem !important;
            }
            &-15 {
                column-gap: 1.5rem !important;
            }

            &-2 {
                column-gap: 2rem !important;
            }

            &-25 {
                column-gap: 2.5rem !important;
            }

            &-3 {
                column-gap: 3rem !important;
            }

            &-35 {
                column-gap: 3.5rem !important;
            }

            &-4 {
                column-gap: 4rem !important;
            }

            &-45 {
                column-gap: 4.5rem !important;
            }

            &-5 {
                column-gap: 5rem !important;
            }

            &-6 {
                column-gap: 6rem !important;
            }

            &-7 {
                column-gap: 7rem !important;
            }

            &-8 {
                column-gap: 8rem !important;
            }
        }
        &-row-gap {
            &-05 {
                row-gap: 0.5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-1 {
                row-gap: 1rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-15 {
                row-gap: 1.5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-2 {
                row-gap: 2rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-25 {
                row-gap: 2.5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-3 {
                row-gap: 3rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-35 {
                row-gap: 3.5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-4 {
                row-gap: 4rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-45 {
                row-gap: 4.5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-5 {
                row-gap: 5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-6 {
                row-gap: 6rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-7 {
                row-gap: 7rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-8 {
                row-gap: 8rem !important; //sass-lint:disable-line no-misspelled-properties
            }
        }
    }
}
@include min-screen($screen-4xl) {
    .x4l {
        &-column-gap {
            &-1 {
                column-gap: 1rem !important;
            }
            &-15 {
                column-gap: 1.5rem !important;
            }

            &-2 {
                column-gap: 2rem !important;
            }

            &-25 {
                column-gap: 2.5rem !important;
            }

            &-3 {
                column-gap: 3rem !important;
            }

            &-35 {
                column-gap: 3.5rem !important;
            }

            &-4 {
                column-gap: 4rem !important;
            }

            &-45 {
                column-gap: 4.5rem !important;
            }

            &-5 {
                column-gap: 5rem !important;
            }

            &-6 {
                column-gap: 6rem !important;
            }

            &-7 {
                column-gap: 7rem !important;
            }

            &-8 {
                column-gap: 8rem !important;
            }
        }
        &-row-gap {
            &-05 {
                row-gap: 0.5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-1 {
                row-gap: 1rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-15 {
                row-gap: 1.5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-2 {
                row-gap: 2rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-25 {
                row-gap: 2.5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-3 {
                row-gap: 3rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-35 {
                row-gap: 3.5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-4 {
                row-gap: 4rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-45 {
                row-gap: 4.5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-5 {
                row-gap: 5rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-6 {
                row-gap: 6rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-7 {
                row-gap: 7rem !important; //sass-lint:disable-line no-misspelled-properties
            }

            &-8 {
                row-gap: 8rem !important; //sass-lint:disable-line no-misspelled-properties
            }
        }
    }
}
//sass-lint: enable no-important
