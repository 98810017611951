//prevent document from scrolling
//sass-lint:disable no-important
.background,
.bg {
    &-white {
        background-color: $white !important;
    }

    &-black {
        background-color: $black !important;
    }

    &-red {
        background-color: $red !important;
    }

    &-orange {
        background-color: $orange !important;
    }

    &-orange-dark {
        background-color: $orange-dark !important;
    }

    &-yellow {
        background-color: $yellow !important;
    }

    &-green {
        background-color: $green !important;
    }
    &-blue-light {
        background-color: $blue-light !important;
    }
    &-blue {
        background-color: $blue !important;
    }

    &-bright-sky-blue {
        background-color: $bright-sky-blue !important;
    }

    &-brown {
        background-color: $brown !important;
    }

    &-teal {
        background-color: $teal !important;
    }

    &-pink {
        background-color: $pink !important;
    }

    &-purple {
        background-color: $purple !important;
    }

    &-seafoam-blue {
        background-color: $seafoam-blue !important;
    }
    &-gray-100,
    &-gray-darkest {
        background-color: $gray-darkest !important;
    }
    &-gray-200,
    &-gray-darker {
        background-color: $gray-darker !important;
    }
    &-gray-300,
    &-gray-dark {
        background-color: $gray-dark !important;
    }
    &-gray-400,
    &-gray {
        background-color: $gray !important;
    }
    &-gray-500,
    &-gray-light {
        background-color: $gray-light !important;
    }
    &-gray-600,
    &-gray-lighter {
        background-color: $gray-lighter !important;
    }
    &-gray-700,
    &-gray-800,
    &-gray-900,
    &-gray-lightest {
        background-color: $gray-lightest !important;
    }

    &-theme-gold {
        background-color: $theme-gold !important;
    }

    &-theme-primary {
        background-color: $theme-primary !important;
    }

    &-theme-second {
        background-color: $theme-second !important;
    }

    &-theme-dark {
        background-color: $theme-dark !important;
    }

    &-primary {
        background-color: $color-primary !important;
    }

    &-secondary {
        background-color: $color-secondary !important;
    }

    &-tertiary {
        background-color: $color-tertiary !important;
    }

    &-success {
        background-color: $color-success !important;
    }

    &-info {
        background-color: $color-info !important;
    }

    &-warning {
        background-color: $color-warning !important;
    }

    &-error {
        background-color: $color-error !important;
    }

    &-inverse {
        background-color: $color-inverse !important;
    }

    &-focus {
        background-color: $color-focus !important;
    }
}
@include max-screen($screen-m - 1) {
    .xs {
        .background,
        .bg {
            &-white {
                background-color: $white !important;
            }

            &-black {
                background-color: $black !important;
            }

            &-red {
                background-color: $red !important;
            }

            &-orange {
                background-color: $orange !important;
            }

            &-orange-dark {
                background-color: $orange-dark !important;
            }

            &-yellow {
                background-color: $yellow !important;
            }

            &-green {
                background-color: $green !important;
            }
            &-blue-light {
                background-color: $blue-light !important;
            }
            &-blue {
                background-color: $blue !important;
            }

            &-bright-sky-blue {
                background-color: $bright-sky-blue !important;
            }

            &-brown {
                background-color: $brown !important;
            }

            &-teal {
                background-color: $teal !important;
            }

            &-pink {
                background-color: $pink !important;
            }

            &-purple {
                background-color: $purple !important;
            }

            &-seafoam-blue {
                background-color: $seafoam-blue !important;
            }
            &-gray-100,
            &-gray-darkest {
                background-color: $gray-darkest !important;
            }
            &-gray-200,
            &-gray-darker {
                background-color: $gray-darker !important;
            }
            &-gray-300,
            &-gray-dark {
                background-color: $gray-dark !important;
            }
            &-gray-400,
            &-gray {
                background-color: $gray !important;
            }
            &-gray-500,
            &-gray-light {
                background-color: $gray-light !important;
            }
            &-gray-600,
            &-gray-lighter {
                background-color: $gray-lighter !important;
            }
            &-gray-700,
            &-gray-800,
            &-gray-900,
            &-gray-lightest {
                background-color: $gray-lightest !important;
            }

            &-theme-gold {
                background-color: $theme-gold !important;
            }

            &-theme-primary {
                background-color: $theme-primary !important;
            }

            &-theme-second {
                background-color: $theme-second !important;
            }

            &-theme-dark {
                background-color: $theme-dark !important;
            }

            &-primary {
                background-color: $color-primary !important;
            }

            &-secondary {
                background-color: $color-secondary !important;
            }

            &-tertiary {
                background-color: $color-tertiary !important;
            }

            &-success {
                background-color: $color-success !important;
            }

            &-info {
                background-color: $color-info !important;
            }

            &-warning {
                background-color: $color-warning !important;
            }

            &-error {
                background-color: $color-error !important;
            }

            &-inverse {
                background-color: $color-inverse !important;
            }

            &-focus {
                background-color: $color-focus !important;
            }
        }
    }
}
@include min-screen($screen-m) {
    .md {
        .background,
        .bg {
            &-white {
                background-color: $white !important;
            }

            &-black {
                background-color: $black !important;
            }

            &-red {
                background-color: $red !important;
            }

            &-orange {
                background-color: $orange !important;
            }

            &-orange-dark {
                background-color: $orange-dark !important;
            }

            &-yellow {
                background-color: $yellow !important;
            }

            &-green {
                background-color: $green !important;
            }
            &-blue-light {
                background-color: $blue-light !important;
            }
            &-blue {
                background-color: $blue !important;
            }

            &-bright-sky-blue {
                background-color: $bright-sky-blue !important;
            }

            &-brown {
                background-color: $brown !important;
            }

            &-teal {
                background-color: $teal !important;
            }

            &-pink {
                background-color: $pink !important;
            }

            &-purple {
                background-color: $purple !important;
            }

            &-seafoam-blue {
                background-color: $seafoam-blue !important;
            }
            &-gray-100,
            &-gray-darkest {
                background-color: $gray-darkest !important;
            }
            &-gray-200,
            &-gray-darker {
                background-color: $gray-darker !important;
            }
            &-gray-300,
            &-gray-dark {
                background-color: $gray-dark !important;
            }
            &-gray-400,
            &-gray {
                background-color: $gray !important;
            }
            &-gray-500,
            &-gray-light {
                background-color: $gray-light !important;
            }
            &-gray-600,
            &-gray-lighter {
                background-color: $gray-lighter !important;
            }
            &-gray-700,
            &-gray-800,
            &-gray-900,
            &-gray-lightest {
                background-color: $gray-lightest !important;
            }

            &-theme-gold {
                background-color: $theme-gold !important;
            }

            &-theme-primary {
                background-color: $theme-primary !important;
            }

            &-theme-second {
                background-color: $theme-second !important;
            }

            &-theme-dark {
                background-color: $theme-dark !important;
            }

            &-primary {
                background-color: $color-primary !important;
            }

            &-secondary {
                background-color: $color-secondary !important;
            }

            &-tertiary {
                background-color: $color-tertiary !important;
            }

            &-success {
                background-color: $color-success !important;
            }

            &-info {
                background-color: $color-info !important;
            }

            &-warning {
                background-color: $color-warning !important;
            }

            &-error {
                background-color: $color-error !important;
            }

            &-inverse {
                background-color: $color-inverse !important;
            }

            &-focus {
                background-color: $color-focus !important;
            }
        }
    }
}
@include min-screen($screen-l) {
    .lg {
        .background,
        .bg {
            &-white {
                background-color: $white !important;
            }

            &-black {
                background-color: $black !important;
            }

            &-red {
                background-color: $red !important;
            }

            &-orange {
                background-color: $orange !important;
            }

            &-orange-dark {
                background-color: $orange-dark !important;
            }

            &-yellow {
                background-color: $yellow !important;
            }

            &-green {
                background-color: $green !important;
            }
            &-blue-light {
                background-color: $blue-light !important;
            }
            &-blue {
                background-color: $blue !important;
            }

            &-bright-sky-blue {
                background-color: $bright-sky-blue !important;
            }

            &-brown {
                background-color: $brown !important;
            }

            &-teal {
                background-color: $teal !important;
            }

            &-pink {
                background-color: $pink !important;
            }

            &-purple {
                background-color: $purple !important;
            }

            &-seafoam-blue {
                background-color: $seafoam-blue !important;
            }
            &-gray-100,
            &-gray-darkest {
                background-color: $gray-darkest !important;
            }
            &-gray-200,
            &-gray-darker {
                background-color: $gray-darker !important;
            }
            &-gray-300,
            &-gray-dark {
                background-color: $gray-dark !important;
            }
            &-gray-400,
            &-gray {
                background-color: $gray !important;
            }
            &-gray-500,
            &-gray-light {
                background-color: $gray-light !important;
            }
            &-gray-600,
            &-gray-lighter {
                background-color: $gray-lighter !important;
            }
            &-gray-700,
            &-gray-800,
            &-gray-900,
            &-gray-lightest {
                background-color: $gray-lightest !important;
            }

            &-theme-gold {
                background-color: $theme-gold !important;
            }

            &-theme-primary {
                background-color: $theme-primary !important;
            }

            &-theme-second {
                background-color: $theme-second !important;
            }

            &-theme-dark {
                background-color: $theme-dark !important;
            }

            &-primary {
                background-color: $color-primary !important;
            }

            &-secondary {
                background-color: $color-secondary !important;
            }

            &-tertiary {
                background-color: $color-tertiary !important;
            }

            &-success {
                background-color: $color-success !important;
            }

            &-info {
                background-color: $color-info !important;
            }

            &-warning {
                background-color: $color-warning !important;
            }

            &-error {
                background-color: $color-error !important;
            }

            &-inverse {
                background-color: $color-inverse !important;
            }

            &-focus {
                background-color: $color-focus !important;
            }
        }
    }
}
@include min-screen($screen-xl) {
    .xl {
        .background,
        .bg {
            &-white {
                background-color: $white !important;
            }

            &-black {
                background-color: $black !important;
            }

            &-red {
                background-color: $red !important;
            }

            &-orange {
                background-color: $orange !important;
            }

            &-orange-dark {
                background-color: $orange-dark !important;
            }

            &-yellow {
                background-color: $yellow !important;
            }

            &-green {
                background-color: $green !important;
            }
            &-blue-light {
                background-color: $blue-light !important;
            }
            &-blue {
                background-color: $blue !important;
            }

            &-bright-sky-blue {
                background-color: $bright-sky-blue !important;
            }

            &-brown {
                background-color: $brown !important;
            }

            &-teal {
                background-color: $teal !important;
            }

            &-pink {
                background-color: $pink !important;
            }

            &-purple {
                background-color: $purple !important;
            }

            &-seafoam-blue {
                background-color: $seafoam-blue !important;
            }
            &-gray-100,
            &-gray-darkest {
                background-color: $gray-darkest !important;
            }
            &-gray-200,
            &-gray-darker {
                background-color: $gray-darker !important;
            }
            &-gray-300,
            &-gray-dark {
                background-color: $gray-dark !important;
            }
            &-gray-400,
            &-gray {
                background-color: $gray !important;
            }
            &-gray-500,
            &-gray-light {
                background-color: $gray-light !important;
            }
            &-gray-600,
            &-gray-lighter {
                background-color: $gray-lighter !important;
            }
            &-gray-700,
            &-gray-800,
            &-gray-900,
            &-gray-lightest {
                background-color: $gray-lightest !important;
            }

            &-theme-gold {
                background-color: $theme-gold !important;
            }

            &-theme-primary {
                background-color: $theme-primary !important;
            }

            &-theme-second {
                background-color: $theme-second !important;
            }

            &-theme-dark {
                background-color: $theme-dark !important;
            }

            &-primary {
                background-color: $color-primary !important;
            }

            &-secondary {
                background-color: $color-secondary !important;
            }

            &-tertiary {
                background-color: $color-tertiary !important;
            }

            &-success {
                background-color: $color-success !important;
            }

            &-info {
                background-color: $color-info !important;
            }

            &-warning {
                background-color: $color-warning !important;
            }

            &-error {
                background-color: $color-error !important;
            }

            &-inverse {
                background-color: $color-inverse !important;
            }

            &-focus {
                background-color: $color-focus !important;
            }
        }
    }
}
@include min-screen($screen-2xl) {
    .x2l {
        .background,
        .bg {
            &-white {
                background-color: $white !important;
            }

            &-black {
                background-color: $black !important;
            }

            &-red {
                background-color: $red !important;
            }

            &-orange {
                background-color: $orange !important;
            }

            &-orange-dark {
                background-color: $orange-dark !important;
            }

            &-yellow {
                background-color: $yellow !important;
            }

            &-green {
                background-color: $green !important;
            }
            &-blue-light {
                background-color: $blue-light !important;
            }
            &-blue {
                background-color: $blue !important;
            }

            &-bright-sky-blue {
                background-color: $bright-sky-blue !important;
            }

            &-brown {
                background-color: $brown !important;
            }

            &-teal {
                background-color: $teal !important;
            }

            &-pink {
                background-color: $pink !important;
            }

            &-purple {
                background-color: $purple !important;
            }

            &-seafoam-blue {
                background-color: $seafoam-blue !important;
            }
            &-gray-100,
            &-gray-darkest {
                background-color: $gray-darkest !important;
            }
            &-gray-200,
            &-gray-darker {
                background-color: $gray-darker !important;
            }
            &-gray-300,
            &-gray-dark {
                background-color: $gray-dark !important;
            }
            &-gray-400,
            &-gray {
                background-color: $gray !important;
            }
            &-gray-500,
            &-gray-light {
                background-color: $gray-light !important;
            }
            &-gray-600,
            &-gray-lighter {
                background-color: $gray-lighter !important;
            }
            &-gray-700,
            &-gray-800,
            &-gray-900,
            &-gray-lightest {
                background-color: $gray-lightest !important;
            }

            &-theme-gold {
                background-color: $theme-gold !important;
            }

            &-theme-primary {
                background-color: $theme-primary !important;
            }

            &-theme-second {
                background-color: $theme-second !important;
            }

            &-theme-dark {
                background-color: $theme-dark !important;
            }

            &-primary {
                background-color: $color-primary !important;
            }

            &-secondary {
                background-color: $color-secondary !important;
            }

            &-tertiary {
                background-color: $color-tertiary !important;
            }

            &-success {
                background-color: $color-success !important;
            }

            &-info {
                background-color: $color-info !important;
            }

            &-warning {
                background-color: $color-warning !important;
            }

            &-error {
                background-color: $color-error !important;
            }

            &-inverse {
                background-color: $color-inverse !important;
            }

            &-focus {
                background-color: $color-focus !important;
            }
        }
    }
}
@include min-screen($screen-xxl) {
    .xxl {
        .background,
        .bg {
            &-white {
                background-color: $white !important;
            }

            &-black {
                background-color: $black !important;
            }

            &-red {
                background-color: $red !important;
            }

            &-orange {
                background-color: $orange !important;
            }

            &-orange-dark {
                background-color: $orange-dark !important;
            }

            &-yellow {
                background-color: $yellow !important;
            }

            &-green {
                background-color: $green !important;
            }
            &-blue-light {
                background-color: $blue-light !important;
            }
            &-blue {
                background-color: $blue !important;
            }

            &-bright-sky-blue {
                background-color: $bright-sky-blue !important;
            }

            &-brown {
                background-color: $brown !important;
            }

            &-teal {
                background-color: $teal !important;
            }

            &-pink {
                background-color: $pink !important;
            }

            &-purple {
                background-color: $purple !important;
            }

            &-seafoam-blue {
                background-color: $seafoam-blue !important;
            }
            &-gray-100,
            &-gray-darkest {
                background-color: $gray-darkest !important;
            }
            &-gray-200,
            &-gray-darker {
                background-color: $gray-darker !important;
            }
            &-gray-300,
            &-gray-dark {
                background-color: $gray-dark !important;
            }
            &-gray-400,
            &-gray {
                background-color: $gray !important;
            }
            &-gray-500,
            &-gray-light {
                background-color: $gray-light !important;
            }
            &-gray-600,
            &-gray-lighter {
                background-color: $gray-lighter !important;
            }
            &-gray-700,
            &-gray-800,
            &-gray-900,
            &-gray-lightest {
                background-color: $gray-lightest !important;
            }

            &-theme-gold {
                background-color: $theme-gold !important;
            }

            &-theme-primary {
                background-color: $theme-primary !important;
            }

            &-theme-second {
                background-color: $theme-second !important;
            }

            &-theme-dark {
                background-color: $theme-dark !important;
            }

            &-primary {
                background-color: $color-primary !important;
            }

            &-secondary {
                background-color: $color-secondary !important;
            }

            &-tertiary {
                background-color: $color-tertiary !important;
            }

            &-success {
                background-color: $color-success !important;
            }

            &-info {
                background-color: $color-info !important;
            }

            &-warning {
                background-color: $color-warning !important;
            }

            &-error {
                background-color: $color-error !important;
            }

            &-inverse {
                background-color: $color-inverse !important;
            }

            &-focus {
                background-color: $color-focus !important;
            }
        }
    }
}
@include min-screen($screen-3xl) {
    .x3l {
        .background,
        .bg {
            &-white {
                background-color: $white !important;
            }

            &-black {
                background-color: $black !important;
            }

            &-red {
                background-color: $red !important;
            }

            &-orange {
                background-color: $orange !important;
            }

            &-orange-dark {
                background-color: $orange-dark !important;
            }

            &-yellow {
                background-color: $yellow !important;
            }

            &-green {
                background-color: $green !important;
            }
            &-blue-light {
                background-color: $blue-light !important;
            }
            &-blue {
                background-color: $blue !important;
            }

            &-bright-sky-blue {
                background-color: $bright-sky-blue !important;
            }

            &-brown {
                background-color: $brown !important;
            }

            &-teal {
                background-color: $teal !important;
            }

            &-pink {
                background-color: $pink !important;
            }

            &-purple {
                background-color: $purple !important;
            }

            &-seafoam-blue {
                background-color: $seafoam-blue !important;
            }
            &-gray-100,
            &-gray-darkest {
                background-color: $gray-darkest !important;
            }
            &-gray-200,
            &-gray-darker {
                background-color: $gray-darker !important;
            }
            &-gray-300,
            &-gray-dark {
                background-color: $gray-dark !important;
            }
            &-gray-400,
            &-gray {
                background-color: $gray !important;
            }
            &-gray-500,
            &-gray-light {
                background-color: $gray-light !important;
            }
            &-gray-600,
            &-gray-lighter {
                background-color: $gray-lighter !important;
            }
            &-gray-700,
            &-gray-800,
            &-gray-900,
            &-gray-lightest {
                background-color: $gray-lightest !important;
            }

            &-theme-gold {
                background-color: $theme-gold !important;
            }

            &-theme-primary {
                background-color: $theme-primary !important;
            }

            &-theme-second {
                background-color: $theme-second !important;
            }

            &-theme-dark {
                background-color: $theme-dark !important;
            }

            &-primary {
                background-color: $color-primary !important;
            }

            &-secondary {
                background-color: $color-secondary !important;
            }

            &-tertiary {
                background-color: $color-tertiary !important;
            }

            &-success {
                background-color: $color-success !important;
            }

            &-info {
                background-color: $color-info !important;
            }

            &-warning {
                background-color: $color-warning !important;
            }

            &-error {
                background-color: $color-error !important;
            }

            &-inverse {
                background-color: $color-inverse !important;
            }

            &-focus {
                background-color: $color-focus !important;
            }
        }
    }
}
@include min-screen($screen-xxxl) {
    .xxxl {
        .background,
        .bg {
            &-white {
                background-color: $white !important;
            }

            &-black {
                background-color: $black !important;
            }

            &-red {
                background-color: $red !important;
            }

            &-orange {
                background-color: $orange !important;
            }

            &-orange-dark {
                background-color: $orange-dark !important;
            }

            &-yellow {
                background-color: $yellow !important;
            }

            &-green {
                background-color: $green !important;
            }
            &-blue-light {
                background-color: $blue-light !important;
            }
            &-blue {
                background-color: $blue !important;
            }

            &-bright-sky-blue {
                background-color: $bright-sky-blue !important;
            }

            &-brown {
                background-color: $brown !important;
            }

            &-teal {
                background-color: $teal !important;
            }

            &-pink {
                background-color: $pink !important;
            }

            &-purple {
                background-color: $purple !important;
            }

            &-seafoam-blue {
                background-color: $seafoam-blue !important;
            }
            &-gray-100,
            &-gray-darkest {
                background-color: $gray-darkest !important;
            }
            &-gray-200,
            &-gray-darker {
                background-color: $gray-darker !important;
            }
            &-gray-300,
            &-gray-dark {
                background-color: $gray-dark !important;
            }
            &-gray-400,
            &-gray {
                background-color: $gray !important;
            }
            &-gray-500,
            &-gray-light {
                background-color: $gray-light !important;
            }
            &-gray-600,
            &-gray-lighter {
                background-color: $gray-lighter !important;
            }
            &-gray-700,
            &-gray-800,
            &-gray-900,
            &-gray-lightest {
                background-color: $gray-lightest !important;
            }

            &-theme-gold {
                background-color: $theme-gold !important;
            }

            &-theme-primary {
                background-color: $theme-primary !important;
            }

            &-theme-second {
                background-color: $theme-second !important;
            }

            &-theme-dark {
                background-color: $theme-dark !important;
            }

            &-primary {
                background-color: $color-primary !important;
            }

            &-secondary {
                background-color: $color-secondary !important;
            }

            &-tertiary {
                background-color: $color-tertiary !important;
            }

            &-success {
                background-color: $color-success !important;
            }

            &-info {
                background-color: $color-info !important;
            }

            &-warning {
                background-color: $color-warning !important;
            }

            &-error {
                background-color: $color-error !important;
            }

            &-inverse {
                background-color: $color-inverse !important;
            }

            &-focus {
                background-color: $color-focus !important;
            }
        }
    }
}
@include min-screen($screen-4xl) {
    .x4l {
        .background,
        .bg {
            &-white {
                background-color: $white !important;
            }

            &-black {
                background-color: $black !important;
            }

            &-red {
                background-color: $red !important;
            }

            &-orange {
                background-color: $orange !important;
            }

            &-orange-dark {
                background-color: $orange-dark !important;
            }

            &-yellow {
                background-color: $yellow !important;
            }

            &-green {
                background-color: $green !important;
            }
            &-blue-light {
                background-color: $blue-light !important;
            }
            &-blue {
                background-color: $blue !important;
            }

            &-bright-sky-blue {
                background-color: $bright-sky-blue !important;
            }

            &-brown {
                background-color: $brown !important;
            }

            &-teal {
                background-color: $teal !important;
            }

            &-pink {
                background-color: $pink !important;
            }

            &-purple {
                background-color: $purple !important;
            }

            &-seafoam-blue {
                background-color: $seafoam-blue !important;
            }
            &-gray-100,
            &-gray-darkest {
                background-color: $gray-darkest !important;
            }
            &-gray-200,
            &-gray-darker {
                background-color: $gray-darker !important;
            }
            &-gray-300,
            &-gray-dark {
                background-color: $gray-dark !important;
            }
            &-gray-400,
            &-gray {
                background-color: $gray !important;
            }
            &-gray-500,
            &-gray-light {
                background-color: $gray-light !important;
            }
            &-gray-600,
            &-gray-lighter {
                background-color: $gray-lighter !important;
            }
            &-gray-700,
            &-gray-800,
            &-gray-900,
            &-gray-lightest {
                background-color: $gray-lightest !important;
            }

            &-theme-gold {
                background-color: $theme-gold !important;
            }

            &-theme-primary {
                background-color: $theme-primary !important;
            }

            &-theme-second {
                background-color: $theme-second !important;
            }

            &-theme-dark {
                background-color: $theme-dark !important;
            }

            &-primary {
                background-color: $color-primary !important;
            }

            &-secondary {
                background-color: $color-secondary !important;
            }

            &-tertiary {
                background-color: $color-tertiary !important;
            }

            &-success {
                background-color: $color-success !important;
            }

            &-info {
                background-color: $color-info !important;
            }

            &-warning {
                background-color: $color-warning !important;
            }

            &-error {
                background-color: $color-error !important;
            }

            &-inverse {
                background-color: $color-inverse !important;
            }

            &-focus {
                background-color: $color-focus !important;
            }
        }
    }
}

//sass-lint:enable no-important
