.products-top {
    flex-grow: 1;
    width: 100%;
    margin: 0 !important; //sass-lint:disable-line no-important
}

.catalog-product-view {
    .column.main {
        flex-grow: inherit;
        flex-basis: inherit;

        .content {
            display: flex;
            flex-direction: column;
            width: 100%;

            @include min-screen($screen__m) {
                display: grid;
                grid-gap: 4rem;
                grid-template-columns: minmax(55%, 45%);
                grid-template-areas:
                "details-main sidebar"
                "youtube-video sidebar"
                "details-secondary sidebar"
                "related-products related-products";

            }
        }

        .product.media,
        .product.info.detailed {
            //@include make-col-ready();
            margin-bottom: 3rem;
        }

        .product.media {
            order: 0;
            @include min-screen($screen__m) {
                grid-area: details-main;
                width: 100%;
            }
        }

        .product.info.detailed {
            @include min-screen($screen__m) {
                grid-area: details-secondary;
            }
        }

        .product-info-youtube {
            order: 2;
            position: relative;
            padding-bottom: 56.25%;
            padding-top: 30px;
            height: 0;
            overflow: hidden;

            & iframe,
            & object,
            & embed {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
            @include min-screen($screen__m) {
                grid-area: youtube-video;
            }
        }

        .product-info-sidebar {
            grid-area: sidebar;
            order: 1;
        }

        .product-info-main {
            @include make-col-ready();

            margin-bottom: 3rem;
            position: static;

            @include min-screen($screen__m) {
                //@include make-col($product-view-column-width-right);
                top: 3rem;
                border: none;
                border-radius: 5px;
                margin-bottom: 0;
                position: sticky;
                box-shadow: 0 0 15px #ccc;
            }

            .page-title-wrapper .page-title {
                line-height: $h1__line-height;
                margin-top: 20px;
            }

            .product-reviews-summary .reviews-actions a {
                color: $text__color;
            }

            .product.attribute.sku {
                color: $text__color;
            }
        }

        .product-info-top-extra {
            display: block;
            margin: 0;
        }

        .pros-cons {
            ul {
                list-style: none;
                margin: 0 0 0.5em;
                padding: 0;
                line-height: 1.3;

                &:last-child {
                    margin-bottom: 1em;
                }

                .item {
                    margin-bottom: 4px;
                    display: flex;

                    .fas {
                        margin: 2px 0.3em 0 0;
                        color: $brand__cta__color;

                        &.fa-minus-circle {
                            color: $brand__error__color;
                        }
                    }
                }
            }
        }
    }

    .product-image-photo {
        position: relative;
    }
}
.product-usps {
    border: none;
}
.product-add-form,
.simple-product .product-add-form,
.product-virtual-product .product-add-form,
.page-product-configurable .product-options-bottom,
.page-product-downloadable .product-options-bottom,
.page-product-bundle .customize.button,
.page-product-grouped .product-add-form,
.page-product-bundle .bundle-options-container  {
    @include brand-block();
    border: none;
    .price-box {
        .price {
            font-size: $font-size__xxl;
        }
        .old-price {
            .price {
                font-size: $font-size__xl;
            }
        }
        .price-excluding-tax {
          display: block;
        }
    }
    .price-discount-saving {
        display: flex;
        width: max-content;
        background: $yellow;
        padding: 0.2rem 1rem;
        color: $red;
        border-radius: $border-radius;
        align-items: center;
        @include min-screen($screen__m) {
            padding: 1rem;
        }
        span {
            font-size: $font-size-large;
            font-weight: 600;
            margin-left: 0.5rem ;
        }
    }
    .product-info-price {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;


        @include min-screen($screen__xs) {
            display: flex;
            flex-direction: row;
        }

        @include min-screen($screen__m) {
            width: 100%;
            column-gap: 2rem;
            align-items: center;
        }

        .product-info-stock-sku {
            margin-bottom: 1rem;

            @include min-screen($screen__xs) {
                margin-right: 1rem;
                flex-grow: 1;
            }
        }
    }

    .product-options-wrapper .fieldset .options-list .field {
        &.choice input {
            vertical-align: baseline;
            margin-top: 0;
        }

        .price-notice #{$brand__price__secondary__class} {
            display: none;
        }
    }

    #product_addtocart_form .product-addto-links {
        text-align: left;
        margin-top: $indent__base;
        padding-bottom: $indent__base;

        i {
            font-weight: bold;
        }

        a:hover {
            color: $text__color;

            span {
                text-decoration: underline;
            }
        }

        .tocompare {
            display: block;

            @include min-screen($screen__xl) {
                float: right;
            }
        }
    }

    .swaptools-promotion {
        margin-bottom: $indent__s;
        padding: $indent__s $indent__m;
        display: block;
        color: $color-white;
        background: $brand__cta__color;
        border-radius: $button__border-radius;
    }

    .product-info-payment {
        border-top: $brand__block__border;

        .product-info-payment-label {
            padding-top: $indent__s;
            color: $text__color-muted;
            font-weight: $font-weight__bold;
        }

        .payment-logos {
            display: flex;
            list-style: none;
            padding-left: 0;
            margin-top: $indent__s;
            margin-bottom: 0;
        }
    }

    .table-wrapper.grouped .table.grouped {
        border-collapse: separate;
        border-radius: $brand__border-radius;

        tbody tr:last-child td {
            border-bottom: 0;
        }

        .item-inner {
            flex-wrap: wrap;
        }

        .stock {
            width: 100%;
            order: 2;
            display: block;
            font-size: 0.9em;
            font-weight: normal;
            font-style: italic;
        }

        .column.qty {
            @include max-screen($screen__s) {
                width: 100%;

                .input-text.qty {
                    width: 100%;
                }
            }
        }
    }

    .box-tocart {
        .fieldset {
            margin-top: $indent__l;

            @mixin tocart-stacked() {
                .field.qty {
                    .control {
                        width: 100%;
                    }

                    .input-text.qty {
                        flex-grow: 1;
                    }
                }
            }

            @mixin tocart-side-by-side() {
                display: flex;

                .fieldset {
                    padding-top: $indent__s;
                }

                .field.qty {
                    margin-right: $grid-compact-gutter-width / 2;
                    height: 50px;
                    margin-bottom: 0;

                    @include min-screen($screen__l) {
                        margin-right: $grid-gutter-width / 2;
                    }

                    .control .input-text.qty {
                        height: 50px;
                        width: 50px;
                    }
                }

                .actions {
                    flex-grow: 1;
                }
            }

            @include max-screen($screen__s) {
                @include tocart-stacked();
            }

            @include screen($screen__s, $screen__m) {
                @include tocart-side-by-side();
            }

            @include screen($screen__m, $screen__l) {
                @include tocart-stacked();
            }

            @include min-screen($screen__l) {
                @include tocart-side-by-side();
            }

            .actions {
                .action.primary.tocart {
                    @include button-cta();

                    i {
                        display: none;
                    }
                }
            }

            .floating-button {
                @include brand-shadow-box();

                position: fixed;
                z-index: 100;
                right: 0;
                bottom: 0;
                left: 0;
                padding: $indent__s;
                border-radius: 0;
                transform: translateY(100%);
                transition: transform ease 200ms;

                &.visible {
                    transform: translateY(0);
                }

                @include min-screen($screen__s) {
                    display: none;
                }
            }
        }
    }

    &.out-of-stock {
        .product-info-stock-usps,
        .product-info-payment {
            display: none;
        }

        #product_addtocart_form .product-addto-links {
            padding-bottom: 0;
        }

        .stock-message {
            color: $text__color-muted;
            font-weight: $font-weight__semibold;
            padding: $indent__s 0 $indent__s;
        }

        .action.primary.related {
            background: $brand__cta__color;
            width: 100%;
            line-height: 2.2rem;
            padding: $button__padding__l;
            font-size: $font-size__xl;

            a {
                text-decoration: none;
                color: $color-white;
            }
        }
    }
    .stock {
        margin-top: 0.5rem;
        span.ampreorder-observed {
            color: $text__color;
            line-height: 24px;
            font-weight: 400;

            strong {
                color: $brand__tertiary__color;
                font-weight: 600;
            }
        }
    }

}

.product-info-main {
    .price-box {
        #{$brand__price__primary__class} {
            font-size: 2.2rem;
        }

        .old-price {
            #{$brand__price__primary__class} {
                font-size: $font-size__base;
            }
        }

        #{$brand__price__secondary__class} {
            font-size: $font-size__base;
        }

        .price-from {
            display: inline-block;

            &::after {
                content: " - ";
                font-size: 2.4rem;
            }
        }

        .price-to {
            display: inline-block;
        }
    }

    .items.samples {
        margin-bottom: $indent__base;
    }
}

.column.main .product.info.detailed {
    order: 2;
    @include max-screen($screen__m) {
        iframe {
            width: 100%;
        }
    }
    @include min-screen($screen__m) {
        box-shadow: 0 0 15px #cccc;
        padding: 1.5rem 2.5rem;
        border-radius: 5px;
    }
    .product-info-title {
        padding: 0.5em 0;
        margin-bottom: 0;
        color: $brand__secondary__color;
        border-top: 1px solid $brand__secondary__lightest;
        border-bottom: none;
        align-items: center;
        cursor: pointer;
        font-size: 2rem;

        @include min-screen($screen__m) {
            border-top: 0;
            padding: 0 0 0.3em;
            font-size: 2.4rem;
        }

        &:first-child {
            border-top: none;
        }
    }

    .additional-attributes {
        .label {
            width: 33%;
        }

        tr:nth-child(odd) {
            background: $brand__secondary__lightest;
        }
    }
}

.column.product-bottom {
    order: 2;
    width: 100%;

    .block-title strong {
        font-family: $font-family__brand;
        font-weight: $font-weight__bold;
        font-size: 2rem;
        color: $brand__secondary__color;
        display: block;

        @include min-screen($screen__m) {
            font-size: 2.4rem;
        }
    }
}

.product.media {
    .fotorama__stage {
        @include max-screen($screen__m) {
            @include brand-shadow-box();

            margin: 2px;
            border-radius: $brand__border-radius;
        }
    }

    .fotorama-item,
    ._block-content-loading {
        @include min-screen($screen__m) {
            //@include brand-shadow-box();

            border-radius: $brand__border-radius;
        }
    }

    .fotorama__nav-wrap {
        @include min-screen($screen__m) {
            border-top: 1px solid rgba(10, 34, 57, 0.06);
            padding: $grid-compact-gutter-width / 2;
        }

        @include min-screen($screen__l) {
            padding: $grid-gutter-width / 2;
        }

        .fotorama__thumb-border {
            border: 2px solid $brand__secondary__color;
        }
    }

    .fotorama__dot {
        border: 1px solid $text__color;
    }

    .fotorama__active .fotorama__dot {
        border: 1px solid $text__color;
        background: $text__color;
    }
}

.products-grid {
    .ampreorder-observed {
        .product-item-info {
            .ampreorder-note {
                margin: 2rem 0 0;

                ~ .product-item-inner {
                    button {
                        background: $brand__tertiary__color;
                    }
                }
            }
        }
    }
}
