//sass-lint:disable no-important
// font-size
.font-size,
.f-s {
    font-size: $font-size-base !important;
    &-xs,
    &--xs {
        font-size: $font-size-extra-small !important;
    }
    &-s,
    &--s {
        font-size: $font-size-small !important;
    }
    &-m,
    &--m {
        font-size: $font-size-medium !important;
    }
    &-ml,
    &--s-m {
        font-size: $font-size-medium-large !important;
    }
    &-l,
    &--l {
        font-size: $font-size-medium !important;
        @include min-screen($screen-m) {
            font-size: $font-size-large !important;
        }
    }
    &-larger,
    &--s-l {
        font-size: $font-size-medium-large !important;
        @include min-screen($screen-m) {
            font-size: $font-size-larger !important;
        }
    }
    &-xl,
    &--xl {
        font-size: $font-size-large !important;
        @include min-screen($screen-m) {
            font-size: $font-size-extra-large !important;
        }
    }
    &-xxl,
    &--xxl {
        font-size: $font-size-larger !important;
        @include min-screen($screen-m) {
            font-size: $font-size-2x-large !important;
        }
    }
    &-sxl,
    &--sxl {
        font-size: $font-size-extra-large !important;
        @include min-screen($screen-m) {
            font-size: $font-size-super-extra-large !important;
        }
    }
    &-heading {
        font-size: $font-size-larger !important;
        @include min-screen($screen-m) {
            font-size: $font-size-heading !important;
        }
    }
    &-heading-l {
        font-size: $font-size-heading !important;
        @include min-screen($screen-m) {
            font-size: $font-size-heading-large !important;
        }
    }
    &-heading-xl {
        font-size: $font-size-heading !important;
        @include min-screen($screen-m) {
            font-size: $font-size-heading-large !important;
        }
        @include min-screen($screen-l) {
            font-size: $font-size-heading-larger !important;
        }
    }
    &-heading-xxl {
        font-size: $font-size-heading-large !important;
        @include min-screen($screen-l) {
            font-size: $font-size-heading-largest !important;
        }
    }
}
.font-style {
    &-italic {
        font-style: italic !important;
    }
    &-normal {
        font-style: normal !important;
    }
    &-oblique {
        font-style: oblique !important;
    }
}
.line-height {
    &-xs {
        line-height: $font-size-extra-small !important;
    }
    &-s {
        line-height: $font-size-small !important;
    }
    &-m {
        line-height: $font-size-medium !important;
    }
    &-ml {
        line-height: $font-size-medium-large !important;
    }
    &-l {
        line-height: $font-size-large !important;
    }
    &-larger {
        line-height: $font-size-larger !important;
    }
    &-xl {
        line-height: $font-size-extra-large !important;
    }
    &-xxl {
        line-height: $font-size-2x-large !important;
    }
    &-sxl {
        line-height: $font-size-super-extra-large !important;
    }
    &-heading {
        line-height: $font-size-heading !important;
    }
    &-heading-l {
        line-height: $font-size-heading-large !important;
    }
    &-heading-xl {
        line-height: $font-size-heading-larger !important;
    }
    &-heading-xxl {
        line-height: $font-size-heading-largest !important;
    }
}

@include max-screen($screen-m - 1) {
    .xs {
        &-font-style {
            &-italic {
                font-style: italic !important;
            }
            &-normal {
                font-style: normal !important;
            }
            &-oblique {
                font-style: oblique !important;
            }
        }
        &-font-size {
            font-size: $font-size-base !important;

            &-xs {
                font-size: $font-size-extra-small !important;
            }

            &-s {
                font-size: $font-size-small !important;
            }

            &-m {
                font-size: $font-size-medium !important;
            }

            &-sl {
                font-size: $font-size-medium-large !important;
            }

            &-m {
                font-size: $font-size-medium !important;
            }

            &-large {
                font-size: $font-size-large !important;
            }

            &-larger {
                font-size: $font-size-larger !important;
            }

            &-xl {
                font-size: $font-size-extra-large !important;
            }

            &-xxl {
                font-size: $font-size-2x-large !important;
            }

            &-xxxl {
                font-size: $font-size-super-extra-large !important;
            }

            &-heading {
                font-size: $font-size-heading !important;
            }

            &-heading-l {
                font-size: $font-size-heading-large !important;
            }

            &-heading-xl {
                font-size: $font-size-heading-larger !important;
            }

            &-heading-xxl {
                font-size: $font-size-heading-largest !important;
            }
        }
    }
}
@include min-screen($screen-m) {
    .md {
        &-font-style {
            &-italic {
                font-style: italic !important;
            }
            &-normal {
                font-style: normal !important;
            }
            &-oblique {
                font-style: oblique !important;
            }
        }
        &-font-size {
            font-size: $font-size-base !important;

            &-xs {
                font-size: $font-size-extra-small !important;
            }

            &-s {
                font-size: $font-size-small !important;
            }

            &-m {
                font-size: $font-size-medium !important;
            }

            &-sl {
                font-size: $font-size-medium-large !important;
            }

            &-m {
                font-size: $font-size-medium !important;
            }

            &-large {
                font-size: $font-size-large !important;
            }

            &-larger {
                font-size: $font-size-larger !important;
            }

            &-xl {
                font-size: $font-size-extra-large !important;
            }

            &-xxl {
                font-size: $font-size-2x-large !important;
            }

            &-xxxl {
                font-size: $font-size-super-extra-large !important;
            }

            &-heading {
                font-size: $font-size-heading !important;
            }

            &-heading-l {
                font-size: $font-size-heading-large !important;
            }

            &-heading-xl {
                font-size: $font-size-heading-larger !important;
            }

            &-heading-xxl {
                font-size: $font-size-heading-largest !important;
            }
        }
    }
}
@include min-screen($screen-l) {
    .lg {
        &-font-style {
            &-italic {
                font-style: italic !important;
            }
            &-normal {
                font-style: normal !important;
            }
            &-oblique {
                font-style: oblique !important;
            }
        }
        &-font-size {
            font-size: $font-size-base !important;

            &-xs {
                font-size: $font-size-extra-small !important;
            }

            &-s {
                font-size: $font-size-small !important;
            }

            &-m {
                font-size: $font-size-medium !important;
            }

            &-sl {
                font-size: $font-size-medium-large !important;
            }

            &-m {
                font-size: $font-size-medium !important;
            }

            &-large {
                font-size: $font-size-large !important;
            }

            &-larger {
                font-size: $font-size-larger !important;
            }

            &-xl {
                font-size: $font-size-extra-large !important;
            }

            &-xxl {
                font-size: $font-size-2x-large !important;
            }

            &-xxxl {
                font-size: $font-size-super-extra-large !important;
            }

            &-heading {
                font-size: $font-size-heading !important;
            }

            &-heading-l {
                font-size: $font-size-heading-large !important;
            }

            &-heading-xl {
                font-size: $font-size-heading-larger !important;
            }

            &-heading-xxl {
                font-size: $font-size-heading-largest !important;
            }
        }
    }
}
@include min-screen($screen-xl) {
    .xl {
        &-font-style {
            &-italic {
                font-style: italic !important;
            }
            &-normal {
                font-style: normal !important;
            }
            &-oblique {
                font-style: oblique !important;
            }
        }
        &-font-size {
            font-size: $font-size-base !important;

            &-xs {
                font-size: $font-size-extra-small !important;
            }

            &-s {
                font-size: $font-size-small !important;
            }

            &-m {
                font-size: $font-size-medium !important;
            }

            &-sl {
                font-size: $font-size-medium-large !important;
            }

            &-m {
                font-size: $font-size-medium !important;
            }

            &-large {
                font-size: $font-size-large !important;
            }

            &-larger {
                font-size: $font-size-larger !important;
            }

            &-xl {
                font-size: $font-size-extra-large !important;
            }

            &-xxl {
                font-size: $font-size-2x-large !important;
            }

            &-xxxl {
                font-size: $font-size-super-extra-large !important;
            }

            &-heading {
                font-size: $font-size-heading !important;
            }

            &-heading-l {
                font-size: $font-size-heading-large !important;
            }

            &-heading-xl {
                font-size: $font-size-heading-larger !important;
            }

            &-heading-xxl {
                font-size: $font-size-heading-largest !important;
            }
        }
    }
}
@include min-screen($screen-2xl) {
    .x2l {
        &-font-style {
            &-italic {
                font-style: italic !important;
            }
            &-normal {
                font-style: normal !important;
            }
            &-oblique {
                font-style: oblique !important;
            }
        }
        &-font-size {
            font-size: $font-size-base !important;

            &-xs {
                font-size: $font-size-extra-small !important;
            }

            &-s {
                font-size: $font-size-small !important;
            }

            &-m {
                font-size: $font-size-medium !important;
            }

            &-sl {
                font-size: $font-size-medium-large !important;
            }

            &-m {
                font-size: $font-size-medium !important;
            }

            &-large {
                font-size: $font-size-large !important;
            }

            &-larger {
                font-size: $font-size-larger !important;
            }

            &-xl {
                font-size: $font-size-extra-large !important;
            }

            &-xxl {
                font-size: $font-size-2x-large !important;
            }

            &-xxxl {
                font-size: $font-size-super-extra-large !important;
            }

            &-heading {
                font-size: $font-size-heading !important;
            }

            &-heading-l {
                font-size: $font-size-heading-large !important;
            }

            &-heading-xl {
                font-size: $font-size-heading-larger !important;
            }

            &-heading-xxl {
                font-size: $font-size-heading-largest !important;
            }
        }
    }
}
@include min-screen($screen-xxl) {
    .xxl {
        &-font-style {
            &-italic {
                font-style: italic !important;
            }
            &-normal {
                font-style: normal !important;
            }
            &-oblique {
                font-style: oblique !important;
            }
        }
        &-font-size {
            font-size: $font-size-base !important;

            &-xs {
                font-size: $font-size-extra-small !important;
            }

            &-s {
                font-size: $font-size-small !important;
            }

            &-m {
                font-size: $font-size-medium !important;
            }

            &-sl {
                font-size: $font-size-medium-large !important;
            }

            &-m {
                font-size: $font-size-medium !important;
            }

            &-large {
                font-size: $font-size-large !important;
            }

            &-larger {
                font-size: $font-size-larger !important;
            }

            &-xl {
                font-size: $font-size-extra-large !important;
            }

            &-xxl {
                font-size: $font-size-2x-large !important;
            }

            &-xxxl {
                font-size: $font-size-super-extra-large !important;
            }

            &-heading {
                font-size: $font-size-heading !important;
            }

            &-heading-l {
                font-size: $font-size-heading-large !important;
            }

            &-heading-xl {
                font-size: $font-size-heading-larger !important;
            }

            &-heading-xxl {
                font-size: $font-size-heading-largest !important;
            }
        }
    }
}
@include min-screen($screen-3xl) {
    .x3l {
        &-font-style {
            &-italic {
                font-style: italic !important;
            }
            &-normal {
                font-style: normal !important;
            }
            &-oblique {
                font-style: oblique !important;
            }
        }
        &-font-size {
            font-size: $font-size-base !important;

            &-xs {
                font-size: $font-size-extra-small !important;
            }

            &-s {
                font-size: $font-size-small !important;
            }

            &-m {
                font-size: $font-size-medium !important;
            }

            &-sl {
                font-size: $font-size-medium-large !important;
            }

            &-m {
                font-size: $font-size-medium !important;
            }

            &-large {
                font-size: $font-size-large !important;
            }

            &-larger {
                font-size: $font-size-larger !important;
            }

            &-xl {
                font-size: $font-size-extra-large !important;
            }

            &-xxl {
                font-size: $font-size-2x-large !important;
            }

            &-xxxl {
                font-size: $font-size-super-extra-large !important;
            }

            &-heading {
                font-size: $font-size-heading !important;
            }

            &-heading-l {
                font-size: $font-size-heading-large !important;
            }

            &-heading-xl {
                font-size: $font-size-heading-larger !important;
            }

            &-heading-xxl {
                font-size: $font-size-heading-largest !important;
            }
        }
    }
}
@include min-screen($screen-xxxl) {
    .xxxl {
        &-font-style {
            &-italic {
                font-style: italic !important;
            }
            &-normal {
                font-style: normal !important;
            }
            &-oblique {
                font-style: oblique !important;
            }
        }
        &-font-size {
            font-size: $font-size-base !important;

            &-xs {
                font-size: $font-size-extra-small !important;
            }

            &-s {
                font-size: $font-size-small !important;
            }

            &-m {
                font-size: $font-size-medium !important;
            }

            &-sl {
                font-size: $font-size-medium-large !important;
            }

            &-m {
                font-size: $font-size-medium !important;
            }

            &-large {
                font-size: $font-size-large !important;
            }

            &-larger {
                font-size: $font-size-larger !important;
            }

            &-xl {
                font-size: $font-size-extra-large !important;
            }

            &-xxl {
                font-size: $font-size-2x-large !important;
            }

            &-xxxl {
                font-size: $font-size-super-extra-large !important;
            }

            &-heading {
                font-size: $font-size-heading !important;
            }

            &-heading-l {
                font-size: $font-size-heading-large !important;
            }

            &-heading-xl {
                font-size: $font-size-heading-larger !important;
            }

            &-heading-xxl {
                font-size: $font-size-heading-largest !important;
            }
        }
    }
}
@include min-screen($screen-4xl) {
    .x4l {
        &-font-style {
            &-italic {
                font-style: italic !important;
            }
            &-normal {
                font-style: normal !important;
            }
            &-oblique {
                font-style: oblique !important;
            }
        }
        &-font-size {
            font-size: $font-size-base !important;

            &-xs {
                font-size: $font-size-extra-small !important;
            }

            &-s {
                font-size: $font-size-small !important;
            }

            &-m {
                font-size: $font-size-medium !important;
            }

            &-sl {
                font-size: $font-size-medium-large !important;
            }

            &-m {
                font-size: $font-size-medium !important;
            }

            &-large {
                font-size: $font-size-large !important;
            }

            &-larger {
                font-size: $font-size-larger !important;
            }

            &-xl {
                font-size: $font-size-extra-large !important;
            }

            &-xxl {
                font-size: $font-size-2x-large !important;
            }

            &-xxxl {
                font-size: $font-size-super-extra-large !important;
            }

            &-heading {
                font-size: $font-size-heading !important;
            }

            &-heading-l {
                font-size: $font-size-heading-large !important;
            }

            &-heading-xl {
                font-size: $font-size-heading-larger !important;
            }

            &-heading-xxl {
                font-size: $font-size-heading-largest !important;
            }
        }
    }
}
//sass-lint:enable no-important
