//prevent document from scrolling
//sass-lint:disable no-important
.padding {
    &--0 {
        padding: 0 !important;
    }

    &-xs {
        padding: $spacer !important;
    }

    &-s {
        padding: $spacer--small !important;
    }

    &-m {
        padding: $spacer--medium !important;
    }

    &-sm {
        padding: $spacer--semi-medium !important;
    }

    &-l {
        padding: $spacer--large !important;
    }

    &-sl {
        padding: $spacer--semi-large !important;
    }

    &-xl {
        padding: $spacer--extra-large !important;
    }

    // padding-top
    &-top {
        &--0 {
            padding-top: 0 !important;
        }

        &-xs {
            padding-top: $spacer !important;
        }

        &-s {
            padding-top: $spacer--small !important;
        }

        &-m {
            padding-top: $spacer--medium !important;
        }

        &-sm {
            padding-top: $spacer--semi-medium !important;
        }

        &-l {
            padding-top: $spacer--large !important;
        }

        &-sl {
            padding-top: $spacer--semi-large !important;
        }

        &-xl {
            padding-top: $spacer--extra-large !important;
        }

        &-xxl {
            padding-top: $spacer--extra-extra-large !important;
        }
    }

    // padding-bottom
    &-bottom {
        &--0 {
            padding-bottom: 0 !important;
        }

        &-xs {
            padding-bottom: $spacer !important;
        }

        &-s {
            padding-bottom: $spacer--small !important;
        }

        &-m {
            padding-bottom: $spacer--medium !important;
        }

        &-sm {
            padding-bottom: $spacer--semi-medium !important;
        }

        &-l {
            padding-bottom: $spacer--large !important;
        }

        &-sl {
            padding-bottom: $spacer--semi-large !important;
        }

        &-xl {
            padding-bottom: $spacer--extra-large !important;
        }

        &-xxl {
            padding-bottom: $spacer--extra-extra-large !important;
        }
    }

    // padding-left
    &-left {
        &--0 {
            padding-left: 0 !important;
        }

        &-xs {
            padding-left: $spacer !important;
        }

        &-s {
            padding-left: $spacer--small !important;
        }

        &-m {
            padding-left: $spacer--medium !important;
        }

        &-sm {
            padding-left: $spacer--semi-medium !important;
        }

        &-l {
            padding-left: $spacer--large !important;
        }

        &-sl {
            padding-left: $spacer--semi-large !important;
        }

        &-xl {
            padding-left: $spacer--extra-large !important;
        }

        &-xxl {
            padding-left: $spacer--extra-extra-large !important;
        }
    }

    // padding-right
    &-right {
        &--0 {
            padding-right: 0 !important;
        }

        &-xs {
            padding-right: $spacer !important;
        }

        &-s {
            padding-right: $spacer--small !important;
        }

        &-m {
            padding-right: $spacer--medium !important;
        }

        &-sm {
            padding-right: $spacer--semi-medium !important;
        }

        &-l {
            padding-right: $spacer--large !important;
        }

        &-sl {
            padding-right: $spacer--semi-large !important;
        }

        &-xl {
            padding-right: $spacer--extra-large !important;
        }

        &-xxl {
            padding-right: $spacer--extra-extra-large !important;
        }
    }

    // padding horizontal
    &-hz {
        &--0 {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }

        &-xs {
            padding-left: $spacer !important;
            padding-right: $spacer !important;
        }

        &-s {
            padding-left: $spacer--small !important;
            padding-right: $spacer--small !important;
        }

        &-m {
            padding-left: $spacer--medium !important;
            padding-right: $spacer--medium !important;
        }

        &-sm {
            padding-left: $spacer--semi-medium !important;
            padding-right: $spacer--semi-medium !important;
        }

        &-l {
            padding-left: $spacer--large !important;
            padding-right: $spacer--large !important;
        }

        &-sl {
            padding-left: $spacer--semi-large !important;
            padding-right: $spacer--semi-large !important;
        }

        &-xl {
            padding-left: $spacer--extra-large !important;
            padding-right: $spacer--extra-large !important;
        }

        &-xxl {
            padding-left: $spacer--extra-extra-large !important;
            padding-right: $spacer--extra-extra-large !important;
        }
    }

    // padding vertical
    &-vc {
        &--0 {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }

        &-xs {
            padding-top: $spacer !important;
            padding-bottom: $spacer !important;
        }

        &-s {
            padding-top: $spacer--small !important;
            padding-bottom: $spacer--small !important;
        }

        &-m {
            padding-top: $spacer--medium !important;
            padding-bottom: $spacer--medium !important;
        }

        &-sm {
            padding-top: $spacer--semi-medium !important;
            padding-bottom: $spacer--semi-medium !important;
        }

        &-l {
            padding-top: $spacer--large !important;
            padding-bottom: $spacer--large !important;
        }

        &-sl {
            padding-top: $spacer--semi-large !important;
            padding-bottom: $spacer--semi-large !important;
        }

        &-xl {
            padding-top: $spacer--extra-large !important;
            padding-bottom: $spacer--extra-large !important;
        }

        &-xxl {
            padding-top: $spacer--extra-extra-large !important;
            padding-bottom: $spacer--extra-extra-large !important;
        }
    }
}
@include max-screen($screen-m - 1) {
    .xs-padding {
        &--0 {
            padding: 0 !important;
        }

        &-xs {
            padding: $spacer !important;
        }

        &-s {
            padding: $spacer--small !important;
        }

        &-m {
            padding: $spacer--medium !important;
        }

        &-sm {
            padding: $spacer--semi-medium !important;
        }

        &-l {
            padding: $spacer--large !important;
        }

        &-sl {
            padding: $spacer--semi-large !important;
        }

        &-xl {
            padding: $spacer--extra-large !important;
        }

        // padding-top
        &-top {
            &--0 {
                padding-top: 0 !important;
            }

            &-xs {
                padding-top: $spacer !important;
            }

            &-s {
                padding-top: $spacer--small !important;
            }

            &-m {
                padding-top: $spacer--medium !important;
            }

            &-sm {
                padding-top: $spacer--semi-medium !important;
            }

            &-l {
                padding-top: $spacer--large !important;
            }

            &-sl {
                padding-top: $spacer--semi-large !important;
            }

            &-xl {
                padding-top: $spacer--extra-large !important;
            }

            &-xxl {
                padding-top: $spacer--extra-extra-large !important;
            }
        }

        // padding-bottom
        &-bottom {
            &--0 {
                padding-bottom: 0 !important;
            }

            &-xs {
                padding-bottom: $spacer !important;
            }

            &-s {
                padding-bottom: $spacer--small !important;
            }

            &-m {
                padding-bottom: $spacer--medium !important;
            }

            &-sm {
                padding-bottom: $spacer--semi-medium !important;
            }

            &-l {
                padding-bottom: $spacer--large !important;
            }

            &-sl {
                padding-bottom: $spacer--semi-large !important;
            }

            &-xl {
                padding-bottom: $spacer--extra-large !important;
            }

            &-xxl {
                padding-bottom: $spacer--extra-extra-large !important;
            }
        }

        // padding-left
        &-left {
            &--0 {
                padding-left: 0 !important;
            }

            &-xs {
                padding-left: $spacer !important;
            }

            &-s {
                padding-left: $spacer--small !important;
            }

            &-m {
                padding-left: $spacer--medium !important;
            }

            &-sm {
                padding-left: $spacer--semi-medium !important;
            }

            &-l {
                padding-left: $spacer--large !important;
            }

            &-sl {
                padding-left: $spacer--semi-large !important;
            }

            &-xl {
                padding-left: $spacer--extra-large !important;
            }

            &-xxl {
                padding-left: $spacer--extra-extra-large !important;
            }
        }

        // padding-right
        &-right {
            &--0 {
                padding-right: 0 !important;
            }

            &-xs {
                padding-right: $spacer !important;
            }

            &-s {
                padding-right: $spacer--small !important;
            }

            &-m {
                padding-right: $spacer--medium !important;
            }

            &-sm {
                padding-right: $spacer--semi-medium !important;
            }

            &-l {
                padding-right: $spacer--large !important;
            }

            &-sl {
                padding-right: $spacer--semi-large !important;
            }

            &-xl {
                padding-right: $spacer--extra-large !important;
            }

            &-xxl {
                padding-right: $spacer--extra-extra-large !important;
            }
        }

        // padding horizontal
        &-hz {
            &--0 {
                padding-left: 0 !important;
                padding-right: 0 !important;
            }

            &-xs {
                padding-left: $spacer !important;
                padding-right: $spacer !important;
            }

            &-s {
                padding-left: $spacer--small !important;
                padding-right: $spacer--small !important;
            }

            &-m {
                padding-left: $spacer--medium !important;
                padding-right: $spacer--medium !important;
            }

            &-sm {
                padding-left: $spacer--semi-medium !important;
                padding-right: $spacer--semi-medium !important;
            }

            &-l {
                padding-left: $spacer--large !important;
                padding-right: $spacer--large !important;
            }

            &-sl {
                padding-left: $spacer--semi-large !important;
                padding-right: $spacer--semi-large !important;
            }

            &-xl {
                padding-left: $spacer--extra-large !important;
                padding-right: $spacer--extra-large !important;
            }

            &-xxl {
                padding-left: $spacer--extra-extra-large !important;
                padding-right: $spacer--extra-extra-large !important;
            }
        }

        // padding vertical
        &-vc {
            &--0 {
                padding-top: 0 !important;
                padding-bottom: 0 !important;
            }

            &-xs {
                padding-top: $spacer !important;
                padding-bottom: $spacer !important;
            }

            &-s {
                padding-top: $spacer--small !important;
                padding-bottom: $spacer--small !important;
            }

            &-m {
                padding-top: $spacer--medium !important;
                padding-bottom: $spacer--medium !important;
            }

            &-sm {
                padding-top: $spacer--semi-medium !important;
                padding-bottom: $spacer--semi-medium !important;
            }

            &-l {
                padding-top: $spacer--large !important;
                padding-bottom: $spacer--large !important;
            }

            &-sl {
                padding-top: $spacer--semi-large !important;
                padding-bottom: $spacer--semi-large !important;
            }

            &-xl {
                padding-top: $spacer--extra-large !important;
                padding-bottom: $spacer--extra-large !important;
            }

            &-xxl {
                padding-top: $spacer--extra-extra-large !important;
                padding-bottom: $spacer--extra-extra-large !important;
            }
        }
    }
}
@include min-screen($screen-m) {
    .md-padding {
        &--0 {
            padding: 0 !important;
        }

        &-xs {
            padding: $spacer !important;
        }

        &-s {
            padding: $spacer--small !important;
        }

        &-m {
            padding: $spacer--medium !important;
        }

        &-sm {
            padding: $spacer--semi-medium !important;
        }

        &-l {
            padding: $spacer--large !important;
        }

        &-sl {
            padding: $spacer--semi-large !important;
        }

        &-xl {
            padding: $spacer--extra-large !important;
        }

        // padding-top
        &-top {
            &--0 {
                padding-top: 0 !important;
            }

            &-xs {
                padding-top: $spacer !important;
            }

            &-s {
                padding-top: $spacer--small !important;
            }

            &-m {
                padding-top: $spacer--medium !important;
            }

            &-sm {
                padding-top: $spacer--semi-medium !important;
            }

            &-l {
                padding-top: $spacer--large !important;
            }

            &-sl {
                padding-top: $spacer--semi-large !important;
            }

            &-xl {
                padding-top: $spacer--extra-large !important;
            }

            &-xxl {
                padding-top: $spacer--extra-extra-large !important;
            }
        }

        // padding-bottom
        &-bottom {
            &--0 {
                padding-bottom: 0 !important;
            }

            &-xs {
                padding-bottom: $spacer !important;
            }

            &-s {
                padding-bottom: $spacer--small !important;
            }

            &-m {
                padding-bottom: $spacer--medium !important;
            }

            &-sm {
                padding-bottom: $spacer--semi-medium !important;
            }

            &-l {
                padding-bottom: $spacer--large !important;
            }

            &-sl {
                padding-bottom: $spacer--semi-large !important;
            }

            &-xl {
                padding-bottom: $spacer--extra-large !important;
            }

            &-xxl {
                padding-bottom: $spacer--extra-extra-large !important;
            }
        }

        // padding-left
        &-left {
            &--0 {
                padding-left: 0 !important;
            }

            &-xs {
                padding-left: $spacer !important;
            }

            &-s {
                padding-left: $spacer--small !important;
            }

            &-m {
                padding-left: $spacer--medium !important;
            }

            &-sm {
                padding-left: $spacer--semi-medium !important;
            }

            &-l {
                padding-left: $spacer--large !important;
            }

            &-sl {
                padding-left: $spacer--semi-large !important;
            }

            &-xl {
                padding-left: $spacer--extra-large !important;
            }

            &-xxl {
                padding-left: $spacer--extra-extra-large !important;
            }
        }

        // padding-right
        &-right {
            &--0 {
                padding-right: 0 !important;
            }

            &-xs {
                padding-right: $spacer !important;
            }

            &-s {
                padding-right: $spacer--small !important;
            }

            &-m {
                padding-right: $spacer--medium !important;
            }

            &-sm {
                padding-right: $spacer--semi-medium !important;
            }

            &-l {
                padding-right: $spacer--large !important;
            }

            &-sl {
                padding-right: $spacer--semi-large !important;
            }

            &-xl {
                padding-right: $spacer--extra-large !important;
            }

            &-xxl {
                padding-right: $spacer--extra-extra-large !important;
            }
        }

        // padding horizontal
        &-hz {
            &--0 {
                padding-left: 0 !important;
                padding-right: 0 !important;
            }

            &-xs {
                padding-left: $spacer !important;
                padding-right: $spacer !important;
            }

            &-s {
                padding-left: $spacer--small !important;
                padding-right: $spacer--small !important;
            }

            &-m {
                padding-left: $spacer--medium !important;
                padding-right: $spacer--medium !important;
            }

            &-sm {
                padding-left: $spacer--semi-medium !important;
                padding-right: $spacer--semi-medium !important;
            }

            &-l {
                padding-left: $spacer--large !important;
                padding-right: $spacer--large !important;
            }

            &-sl {
                padding-left: $spacer--semi-large !important;
                padding-right: $spacer--semi-large !important;
            }

            &-xl {
                padding-left: $spacer--extra-large !important;
                padding-right: $spacer--extra-large !important;
            }

            &-xxl {
                padding-left: $spacer--extra-extra-large !important;
                padding-right: $spacer--extra-extra-large !important;
            }
        }

        // padding vertical
        &-vc {
            &--0 {
                padding-top: 0 !important;
                padding-bottom: 0 !important;
            }

            &-xs {
                padding-top: $spacer !important;
                padding-bottom: $spacer !important;
            }

            &-s {
                padding-top: $spacer--small !important;
                padding-bottom: $spacer--small !important;
            }

            &-m {
                padding-top: $spacer--medium !important;
                padding-bottom: $spacer--medium !important;
            }

            &-sm {
                padding-top: $spacer--semi-medium !important;
                padding-bottom: $spacer--semi-medium !important;
            }

            &-l {
                padding-top: $spacer--large !important;
                padding-bottom: $spacer--large !important;
            }

            &-sl {
                padding-top: $spacer--semi-large !important;
                padding-bottom: $spacer--semi-large !important;
            }

            &-xl {
                padding-top: $spacer--extra-large !important;
                padding-bottom: $spacer--extra-large !important;
            }

            &-xxl {
                padding-top: $spacer--extra-extra-large !important;
                padding-bottom: $spacer--extra-extra-large !important;
            }
        }
    }
}
@include min-screen($screen-l) {
    .lg-padding {
        &--0 {
            padding: 0 !important;
        }

        &-xs {
            padding: $spacer !important;
        }

        &-s {
            padding: $spacer--small !important;
        }

        &-m {
            padding: $spacer--medium !important;
        }

        &-sm {
            padding: $spacer--semi-medium !important;
        }

        &-l {
            padding: $spacer--large !important;
        }

        &-sl {
            padding: $spacer--semi-large !important;
        }

        &-xl {
            padding: $spacer--extra-large !important;
        }

        // padding-top
        &-top {
            &--0 {
                padding-top: 0 !important;
            }

            &-xs {
                padding-top: $spacer !important;
            }

            &-s {
                padding-top: $spacer--small !important;
            }

            &-m {
                padding-top: $spacer--medium !important;
            }

            &-sm {
                padding-top: $spacer--semi-medium !important;
            }

            &-l {
                padding-top: $spacer--large !important;
            }

            &-sl {
                padding-top: $spacer--semi-large !important;
            }

            &-xl {
                padding-top: $spacer--extra-large !important;
            }

            &-xxl {
                padding-top: $spacer--extra-extra-large !important;
            }
        }

        // padding-bottom
        &-bottom {
            &--0 {
                padding-bottom: 0 !important;
            }

            &-xs {
                padding-bottom: $spacer !important;
            }

            &-s {
                padding-bottom: $spacer--small !important;
            }

            &-m {
                padding-bottom: $spacer--medium !important;
            }

            &-sm {
                padding-bottom: $spacer--semi-medium !important;
            }

            &-l {
                padding-bottom: $spacer--large !important;
            }

            &-sl {
                padding-bottom: $spacer--semi-large !important;
            }

            &-xl {
                padding-bottom: $spacer--extra-large !important;
            }

            &-xxl {
                padding-bottom: $spacer--extra-extra-large !important;
            }
        }

        // padding-left
        &-left {
            &--0 {
                padding-left: 0 !important;
            }

            &-xs {
                padding-left: $spacer !important;
            }

            &-s {
                padding-left: $spacer--small !important;
            }

            &-m {
                padding-left: $spacer--medium !important;
            }

            &-sm {
                padding-left: $spacer--semi-medium !important;
            }

            &-l {
                padding-left: $spacer--large !important;
            }

            &-sl {
                padding-left: $spacer--semi-large !important;
            }

            &-xl {
                padding-left: $spacer--extra-large !important;
            }

            &-xxl {
                padding-left: $spacer--extra-extra-large !important;
            }
        }

        // padding-right
        &-right {
            &--0 {
                padding-right: 0 !important;
            }

            &-xs {
                padding-right: $spacer !important;
            }

            &-s {
                padding-right: $spacer--small !important;
            }

            &-m {
                padding-right: $spacer--medium !important;
            }

            &-sm {
                padding-right: $spacer--semi-medium !important;
            }

            &-l {
                padding-right: $spacer--large !important;
            }

            &-sl {
                padding-right: $spacer--semi-large !important;
            }

            &-xl {
                padding-right: $spacer--extra-large !important;
            }

            &-xxl {
                padding-right: $spacer--extra-extra-large !important;
            }
        }

        // padding horizontal
        &-hz {
            &--0 {
                padding-left: 0 !important;
                padding-right: 0 !important;
            }

            &-xs {
                padding-left: $spacer !important;
                padding-right: $spacer !important;
            }

            &-s {
                padding-left: $spacer--small !important;
                padding-right: $spacer--small !important;
            }

            &-m {
                padding-left: $spacer--medium !important;
                padding-right: $spacer--medium !important;
            }

            &-sm {
                padding-left: $spacer--semi-medium !important;
                padding-right: $spacer--semi-medium !important;
            }

            &-l {
                padding-left: $spacer--large !important;
                padding-right: $spacer--large !important;
            }

            &-sl {
                padding-left: $spacer--semi-large !important;
                padding-right: $spacer--semi-large !important;
            }

            &-xl {
                padding-left: $spacer--extra-large !important;
                padding-right: $spacer--extra-large !important;
            }

            &-xxl {
                padding-left: $spacer--extra-extra-large !important;
                padding-right: $spacer--extra-extra-large !important;
            }
        }

        // padding vertical
        &-vc {
            &--0 {
                padding-top: 0 !important;
                padding-bottom: 0 !important;
            }

            &-xs {
                padding-top: $spacer !important;
                padding-bottom: $spacer !important;
            }

            &-s {
                padding-top: $spacer--small !important;
                padding-bottom: $spacer--small !important;
            }

            &-m {
                padding-top: $spacer--medium !important;
                padding-bottom: $spacer--medium !important;
            }

            &-sm {
                padding-top: $spacer--semi-medium !important;
                padding-bottom: $spacer--semi-medium !important;
            }

            &-l {
                padding-top: $spacer--large !important;
                padding-bottom: $spacer--large !important;
            }

            &-sl {
                padding-top: $spacer--semi-large !important;
                padding-bottom: $spacer--semi-large !important;
            }

            &-xl {
                padding-top: $spacer--extra-large !important;
                padding-bottom: $spacer--extra-large !important;
            }

            &-xxl {
                padding-top: $spacer--extra-extra-large !important;
                padding-bottom: $spacer--extra-extra-large !important;
            }
        }
    }
}
.p {
    &-0 {
        padding: 0 !important;
    }
    &-1 {
        padding: $spacer--extra-small !important;
    }
    &-2 {
        padding: $spacer !important;
    }
    &-3 {
        padding: $spacer--small !important;
    }
    &-4 {
        padding: $spacer--medium !important;
    }
    &-5 {
        padding: $spacer--m !important;
    }
    &-6 {
        padding: $spacer--semi-medium !important;
    }
    &-7 {
        padding: $spacer--large !important;
    }
    &-8 {
        padding: $spacer--l !important;
    }
    &-9 {
        padding: $spacer--semi-large !important;
    }
    &-10 {
        padding: $spacer--xl !important;
    }
    &-11 {
        padding: $spacer--extra-large !important;
    }
    &-12 {
        padding: $spacer--xxl !important;
    }
    &-13 {
        padding: $spacer--extra-extra-large !important;
    }
    &-14 {
        padding: $spacer--larger !important;
    }
    &-vc {
        &-0 {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }
        &-1 {
            padding-top: $spacer--extra-small !important;
            padding-bottom: $spacer--extra-small !important;
        }
        &-2 {
            padding-top: $spacer !important;
            padding-bottom: $spacer !important;
        }
        &-3 {
            padding-top: $spacer--small !important;
            padding-bottom: $spacer--small !important;
        }
        &-4 {
            padding-top: $spacer--medium !important;
            padding-bottom: $spacer--medium !important;
        }
        &-5 {
            padding-top: $spacer--m !important;
            padding-bottom: $spacer--m !important;
        }
        &-6 {
            padding-top: $spacer--semi-medium !important;
            padding-bottom: $spacer--semi-medium !important;
        }
        &-7 {
            padding-top: $spacer--large !important;
            padding-bottom: $spacer--large !important;
        }
        &-8 {
            padding-top: $spacer--l !important;
            padding-bottom: $spacer--l !important;
        }
        &-9 {
            padding-top: $spacer--semi-large !important;
            padding-bottom: $spacer--semi-large !important;
        }
        &-10 {
            padding-top: $spacer--xl !important;
            padding-bottom: $spacer--xl !important;
        }
        &-11 {
            padding-top: $spacer--extra-large !important;
            padding-bottom: $spacer--extra-large !important;
        }
        &-12 {
            padding-top: $spacer--xxl !important;
            padding-bottom: $spacer--xxl !important;
        }
        &-13 {
            padding-top: $spacer--extra-extra-large !important;
            padding-bottom: $spacer--extra-extra-large !important;
        }
        &-14 {
            padding-top: $spacer--larger !important;
            padding-bottom: $spacer--larger !important;
        }
    }
    &-hz {
        &-0 {
            padding-right: 0 !important;
            padding-left: 0 !important;
        }
        &-1 {
            padding-right: $spacer--extra-small !important;
            padding-left: $spacer--extra-small !important;
        }
        &-2 {
            padding-right: $spacer !important;
            padding-left: $spacer !important;
        }
        &-3 {
            padding-right: $spacer--small !important;
            padding-left: $spacer--small !important;
        }
        &-4 {
            padding-right: $spacer--medium !important;
            padding-left: $spacer--medium !important;
        }
        &-5 {
            padding-right: $spacer--m !important;
            padding-left: $spacer--m !important;
        }
        &-6 {
            padding-right: $spacer--semi-medium !important;
            padding-left: $spacer--semi-medium !important;
        }
        &-7 {
            padding-right: $spacer--large !important;
            padding-left: $spacer--large !important;
        }
        &-8 {
            padding-right: $spacer--l !important;
            padding-left: $spacer--l !important;
        }
        &-9 {
            padding-right: $spacer--semi-large !important;
            padding-left: $spacer--semi-large !important;
        }
        &-10 {
            padding-right: $spacer--xl !important;
            padding-left: $spacer--xl !important;
        }
        &-11 {
            padding-right: $spacer--extra-large !important;
            padding-left: $spacer--extra-large !important;
        }
        &-12 {
            padding-right: $spacer--xxl !important;
            padding-left: $spacer--xxl !important;
        }
        &-13 {
            padding-right: $spacer--extra-extra-large !important;
            padding-left: $spacer--extra-extra-large !important;
        }
        &-14 {
            padding-right: $spacer--larger !important;
            padding-left: $spacer--larger !important;
        }
    }
    &t {
        &-0 {
            padding-top: 0 !important;
        }
        &-1 {
            padding-top: $spacer--extra-small !important;
        }
        &-2 {
            padding-top: $spacer !important;
        }
        &-3 {
            padding-top: $spacer--small !important;
        }
        &-4 {
            padding-top: $spacer--medium !important;
        }
        &-5 {
            padding-top: $spacer--m !important;
        }
        &-6 {
            padding-top: $spacer--semi-medium !important;
        }
        &-7 {
            padding-top: $spacer--large !important;
        }
        &-8 {
            padding-top: $spacer--l !important;
        }
        &-9 {
            padding-top: $spacer--semi-large !important;
        }
        &-10 {
            padding-top: $spacer--xl !important;
        }
        &-11 {
            padding-top: $spacer--extra-large !important;
        }
        &-12 {
            padding-top: $spacer--xxl !important;
        }
        &-13 {
            padding-top: $spacer--extra-extra-large !important;
        }
        &-14 {
            padding-top: $spacer--larger !important;
        }
    }
    &r {
        &-0 {
            padding-right: 0 !important;
        }
        &-1 {
            padding-right: $spacer--extra-small !important;
        }
        &-2 {
            padding-right: $spacer !important;
        }
        &-3 {
            padding-right: $spacer--small !important;
        }
        &-4 {
            padding-right: $spacer--medium !important;
        }
        &-5 {
            padding-right: $spacer--m !important;
        }
        &-6 {
            padding-right: $spacer--semi-medium !important;
        }
        &-7 {
            padding-right: $spacer--large !important;
        }
        &-8 {
            padding-right: $spacer--l !important;
        }
        &-9 {
            padding-right: $spacer--semi-large !important;
        }
        &-10 {
            padding-right: $spacer--xl !important;
        }
        &-11 {
            padding-right: $spacer--extra-large !important;
        }
        &-12 {
            padding-right: $spacer--xxl !important;
        }
        &-13 {
            padding-right: $spacer--extra-extra-large !important;
        }
        &-14 {
            padding-right: $spacer--larger !important;
        }
    }
    &b {
        &-0 {
            padding-bottom: 0 !important;
        }
        &-1 {
            padding-bottom: $spacer--extra-small !important;
        }
        &-2 {
            padding-bottom: $spacer !important;
        }
        &-3 {
            padding-bottom: $spacer--small !important;
        }
        &-4 {
            padding-bottom: $spacer--medium !important;
        }
        &-5 {
            padding-bottom: $spacer--m !important;
        }
        &-6 {
            padding-bottom: $spacer--semi-medium !important;
        }
        &-7 {
            padding-bottom: $spacer--large !important;
        }
        &-8 {
            padding-bottom: $spacer--l !important;
        }
        &-9 {
            padding-bottom: $spacer--semi-large !important;
        }
        &-10 {
            padding-bottom: $spacer--xl !important;
        }
        &-11 {
            padding-bottom: $spacer--extra-large !important;
        }
        &-12 {
            padding-bottom: $spacer--xxl !important;
        }
        &-13 {
            padding-bottom: $spacer--extra-extra-large !important;
        }
        &-14 {
            padding-bottom: $spacer--larger !important;
        }
    }
    &l {
        &-0 {
            padding-left: 0 !important;
        }
        &-1 {
            padding-left: $spacer--extra-small !important;
        }
        &-2 {
            padding-left: $spacer !important;
        }
        &-3 {
            padding-left: $spacer--small !important;
        }
        &-4 {
            padding-left: $spacer--medium !important;
        }
        &-5 {
            padding-left: $spacer--m !important;
        }
        &-6 {
            padding-left: $spacer--semi-medium !important;
        }
        &-7 {
            padding-left: $spacer--large !important;
        }
        &-8 {
            padding-left: $spacer--l !important;
        }
        &-9 {
            padding-left: $spacer--semi-large !important;
        }
        &-10 {
            padding-left: $spacer--xl !important;
        }
        &-11 {
            padding-left: $spacer--extra-large !important;
        }
        &-12 {
            padding-left: $spacer--xxl !important;
        }
        &-13 {
            padding-left: $spacer--extra-extra-large !important;
        }
        &-14 {
            padding-left: $spacer--larger !important;
        }
    }
}
@media screen and (max-width: $screen-m) {
    .xs {
        &-p {
            &-0 {
                padding: 0 !important;
            }
            &-1 {
                padding: $spacer--extra-small !important;
            }
            &-2 {
                padding: $spacer !important;
            }
            &-3 {
                padding: $spacer--small !important;
            }
            &-4 {
                padding: $spacer--medium !important;
            }
            &-5 {
                padding: $spacer--m !important;
            }
            &-6 {
                padding: $spacer--semi-medium !important;
            }
            &-7 {
                padding: $spacer--large !important;
            }
            &-8 {
                padding: $spacer--l !important;
            }
            &-9 {
                padding: $spacer--semi-large !important;
            }
            &-10 {
                padding: $spacer--xl !important;
            }
            &-11 {
                padding: $spacer--extra-large !important;
            }
            &-12 {
                padding: $spacer--xxl !important;
            }
            &-13 {
                padding: $spacer--extra-extra-large !important;
            }
            &-14 {
                padding: $spacer--larger !important;
            }
            &-vc {
                &-0 {
                    padding-top: 0 !important;
                    padding-bottom: 0 !important;
                }
                &-1 {
                    padding-top: $spacer--extra-small !important;
                    padding-bottom: $spacer--extra-small !important;
                }
                &-2 {
                    padding-top: $spacer !important;
                    padding-bottom: $spacer !important;
                }
                &-3 {
                    padding-top: $spacer--small !important;
                    padding-bottom: $spacer--small !important;
                }
                &-4 {
                    padding-top: $spacer--medium !important;
                    padding-bottom: $spacer--medium !important;
                }
                &-5 {
                    padding-top: $spacer--m !important;
                    padding-bottom: $spacer--m !important;
                }
                &-6 {
                    padding-top: $spacer--semi-medium !important;
                    padding-bottom: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-top: $spacer--large !important;
                    padding-bottom: $spacer--large !important;
                }
                &-8 {
                    padding-top: $spacer--l !important;
                    padding-bottom: $spacer--l !important;
                }
                &-9 {
                    padding-top: $spacer--semi-large !important;
                    padding-bottom: $spacer--semi-large !important;
                }
                &-10 {
                    padding-top: $spacer--xl !important;
                    padding-bottom: $spacer--xl !important;
                }
                &-11 {
                    padding-top: $spacer--extra-large !important;
                    padding-bottom: $spacer--extra-large !important;
                }
                &-12 {
                    padding-top: $spacer--xxl !important;
                    padding-bottom: $spacer--xxl !important;
                }
                &-13 {
                    padding-top: $spacer--extra-extra-large !important;
                    padding-bottom: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-top: $spacer--larger !important;
                    padding-bottom: $spacer--larger !important;
                }
            }
            &-hz {
                &-0 {
                    padding-right: 0 !important;
                    padding-left: 0 !important;
                }
                &-1 {
                    padding-right: $spacer--extra-small !important;
                    padding-left: $spacer--extra-small !important;
                }
                &-2 {
                    padding-right: $spacer !important;
                    padding-left: $spacer !important;
                }
                &-3 {
                    padding-right: $spacer--small !important;
                    padding-left: $spacer--small !important;
                }
                &-4 {
                    padding-right: $spacer--medium !important;
                    padding-left: $spacer--medium !important;
                }
                &-5 {
                    padding-right: $spacer--m !important;
                    padding-left: $spacer--m !important;
                }
                &-6 {
                    padding-right: $spacer--semi-medium !important;
                    padding-left: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-right: $spacer--large !important;
                    padding-left: $spacer--large !important;
                }
                &-8 {
                    padding-right: $spacer--l !important;
                    padding-left: $spacer--l !important;
                }
                &-9 {
                    padding-right: $spacer--semi-large !important;
                    padding-left: $spacer--semi-large !important;
                }
                &-10 {
                    padding-right: $spacer--xl !important;
                    padding-left: $spacer--xl !important;
                }
                &-11 {
                    padding-right: $spacer--extra-large !important;
                    padding-left: $spacer--extra-large !important;
                }
                &-12 {
                    padding-right: $spacer--xxl !important;
                    padding-left: $spacer--xxl !important;
                }
                &-13 {
                    padding-right: $spacer--extra-extra-large !important;
                    padding-left: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-right: $spacer--larger !important;
                    padding-left: $spacer--larger !important;
                }
            }
            &t {
                &-0 {
                    padding-top: 0 !important;
                }
                &-1 {
                    padding-top: $spacer--extra-small !important;
                }
                &-2 {
                    padding-top: $spacer !important;
                }
                &-3 {
                    padding-top: $spacer--small !important;
                }
                &-4 {
                    padding-top: $spacer--medium !important;
                }
                &-5 {
                    padding-top: $spacer--m !important;
                }
                &-6 {
                    padding-top: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-top: $spacer--large !important;
                }
                &-8 {
                    padding-top: $spacer--l !important;
                }
                &-9 {
                    padding-top: $spacer--semi-large !important;
                }
                &-10 {
                    padding-top: $spacer--xl !important;
                }
                &-11 {
                    padding-top: $spacer--extra-large !important;
                }
                &-12 {
                    padding-top: $spacer--xxl !important;
                }
                &-13 {
                    padding-top: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-top: $spacer--larger !important;
                }
            }
            &r {
                &-0 {
                    padding-right: 0 !important;
                }
                &-1 {
                    padding-right: $spacer--extra-small !important;
                }
                &-2 {
                    padding-right: $spacer !important;
                }
                &-3 {
                    padding-right: $spacer--small !important;
                }
                &-4 {
                    padding-right: $spacer--medium !important;
                }
                &-5 {
                    padding-right: $spacer--m !important;
                }
                &-6 {
                    padding-right: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-right: $spacer--large !important;
                }
                &-8 {
                    padding-right: $spacer--l !important;
                }
                &-9 {
                    padding-right: $spacer--semi-large !important;
                }
                &-10 {
                    padding-right: $spacer--xl !important;
                }
                &-11 {
                    padding-right: $spacer--extra-large !important;
                }
                &-12 {
                    padding-right: $spacer--xxl !important;
                }
                &-13 {
                    padding-right: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-right: $spacer--larger !important;
                }
            }
            &b {
                &-0 {
                    padding-bottom: 0 !important;
                }
                &-1 {
                    padding-bottom: $spacer--extra-small !important;
                }
                &-2 {
                    padding-bottom: $spacer !important;
                }
                &-3 {
                    padding-bottom: $spacer--small !important;
                }
                &-4 {
                    padding-bottom: $spacer--medium !important;
                }
                &-5 {
                    padding-bottom: $spacer--m !important;
                }
                &-6 {
                    padding-bottom: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-bottom: $spacer--large !important;
                }
                &-8 {
                    padding-bottom: $spacer--l !important;
                }
                &-9 {
                    padding-bottom: $spacer--semi-large !important;
                }
                &-10 {
                    padding-bottom: $spacer--xl !important;
                }
                &-11 {
                    padding-bottom: $spacer--extra-large !important;
                }
                &-12 {
                    padding-bottom: $spacer--xxl !important;
                }
                &-13 {
                    padding-bottom: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-bottom: $spacer--larger !important;
                }
            }
            &l {
                &-0 {
                    padding-left: 0 !important;
                }
                &-1 {
                    padding-left: $spacer--extra-small !important;
                }
                &-2 {
                    padding-left: $spacer !important;
                }
                &-3 {
                    padding-left: $spacer--small !important;
                }
                &-4 {
                    padding-left: $spacer--medium !important;
                }
                &-5 {
                    padding-left: $spacer--m !important;
                }
                &-6 {
                    padding-left: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-left: $spacer--large !important;
                }
                &-8 {
                    padding-left: $spacer--l !important;
                }
                &-9 {
                    padding-left: $spacer--semi-large !important;
                }
                &-10 {
                    padding-left: $spacer--xl !important;
                }
                &-11 {
                    padding-left: $spacer--extra-large !important;
                }
                &-12 {
                    padding-left: $spacer--xxl !important;
                }
                &-13 {
                    padding-left: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-left: $spacer--larger !important;
                }
            }
        }
    }
}
@include min-screen($screen-m) {
    .md {
        &-p {
            &-0 {
                padding: 0 !important;
            }
            &-1 {
                padding: $spacer--extra-small !important;
            }
            &-2 {
                padding: $spacer !important;
            }
            &-3 {
                padding: $spacer--small !important;
            }
            &-4 {
                padding: $spacer--medium !important;
            }
            &-5 {
                padding: $spacer--m !important;
            }
            &-6 {
                padding: $spacer--semi-medium !important;
            }
            &-7 {
                padding: $spacer--large !important;
            }
            &-8 {
                padding: $spacer--l !important;
            }
            &-9 {
                padding: $spacer--semi-large !important;
            }
            &-10 {
                padding: $spacer--xl !important;
            }
            &-11 {
                padding: $spacer--extra-large !important;
            }
            &-12 {
                padding: $spacer--xxl !important;
            }
            &-13 {
                padding: $spacer--extra-extra-large !important;
            }
            &-14 {
                padding: $spacer--larger !important;
            }
            &-vc {
                &-0 {
                    padding-top: 0 !important;
                    padding-bottom: 0 !important;
                }
                &-1 {
                    padding-top: $spacer--extra-small !important;
                    padding-bottom: $spacer--extra-small !important;
                }
                &-2 {
                    padding-top: $spacer !important;
                    padding-bottom: $spacer !important;
                }
                &-3 {
                    padding-top: $spacer--small !important;
                    padding-bottom: $spacer--small !important;
                }
                &-4 {
                    padding-top: $spacer--medium !important;
                    padding-bottom: $spacer--medium !important;
                }
                &-5 {
                    padding-top: $spacer--m !important;
                    padding-bottom: $spacer--m !important;
                }
                &-6 {
                    padding-top: $spacer--semi-medium !important;
                    padding-bottom: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-top: $spacer--large !important;
                    padding-bottom: $spacer--large !important;
                }
                &-8 {
                    padding-top: $spacer--l !important;
                    padding-bottom: $spacer--l !important;
                }
                &-9 {
                    padding-top: $spacer--semi-large !important;
                    padding-bottom: $spacer--semi-large !important;
                }
                &-10 {
                    padding-top: $spacer--xl !important;
                    padding-bottom: $spacer--xl !important;
                }
                &-11 {
                    padding-top: $spacer--extra-large !important;
                    padding-bottom: $spacer--extra-large !important;
                }
                &-12 {
                    padding-top: $spacer--xxl !important;
                    padding-bottom: $spacer--xxl !important;
                }
                &-13 {
                    padding-top: $spacer--extra-extra-large !important;
                    padding-bottom: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-top: $spacer--larger !important;
                    padding-bottom: $spacer--larger !important;
                }
            }
            &-hz {
                &-0 {
                    padding-right: 0 !important;
                    padding-left: 0 !important;
                }
                &-1 {
                    padding-right: $spacer--extra-small !important;
                    padding-left: $spacer--extra-small !important;
                }
                &-2 {
                    padding-right: $spacer !important;
                    padding-left: $spacer !important;
                }
                &-3 {
                    padding-right: $spacer--small !important;
                    padding-left: $spacer--small !important;
                }
                &-4 {
                    padding-right: $spacer--medium !important;
                    padding-left: $spacer--medium !important;
                }
                &-5 {
                    padding-right: $spacer--m !important;
                    padding-left: $spacer--m !important;
                }
                &-6 {
                    padding-right: $spacer--semi-medium !important;
                    padding-left: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-right: $spacer--large !important;
                    padding-left: $spacer--large !important;
                }
                &-8 {
                    padding-right: $spacer--l !important;
                    padding-left: $spacer--l !important;
                }
                &-9 {
                    padding-right: $spacer--semi-large !important;
                    padding-left: $spacer--semi-large !important;
                }
                &-10 {
                    padding-right: $spacer--xl !important;
                    padding-left: $spacer--xl !important;
                }
                &-11 {
                    padding-right: $spacer--extra-large !important;
                    padding-left: $spacer--extra-large !important;
                }
                &-12 {
                    padding-right: $spacer--xxl !important;
                    padding-left: $spacer--xxl !important;
                }
                &-13 {
                    padding-right: $spacer--extra-extra-large !important;
                    padding-left: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-right: $spacer--larger !important;
                    padding-left: $spacer--larger !important;
                }
            }
            &t {
                &-0 {
                    padding-top: 0 !important;
                }
                &-1 {
                    padding-top: $spacer--extra-small !important;
                }
                &-2 {
                    padding-top: $spacer !important;
                }
                &-3 {
                    padding-top: $spacer--small !important;
                }
                &-4 {
                    padding-top: $spacer--medium !important;
                }
                &-5 {
                    padding-top: $spacer--m !important;
                }
                &-6 {
                    padding-top: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-top: $spacer--large !important;
                }
                &-8 {
                    padding-top: $spacer--l !important;
                }
                &-9 {
                    padding-top: $spacer--semi-large !important;
                }
                &-10 {
                    padding-top: $spacer--xl !important;
                }
                &-11 {
                    padding-top: $spacer--extra-large !important;
                }
                &-12 {
                    padding-top: $spacer--xxl !important;
                }
                &-13 {
                    padding-top: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-top: $spacer--larger !important;
                }
            }
            &r {
                &-0 {
                    padding-right: 0 !important;
                }
                &-1 {
                    padding-right: $spacer--extra-small !important;
                }
                &-2 {
                    padding-right: $spacer !important;
                }
                &-3 {
                    padding-right: $spacer--small !important;
                }
                &-4 {
                    padding-right: $spacer--medium !important;
                }
                &-5 {
                    padding-right: $spacer--m !important;
                }
                &-6 {
                    padding-right: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-right: $spacer--large !important;
                }
                &-8 {
                    padding-right: $spacer--l !important;
                }
                &-9 {
                    padding-right: $spacer--semi-large !important;
                }
                &-10 {
                    padding-right: $spacer--xl !important;
                }
                &-11 {
                    padding-right: $spacer--extra-large !important;
                }
                &-12 {
                    padding-right: $spacer--xxl !important;
                }
                &-13 {
                    padding-right: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-right: $spacer--larger !important;
                }
            }
            &b {
                &-0 {
                    padding-bottom: 0 !important;
                }
                &-1 {
                    padding-bottom: $spacer--extra-small !important;
                }
                &-2 {
                    padding-bottom: $spacer !important;
                }
                &-3 {
                    padding-bottom: $spacer--small !important;
                }
                &-4 {
                    padding-bottom: $spacer--medium !important;
                }
                &-5 {
                    padding-bottom: $spacer--m !important;
                }
                &-6 {
                    padding-bottom: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-bottom: $spacer--large !important;
                }
                &-8 {
                    padding-bottom: $spacer--l !important;
                }
                &-9 {
                    padding-bottom: $spacer--semi-large !important;
                }
                &-10 {
                    padding-bottom: $spacer--xl !important;
                }
                &-11 {
                    padding-bottom: $spacer--extra-large !important;
                }
                &-12 {
                    padding-bottom: $spacer--xxl !important;
                }
                &-13 {
                    padding-bottom: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-bottom: $spacer--larger !important;
                }
            }
            &l {
                &-0 {
                    padding-left: 0 !important;
                }
                &-1 {
                    padding-left: $spacer--extra-small !important;
                }
                &-2 {
                    padding-left: $spacer !important;
                }
                &-3 {
                    padding-left: $spacer--small !important;
                }
                &-4 {
                    padding-left: $spacer--medium !important;
                }
                &-5 {
                    padding-left: $spacer--m !important;
                }
                &-6 {
                    padding-left: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-left: $spacer--large !important;
                }
                &-8 {
                    padding-left: $spacer--l !important;
                }
                &-9 {
                    padding-left: $spacer--semi-large !important;
                }
                &-10 {
                    padding-left: $spacer--xl !important;
                }
                &-11 {
                    padding-left: $spacer--extra-large !important;
                }
                &-12 {
                    padding-left: $spacer--xxl !important;
                }
                &-13 {
                    padding-left: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-left: $spacer--larger !important;
                }
            }
        }
    }
}
@include min-screen($screen-l) {
    .lg {
        &-p {
            &-0 {
                padding: 0 !important;
            }
            &-1 {
                padding: $spacer--extra-small !important;
            }
            &-2 {
                padding: $spacer !important;
            }
            &-3 {
                padding: $spacer--small !important;
            }
            &-4 {
                padding: $spacer--medium !important;
            }
            &-5 {
                padding: $spacer--m !important;
            }
            &-6 {
                padding: $spacer--semi-medium !important;
            }
            &-7 {
                padding: $spacer--large !important;
            }
            &-8 {
                padding: $spacer--l !important;
            }
            &-9 {
                padding: $spacer--semi-large !important;
            }
            &-10 {
                padding: $spacer--xl !important;
            }
            &-11 {
                padding: $spacer--extra-large !important;
            }
            &-12 {
                padding: $spacer--xxl !important;
            }
            &-13 {
                padding: $spacer--extra-extra-large !important;
            }
            &-14 {
                padding: $spacer--larger !important;
            }
            &-vc {
                &-0 {
                    padding-top: 0 !important;
                    padding-bottom: 0 !important;
                }
                &-1 {
                    padding-top: $spacer--extra-small !important;
                    padding-bottom: $spacer--extra-small !important;
                }
                &-2 {
                    padding-top: $spacer !important;
                    padding-bottom: $spacer !important;
                }
                &-3 {
                    padding-top: $spacer--small !important;
                    padding-bottom: $spacer--small !important;
                }
                &-4 {
                    padding-top: $spacer--medium !important;
                    padding-bottom: $spacer--medium !important;
                }
                &-5 {
                    padding-top: $spacer--m !important;
                    padding-bottom: $spacer--m !important;
                }
                &-6 {
                    padding-top: $spacer--semi-medium !important;
                    padding-bottom: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-top: $spacer--large !important;
                    padding-bottom: $spacer--large !important;
                }
                &-8 {
                    padding-top: $spacer--l !important;
                    padding-bottom: $spacer--l !important;
                }
                &-9 {
                    padding-top: $spacer--semi-large !important;
                    padding-bottom: $spacer--semi-large !important;
                }
                &-10 {
                    padding-top: $spacer--xl !important;
                    padding-bottom: $spacer--xl !important;
                }
                &-11 {
                    padding-top: $spacer--extra-large !important;
                    padding-bottom: $spacer--extra-large !important;
                }
                &-12 {
                    padding-top: $spacer--xxl !important;
                    padding-bottom: $spacer--xxl !important;
                }
                &-13 {
                    padding-top: $spacer--extra-extra-large !important;
                    padding-bottom: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-top: $spacer--larger !important;
                    padding-bottom: $spacer--larger !important;
                }
            }
            &-hz {
                &-0 {
                    padding-right: 0 !important;
                    padding-left: 0 !important;
                }
                &-1 {
                    padding-right: $spacer--extra-small !important;
                    padding-left: $spacer--extra-small !important;
                }
                &-2 {
                    padding-right: $spacer !important;
                    padding-left: $spacer !important;
                }
                &-3 {
                    padding-right: $spacer--small !important;
                    padding-left: $spacer--small !important;
                }
                &-4 {
                    padding-right: $spacer--medium !important;
                    padding-left: $spacer--medium !important;
                }
                &-5 {
                    padding-right: $spacer--m !important;
                    padding-left: $spacer--m !important;
                }
                &-6 {
                    padding-right: $spacer--semi-medium !important;
                    padding-left: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-right: $spacer--large !important;
                    padding-left: $spacer--large !important;
                }
                &-8 {
                    padding-right: $spacer--l !important;
                    padding-left: $spacer--l !important;
                }
                &-9 {
                    padding-right: $spacer--semi-large !important;
                    padding-left: $spacer--semi-large !important;
                }
                &-10 {
                    padding-right: $spacer--xl !important;
                    padding-left: $spacer--xl !important;
                }
                &-11 {
                    padding-right: $spacer--extra-large !important;
                    padding-left: $spacer--extra-large !important;
                }
                &-12 {
                    padding-right: $spacer--xxl !important;
                    padding-left: $spacer--xxl !important;
                }
                &-13 {
                    padding-right: $spacer--extra-extra-large !important;
                    padding-left: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-right: $spacer--larger !important;
                    padding-left: $spacer--larger !important;
                }
            }
            &t {
                &-0 {
                    padding-top: 0 !important;
                }
                &-1 {
                    padding-top: $spacer--extra-small !important;
                }
                &-2 {
                    padding-top: $spacer !important;
                }
                &-3 {
                    padding-top: $spacer--small !important;
                }
                &-4 {
                    padding-top: $spacer--medium !important;
                }
                &-5 {
                    padding-top: $spacer--m !important;
                }
                &-6 {
                    padding-top: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-top: $spacer--large !important;
                }
                &-8 {
                    padding-top: $spacer--l !important;
                }
                &-9 {
                    padding-top: $spacer--semi-large !important;
                }
                &-10 {
                    padding-top: $spacer--xl !important;
                }
                &-11 {
                    padding-top: $spacer--extra-large !important;
                }
                &-12 {
                    padding-top: $spacer--xxl !important;
                }
                &-13 {
                    padding-top: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-top: $spacer--larger !important;
                }
            }
            &r {
                &-0 {
                    padding-right: 0 !important;
                }
                &-1 {
                    padding-right: $spacer--extra-small !important;
                }
                &-2 {
                    padding-right: $spacer !important;
                }
                &-3 {
                    padding-right: $spacer--small !important;
                }
                &-4 {
                    padding-right: $spacer--medium !important;
                }
                &-5 {
                    padding-right: $spacer--m !important;
                }
                &-6 {
                    padding-right: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-right: $spacer--large !important;
                }
                &-8 {
                    padding-right: $spacer--l !important;
                }
                &-9 {
                    padding-right: $spacer--semi-large !important;
                }
                &-10 {
                    padding-right: $spacer--xl !important;
                }
                &-11 {
                    padding-right: $spacer--extra-large !important;
                }
                &-12 {
                    padding-right: $spacer--xxl !important;
                }
                &-13 {
                    padding-right: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-right: $spacer--larger !important;
                }
            }
            &b {
                &-0 {
                    padding-bottom: 0 !important;
                }
                &-1 {
                    padding-bottom: $spacer--extra-small !important;
                }
                &-2 {
                    padding-bottom: $spacer !important;
                }
                &-3 {
                    padding-bottom: $spacer--small !important;
                }
                &-4 {
                    padding-bottom: $spacer--medium !important;
                }
                &-5 {
                    padding-bottom: $spacer--m !important;
                }
                &-6 {
                    padding-bottom: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-bottom: $spacer--large !important;
                }
                &-8 {
                    padding-bottom: $spacer--l !important;
                }
                &-9 {
                    padding-bottom: $spacer--semi-large !important;
                }
                &-10 {
                    padding-bottom: $spacer--xl !important;
                }
                &-11 {
                    padding-bottom: $spacer--extra-large !important;
                }
                &-12 {
                    padding-bottom: $spacer--xxl !important;
                }
                &-13 {
                    padding-bottom: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-bottom: $spacer--larger !important;
                }
            }
            &l {
                &-0 {
                    padding-left: 0 !important;
                }
                &-1 {
                    padding-left: $spacer--extra-small !important;
                }
                &-2 {
                    padding-left: $spacer !important;
                }
                &-3 {
                    padding-left: $spacer--small !important;
                }
                &-4 {
                    padding-left: $spacer--medium !important;
                }
                &-5 {
                    padding-left: $spacer--m !important;
                }
                &-6 {
                    padding-left: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-left: $spacer--large !important;
                }
                &-8 {
                    padding-left: $spacer--l !important;
                }
                &-9 {
                    padding-left: $spacer--semi-large !important;
                }
                &-10 {
                    padding-left: $spacer--xl !important;
                }
                &-11 {
                    padding-left: $spacer--extra-large !important;
                }
                &-12 {
                    padding-left: $spacer--xxl !important;
                }
                &-13 {
                    padding-left: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-left: $spacer--larger !important;
                }
            }
        }
    }
}
@include min-screen($screen-xl) {
    .xl {
        &-p {
            &-0 {
                padding: 0 !important;
            }
            &-1 {
                padding: $spacer--extra-small !important;
            }
            &-2 {
                padding: $spacer !important;
            }
            &-3 {
                padding: $spacer--small !important;
            }
            &-4 {
                padding: $spacer--medium !important;
            }
            &-5 {
                padding: $spacer--m !important;
            }
            &-6 {
                padding: $spacer--semi-medium !important;
            }
            &-7 {
                padding: $spacer--large !important;
            }
            &-8 {
                padding: $spacer--l !important;
            }
            &-9 {
                padding: $spacer--semi-large !important;
            }
            &-10 {
                padding: $spacer--xl !important;
            }
            &-11 {
                padding: $spacer--extra-large !important;
            }
            &-12 {
                padding: $spacer--xxl !important;
            }
            &-13 {
                padding: $spacer--extra-extra-large !important;
            }
            &-14 {
                padding: $spacer--larger !important;
            }
            &-vc {
                &-0 {
                    padding-top: 0 !important;
                    padding-bottom: 0 !important;
                }
                &-1 {
                    padding-top: $spacer--extra-small !important;
                    padding-bottom: $spacer--extra-small !important;
                }
                &-2 {
                    padding-top: $spacer !important;
                    padding-bottom: $spacer !important;
                }
                &-3 {
                    padding-top: $spacer--small !important;
                    padding-bottom: $spacer--small !important;
                }
                &-4 {
                    padding-top: $spacer--medium !important;
                    padding-bottom: $spacer--medium !important;
                }
                &-5 {
                    padding-top: $spacer--m !important;
                    padding-bottom: $spacer--m !important;
                }
                &-6 {
                    padding-top: $spacer--semi-medium !important;
                    padding-bottom: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-top: $spacer--large !important;
                    padding-bottom: $spacer--large !important;
                }
                &-8 {
                    padding-top: $spacer--l !important;
                    padding-bottom: $spacer--l !important;
                }
                &-9 {
                    padding-top: $spacer--semi-large !important;
                    padding-bottom: $spacer--semi-large !important;
                }
                &-10 {
                    padding-top: $spacer--xl !important;
                    padding-bottom: $spacer--xl !important;
                }
                &-11 {
                    padding-top: $spacer--extra-large !important;
                    padding-bottom: $spacer--extra-large !important;
                }
                &-12 {
                    padding-top: $spacer--xxl !important;
                    padding-bottom: $spacer--xxl !important;
                }
                &-13 {
                    padding-top: $spacer--extra-extra-large !important;
                    padding-bottom: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-top: $spacer--larger !important;
                    padding-bottom: $spacer--larger !important;
                }
            }
            &-hz {
                &-0 {
                    padding-right: 0 !important;
                    padding-left: 0 !important;
                }
                &-1 {
                    padding-right: $spacer--extra-small !important;
                    padding-left: $spacer--extra-small !important;
                }
                &-2 {
                    padding-right: $spacer !important;
                    padding-left: $spacer !important;
                }
                &-3 {
                    padding-right: $spacer--small !important;
                    padding-left: $spacer--small !important;
                }
                &-4 {
                    padding-right: $spacer--medium !important;
                    padding-left: $spacer--medium !important;
                }
                &-5 {
                    padding-right: $spacer--m !important;
                    padding-left: $spacer--m !important;
                }
                &-6 {
                    padding-right: $spacer--semi-medium !important;
                    padding-left: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-right: $spacer--large !important;
                    padding-left: $spacer--large !important;
                }
                &-8 {
                    padding-right: $spacer--l !important;
                    padding-left: $spacer--l !important;
                }
                &-9 {
                    padding-right: $spacer--semi-large !important;
                    padding-left: $spacer--semi-large !important;
                }
                &-10 {
                    padding-right: $spacer--xl !important;
                    padding-left: $spacer--xl !important;
                }
                &-11 {
                    padding-right: $spacer--extra-large !important;
                    padding-left: $spacer--extra-large !important;
                }
                &-12 {
                    padding-right: $spacer--xxl !important;
                    padding-left: $spacer--xxl !important;
                }
                &-13 {
                    padding-right: $spacer--extra-extra-large !important;
                    padding-left: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-right: $spacer--larger !important;
                    padding-left: $spacer--larger !important;
                }
            }
            &t {
                &-0 {
                    padding-top: 0 !important;
                }
                &-1 {
                    padding-top: $spacer--extra-small !important;
                }
                &-2 {
                    padding-top: $spacer !important;
                }
                &-3 {
                    padding-top: $spacer--small !important;
                }
                &-4 {
                    padding-top: $spacer--medium !important;
                }
                &-5 {
                    padding-top: $spacer--m !important;
                }
                &-6 {
                    padding-top: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-top: $spacer--large !important;
                }
                &-8 {
                    padding-top: $spacer--l !important;
                }
                &-9 {
                    padding-top: $spacer--semi-large !important;
                }
                &-10 {
                    padding-top: $spacer--xl !important;
                }
                &-11 {
                    padding-top: $spacer--extra-large !important;
                }
                &-12 {
                    padding-top: $spacer--xxl !important;
                }
                &-13 {
                    padding-top: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-top: $spacer--larger !important;
                }
            }
            &r {
                &-0 {
                    padding-right: 0 !important;
                }
                &-1 {
                    padding-right: $spacer--extra-small !important;
                }
                &-2 {
                    padding-right: $spacer !important;
                }
                &-3 {
                    padding-right: $spacer--small !important;
                }
                &-4 {
                    padding-right: $spacer--medium !important;
                }
                &-5 {
                    padding-right: $spacer--m !important;
                }
                &-6 {
                    padding-right: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-right: $spacer--large !important;
                }
                &-8 {
                    padding-right: $spacer--l !important;
                }
                &-9 {
                    padding-right: $spacer--semi-large !important;
                }
                &-10 {
                    padding-right: $spacer--xl !important;
                }
                &-11 {
                    padding-right: $spacer--extra-large !important;
                }
                &-12 {
                    padding-right: $spacer--xxl !important;
                }
                &-13 {
                    padding-right: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-right: $spacer--larger !important;
                }
            }
            &b {
                &-0 {
                    padding-bottom: 0 !important;
                }
                &-1 {
                    padding-bottom: $spacer--extra-small !important;
                }
                &-2 {
                    padding-bottom: $spacer !important;
                }
                &-3 {
                    padding-bottom: $spacer--small !important;
                }
                &-4 {
                    padding-bottom: $spacer--medium !important;
                }
                &-5 {
                    padding-bottom: $spacer--m !important;
                }
                &-6 {
                    padding-bottom: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-bottom: $spacer--large !important;
                }
                &-8 {
                    padding-bottom: $spacer--l !important;
                }
                &-9 {
                    padding-bottom: $spacer--semi-large !important;
                }
                &-10 {
                    padding-bottom: $spacer--xl !important;
                }
                &-11 {
                    padding-bottom: $spacer--extra-large !important;
                }
                &-12 {
                    padding-bottom: $spacer--xxl !important;
                }
                &-13 {
                    padding-bottom: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-bottom: $spacer--larger !important;
                }
            }
            &l {
                &-0 {
                    padding-left: 0 !important;
                }
                &-1 {
                    padding-left: $spacer--extra-small !important;
                }
                &-2 {
                    padding-left: $spacer !important;
                }
                &-3 {
                    padding-left: $spacer--small !important;
                }
                &-4 {
                    padding-left: $spacer--medium !important;
                }
                &-5 {
                    padding-left: $spacer--m !important;
                }
                &-6 {
                    padding-left: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-left: $spacer--large !important;
                }
                &-8 {
                    padding-left: $spacer--l !important;
                }
                &-9 {
                    padding-left: $spacer--semi-large !important;
                }
                &-10 {
                    padding-left: $spacer--xl !important;
                }
                &-11 {
                    padding-left: $spacer--extra-large !important;
                }
                &-12 {
                    padding-left: $spacer--xxl !important;
                }
                &-13 {
                    padding-left: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-left: $spacer--larger !important;
                }
            }
        }
    }
}
@include min-screen($screen-2xl) {
    .x2l {
        &-p {
            &-0 {
                padding: 0 !important;
            }
            &-1 {
                padding: $spacer--extra-small !important;
            }
            &-2 {
                padding: $spacer !important;
            }
            &-3 {
                padding: $spacer--small !important;
            }
            &-4 {
                padding: $spacer--medium !important;
            }
            &-5 {
                padding: $spacer--m !important;
            }
            &-6 {
                padding: $spacer--semi-medium !important;
            }
            &-7 {
                padding: $spacer--large !important;
            }
            &-8 {
                padding: $spacer--l !important;
            }
            &-9 {
                padding: $spacer--semi-large !important;
            }
            &-10 {
                padding: $spacer--xl !important;
            }
            &-11 {
                padding: $spacer--extra-large !important;
            }
            &-12 {
                padding: $spacer--xxl !important;
            }
            &-13 {
                padding: $spacer--extra-extra-large !important;
            }
            &-14 {
                padding: $spacer--larger !important;
            }
            &-vc {
                &-0 {
                    padding-top: 0 !important;
                    padding-bottom: 0 !important;
                }
                &-1 {
                    padding-top: $spacer--extra-small !important;
                    padding-bottom: $spacer--extra-small !important;
                }
                &-2 {
                    padding-top: $spacer !important;
                    padding-bottom: $spacer !important;
                }
                &-3 {
                    padding-top: $spacer--small !important;
                    padding-bottom: $spacer--small !important;
                }
                &-4 {
                    padding-top: $spacer--medium !important;
                    padding-bottom: $spacer--medium !important;
                }
                &-5 {
                    padding-top: $spacer--m !important;
                    padding-bottom: $spacer--m !important;
                }
                &-6 {
                    padding-top: $spacer--semi-medium !important;
                    padding-bottom: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-top: $spacer--large !important;
                    padding-bottom: $spacer--large !important;
                }
                &-8 {
                    padding-top: $spacer--l !important;
                    padding-bottom: $spacer--l !important;
                }
                &-9 {
                    padding-top: $spacer--semi-large !important;
                    padding-bottom: $spacer--semi-large !important;
                }
                &-10 {
                    padding-top: $spacer--xl !important;
                    padding-bottom: $spacer--xl !important;
                }
                &-11 {
                    padding-top: $spacer--extra-large !important;
                    padding-bottom: $spacer--extra-large !important;
                }
                &-12 {
                    padding-top: $spacer--xxl !important;
                    padding-bottom: $spacer--xxl !important;
                }
                &-13 {
                    padding-top: $spacer--extra-extra-large !important;
                    padding-bottom: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-top: $spacer--larger !important;
                    padding-bottom: $spacer--larger !important;
                }
            }
            &-hz {
                &-0 {
                    padding-right: 0 !important;
                    padding-left: 0 !important;
                }
                &-1 {
                    padding-right: $spacer--extra-small !important;
                    padding-left: $spacer--extra-small !important;
                }
                &-2 {
                    padding-right: $spacer !important;
                    padding-left: $spacer !important;
                }
                &-3 {
                    padding-right: $spacer--small !important;
                    padding-left: $spacer--small !important;
                }
                &-4 {
                    padding-right: $spacer--medium !important;
                    padding-left: $spacer--medium !important;
                }
                &-5 {
                    padding-right: $spacer--m !important;
                    padding-left: $spacer--m !important;
                }
                &-6 {
                    padding-right: $spacer--semi-medium !important;
                    padding-left: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-right: $spacer--large !important;
                    padding-left: $spacer--large !important;
                }
                &-8 {
                    padding-right: $spacer--l !important;
                    padding-left: $spacer--l !important;
                }
                &-9 {
                    padding-right: $spacer--semi-large !important;
                    padding-left: $spacer--semi-large !important;
                }
                &-10 {
                    padding-right: $spacer--xl !important;
                    padding-left: $spacer--xl !important;
                }
                &-11 {
                    padding-right: $spacer--extra-large !important;
                    padding-left: $spacer--extra-large !important;
                }
                &-12 {
                    padding-right: $spacer--xxl !important;
                    padding-left: $spacer--xxl !important;
                }
                &-13 {
                    padding-right: $spacer--extra-extra-large !important;
                    padding-left: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-right: $spacer--larger !important;
                    padding-left: $spacer--larger !important;
                }
            }
            &t {
                &-0 {
                    padding-top: 0 !important;
                }
                &-1 {
                    padding-top: $spacer--extra-small !important;
                }
                &-2 {
                    padding-top: $spacer !important;
                }
                &-3 {
                    padding-top: $spacer--small !important;
                }
                &-4 {
                    padding-top: $spacer--medium !important;
                }
                &-5 {
                    padding-top: $spacer--m !important;
                }
                &-6 {
                    padding-top: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-top: $spacer--large !important;
                }
                &-8 {
                    padding-top: $spacer--l !important;
                }
                &-9 {
                    padding-top: $spacer--semi-large !important;
                }
                &-10 {
                    padding-top: $spacer--xl !important;
                }
                &-11 {
                    padding-top: $spacer--extra-large !important;
                }
                &-12 {
                    padding-top: $spacer--xxl !important;
                }
                &-13 {
                    padding-top: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-top: $spacer--larger !important;
                }
            }
            &r {
                &-0 {
                    padding-right: 0 !important;
                }
                &-1 {
                    padding-right: $spacer--extra-small !important;
                }
                &-2 {
                    padding-right: $spacer !important;
                }
                &-3 {
                    padding-right: $spacer--small !important;
                }
                &-4 {
                    padding-right: $spacer--medium !important;
                }
                &-5 {
                    padding-right: $spacer--m !important;
                }
                &-6 {
                    padding-right: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-right: $spacer--large !important;
                }
                &-8 {
                    padding-right: $spacer--l !important;
                }
                &-9 {
                    padding-right: $spacer--semi-large !important;
                }
                &-10 {
                    padding-right: $spacer--xl !important;
                }
                &-11 {
                    padding-right: $spacer--extra-large !important;
                }
                &-12 {
                    padding-right: $spacer--xxl !important;
                }
                &-13 {
                    padding-right: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-right: $spacer--larger !important;
                }
            }
            &b {
                &-0 {
                    padding-bottom: 0 !important;
                }
                &-1 {
                    padding-bottom: $spacer--extra-small !important;
                }
                &-2 {
                    padding-bottom: $spacer !important;
                }
                &-3 {
                    padding-bottom: $spacer--small !important;
                }
                &-4 {
                    padding-bottom: $spacer--medium !important;
                }
                &-5 {
                    padding-bottom: $spacer--m !important;
                }
                &-6 {
                    padding-bottom: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-bottom: $spacer--large !important;
                }
                &-8 {
                    padding-bottom: $spacer--l !important;
                }
                &-9 {
                    padding-bottom: $spacer--semi-large !important;
                }
                &-10 {
                    padding-bottom: $spacer--xl !important;
                }
                &-11 {
                    padding-bottom: $spacer--extra-large !important;
                }
                &-12 {
                    padding-bottom: $spacer--xxl !important;
                }
                &-13 {
                    padding-bottom: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-bottom: $spacer--larger !important;
                }
            }
            &l {
                &-0 {
                    padding-left: 0 !important;
                }
                &-1 {
                    padding-left: $spacer--extra-small !important;
                }
                &-2 {
                    padding-left: $spacer !important;
                }
                &-3 {
                    padding-left: $spacer--small !important;
                }
                &-4 {
                    padding-left: $spacer--medium !important;
                }
                &-5 {
                    padding-left: $spacer--m !important;
                }
                &-6 {
                    padding-left: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-left: $spacer--large !important;
                }
                &-8 {
                    padding-left: $spacer--l !important;
                }
                &-9 {
                    padding-left: $spacer--semi-large !important;
                }
                &-10 {
                    padding-left: $spacer--xl !important;
                }
                &-11 {
                    padding-left: $spacer--extra-large !important;
                }
                &-12 {
                    padding-left: $spacer--xxl !important;
                }
                &-13 {
                    padding-left: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-left: $spacer--larger !important;
                }
            }
        }
    }
}
@include min-screen($screen-xxl) {
    .xxl {
        &-p {
            &-0 {
                padding: 0 !important;
            }
            &-1 {
                padding: $spacer--extra-small !important;
            }
            &-2 {
                padding: $spacer !important;
            }
            &-3 {
                padding: $spacer--small !important;
            }
            &-4 {
                padding: $spacer--medium !important;
            }
            &-5 {
                padding: $spacer--m !important;
            }
            &-6 {
                padding: $spacer--semi-medium !important;
            }
            &-7 {
                padding: $spacer--large !important;
            }
            &-8 {
                padding: $spacer--l !important;
            }
            &-9 {
                padding: $spacer--semi-large !important;
            }
            &-10 {
                padding: $spacer--xl !important;
            }
            &-11 {
                padding: $spacer--extra-large !important;
            }
            &-12 {
                padding: $spacer--xxl !important;
            }
            &-13 {
                padding: $spacer--extra-extra-large !important;
            }
            &-14 {
                padding: $spacer--larger !important;
            }
            &-vc {
                &-0 {
                    padding-top: 0 !important;
                    padding-bottom: 0 !important;
                }
                &-1 {
                    padding-top: $spacer--extra-small !important;
                    padding-bottom: $spacer--extra-small !important;
                }
                &-2 {
                    padding-top: $spacer !important;
                    padding-bottom: $spacer !important;
                }
                &-3 {
                    padding-top: $spacer--small !important;
                    padding-bottom: $spacer--small !important;
                }
                &-4 {
                    padding-top: $spacer--medium !important;
                    padding-bottom: $spacer--medium !important;
                }
                &-5 {
                    padding-top: $spacer--m !important;
                    padding-bottom: $spacer--m !important;
                }
                &-6 {
                    padding-top: $spacer--semi-medium !important;
                    padding-bottom: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-top: $spacer--large !important;
                    padding-bottom: $spacer--large !important;
                }
                &-8 {
                    padding-top: $spacer--l !important;
                    padding-bottom: $spacer--l !important;
                }
                &-9 {
                    padding-top: $spacer--semi-large !important;
                    padding-bottom: $spacer--semi-large !important;
                }
                &-10 {
                    padding-top: $spacer--xl !important;
                    padding-bottom: $spacer--xl !important;
                }
                &-11 {
                    padding-top: $spacer--extra-large !important;
                    padding-bottom: $spacer--extra-large !important;
                }
                &-12 {
                    padding-top: $spacer--xxl !important;
                    padding-bottom: $spacer--xxl !important;
                }
                &-13 {
                    padding-top: $spacer--extra-extra-large !important;
                    padding-bottom: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-top: $spacer--larger !important;
                    padding-bottom: $spacer--larger !important;
                }
            }
            &-hz {
                &-0 {
                    padding-right: 0 !important;
                    padding-left: 0 !important;
                }
                &-1 {
                    padding-right: $spacer--extra-small !important;
                    padding-left: $spacer--extra-small !important;
                }
                &-2 {
                    padding-right: $spacer !important;
                    padding-left: $spacer !important;
                }
                &-3 {
                    padding-right: $spacer--small !important;
                    padding-left: $spacer--small !important;
                }
                &-4 {
                    padding-right: $spacer--medium !important;
                    padding-left: $spacer--medium !important;
                }
                &-5 {
                    padding-right: $spacer--m !important;
                    padding-left: $spacer--m !important;
                }
                &-6 {
                    padding-right: $spacer--semi-medium !important;
                    padding-left: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-right: $spacer--large !important;
                    padding-left: $spacer--large !important;
                }
                &-8 {
                    padding-right: $spacer--l !important;
                    padding-left: $spacer--l !important;
                }
                &-9 {
                    padding-right: $spacer--semi-large !important;
                    padding-left: $spacer--semi-large !important;
                }
                &-10 {
                    padding-right: $spacer--xl !important;
                    padding-left: $spacer--xl !important;
                }
                &-11 {
                    padding-right: $spacer--extra-large !important;
                    padding-left: $spacer--extra-large !important;
                }
                &-12 {
                    padding-right: $spacer--xxl !important;
                    padding-left: $spacer--xxl !important;
                }
                &-13 {
                    padding-right: $spacer--extra-extra-large !important;
                    padding-left: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-right: $spacer--larger !important;
                    padding-left: $spacer--larger !important;
                }
            }
            &t {
                &-0 {
                    padding-top: 0 !important;
                }
                &-1 {
                    padding-top: $spacer--extra-small !important;
                }
                &-2 {
                    padding-top: $spacer !important;
                }
                &-3 {
                    padding-top: $spacer--small !important;
                }
                &-4 {
                    padding-top: $spacer--medium !important;
                }
                &-5 {
                    padding-top: $spacer--m !important;
                }
                &-6 {
                    padding-top: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-top: $spacer--large !important;
                }
                &-8 {
                    padding-top: $spacer--l !important;
                }
                &-9 {
                    padding-top: $spacer--semi-large !important;
                }
                &-10 {
                    padding-top: $spacer--xl !important;
                }
                &-11 {
                    padding-top: $spacer--extra-large !important;
                }
                &-12 {
                    padding-top: $spacer--xxl !important;
                }
                &-13 {
                    padding-top: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-top: $spacer--larger !important;
                }
            }
            &r {
                &-0 {
                    padding-right: 0 !important;
                }
                &-1 {
                    padding-right: $spacer--extra-small !important;
                }
                &-2 {
                    padding-right: $spacer !important;
                }
                &-3 {
                    padding-right: $spacer--small !important;
                }
                &-4 {
                    padding-right: $spacer--medium !important;
                }
                &-5 {
                    padding-right: $spacer--m !important;
                }
                &-6 {
                    padding-right: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-right: $spacer--large !important;
                }
                &-8 {
                    padding-right: $spacer--l !important;
                }
                &-9 {
                    padding-right: $spacer--semi-large !important;
                }
                &-10 {
                    padding-right: $spacer--xl !important;
                }
                &-11 {
                    padding-right: $spacer--extra-large !important;
                }
                &-12 {
                    padding-right: $spacer--xxl !important;
                }
                &-13 {
                    padding-right: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-right: $spacer--larger !important;
                }
            }
            &b {
                &-0 {
                    padding-bottom: 0 !important;
                }
                &-1 {
                    padding-bottom: $spacer--extra-small !important;
                }
                &-2 {
                    padding-bottom: $spacer !important;
                }
                &-3 {
                    padding-bottom: $spacer--small !important;
                }
                &-4 {
                    padding-bottom: $spacer--medium !important;
                }
                &-5 {
                    padding-bottom: $spacer--m !important;
                }
                &-6 {
                    padding-bottom: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-bottom: $spacer--large !important;
                }
                &-8 {
                    padding-bottom: $spacer--l !important;
                }
                &-9 {
                    padding-bottom: $spacer--semi-large !important;
                }
                &-10 {
                    padding-bottom: $spacer--xl !important;
                }
                &-11 {
                    padding-bottom: $spacer--extra-large !important;
                }
                &-12 {
                    padding-bottom: $spacer--xxl !important;
                }
                &-13 {
                    padding-bottom: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-bottom: $spacer--larger !important;
                }
            }
            &l {
                &-0 {
                    padding-left: 0 !important;
                }
                &-1 {
                    padding-left: $spacer--extra-small !important;
                }
                &-2 {
                    padding-left: $spacer !important;
                }
                &-3 {
                    padding-left: $spacer--small !important;
                }
                &-4 {
                    padding-left: $spacer--medium !important;
                }
                &-5 {
                    padding-left: $spacer--m !important;
                }
                &-6 {
                    padding-left: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-left: $spacer--large !important;
                }
                &-8 {
                    padding-left: $spacer--l !important;
                }
                &-9 {
                    padding-left: $spacer--semi-large !important;
                }
                &-10 {
                    padding-left: $spacer--xl !important;
                }
                &-11 {
                    padding-left: $spacer--extra-large !important;
                }
                &-12 {
                    padding-left: $spacer--xxl !important;
                }
                &-13 {
                    padding-left: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-left: $spacer--larger !important;
                }
            }
        }
    }
}
@include min-screen($screen-3xl) {
    .x3l {
        &-p {
            &-0 {
                padding: 0 !important;
            }
            &-1 {
                padding: $spacer--extra-small !important;
            }
            &-2 {
                padding: $spacer !important;
            }
            &-3 {
                padding: $spacer--small !important;
            }
            &-4 {
                padding: $spacer--medium !important;
            }
            &-5 {
                padding: $spacer--m !important;
            }
            &-6 {
                padding: $spacer--semi-medium !important;
            }
            &-7 {
                padding: $spacer--large !important;
            }
            &-8 {
                padding: $spacer--l !important;
            }
            &-9 {
                padding: $spacer--semi-large !important;
            }
            &-10 {
                padding: $spacer--xl !important;
            }
            &-11 {
                padding: $spacer--extra-large !important;
            }
            &-12 {
                padding: $spacer--xxl !important;
            }
            &-13 {
                padding: $spacer--extra-extra-large !important;
            }
            &-14 {
                padding: $spacer--larger !important;
            }
            &-vc {
                &-0 {
                    padding-top: 0 !important;
                    padding-bottom: 0 !important;
                }
                &-1 {
                    padding-top: $spacer--extra-small !important;
                    padding-bottom: $spacer--extra-small !important;
                }
                &-2 {
                    padding-top: $spacer !important;
                    padding-bottom: $spacer !important;
                }
                &-3 {
                    padding-top: $spacer--small !important;
                    padding-bottom: $spacer--small !important;
                }
                &-4 {
                    padding-top: $spacer--medium !important;
                    padding-bottom: $spacer--medium !important;
                }
                &-5 {
                    padding-top: $spacer--m !important;
                    padding-bottom: $spacer--m !important;
                }
                &-6 {
                    padding-top: $spacer--semi-medium !important;
                    padding-bottom: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-top: $spacer--large !important;
                    padding-bottom: $spacer--large !important;
                }
                &-8 {
                    padding-top: $spacer--l !important;
                    padding-bottom: $spacer--l !important;
                }
                &-9 {
                    padding-top: $spacer--semi-large !important;
                    padding-bottom: $spacer--semi-large !important;
                }
                &-10 {
                    padding-top: $spacer--xl !important;
                    padding-bottom: $spacer--xl !important;
                }
                &-11 {
                    padding-top: $spacer--extra-large !important;
                    padding-bottom: $spacer--extra-large !important;
                }
                &-12 {
                    padding-top: $spacer--xxl !important;
                    padding-bottom: $spacer--xxl !important;
                }
                &-13 {
                    padding-top: $spacer--extra-extra-large !important;
                    padding-bottom: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-top: $spacer--larger !important;
                    padding-bottom: $spacer--larger !important;
                }
            }
            &-hz {
                &-0 {
                    padding-right: 0 !important;
                    padding-left: 0 !important;
                }
                &-1 {
                    padding-right: $spacer--extra-small !important;
                    padding-left: $spacer--extra-small !important;
                }
                &-2 {
                    padding-right: $spacer !important;
                    padding-left: $spacer !important;
                }
                &-3 {
                    padding-right: $spacer--small !important;
                    padding-left: $spacer--small !important;
                }
                &-4 {
                    padding-right: $spacer--medium !important;
                    padding-left: $spacer--medium !important;
                }
                &-5 {
                    padding-right: $spacer--m !important;
                    padding-left: $spacer--m !important;
                }
                &-6 {
                    padding-right: $spacer--semi-medium !important;
                    padding-left: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-right: $spacer--large !important;
                    padding-left: $spacer--large !important;
                }
                &-8 {
                    padding-right: $spacer--l !important;
                    padding-left: $spacer--l !important;
                }
                &-9 {
                    padding-right: $spacer--semi-large !important;
                    padding-left: $spacer--semi-large !important;
                }
                &-10 {
                    padding-right: $spacer--xl !important;
                    padding-left: $spacer--xl !important;
                }
                &-11 {
                    padding-right: $spacer--extra-large !important;
                    padding-left: $spacer--extra-large !important;
                }
                &-12 {
                    padding-right: $spacer--xxl !important;
                    padding-left: $spacer--xxl !important;
                }
                &-13 {
                    padding-right: $spacer--extra-extra-large !important;
                    padding-left: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-right: $spacer--larger !important;
                    padding-left: $spacer--larger !important;
                }
            }
            &t {
                &-0 {
                    padding-top: 0 !important;
                }
                &-1 {
                    padding-top: $spacer--extra-small !important;
                }
                &-2 {
                    padding-top: $spacer !important;
                }
                &-3 {
                    padding-top: $spacer--small !important;
                }
                &-4 {
                    padding-top: $spacer--medium !important;
                }
                &-5 {
                    padding-top: $spacer--m !important;
                }
                &-6 {
                    padding-top: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-top: $spacer--large !important;
                }
                &-8 {
                    padding-top: $spacer--l !important;
                }
                &-9 {
                    padding-top: $spacer--semi-large !important;
                }
                &-10 {
                    padding-top: $spacer--xl !important;
                }
                &-11 {
                    padding-top: $spacer--extra-large !important;
                }
                &-12 {
                    padding-top: $spacer--xxl !important;
                }
                &-13 {
                    padding-top: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-top: $spacer--larger !important;
                }
            }
            &r {
                &-0 {
                    padding-right: 0 !important;
                }
                &-1 {
                    padding-right: $spacer--extra-small !important;
                }
                &-2 {
                    padding-right: $spacer !important;
                }
                &-3 {
                    padding-right: $spacer--small !important;
                }
                &-4 {
                    padding-right: $spacer--medium !important;
                }
                &-5 {
                    padding-right: $spacer--m !important;
                }
                &-6 {
                    padding-right: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-right: $spacer--large !important;
                }
                &-8 {
                    padding-right: $spacer--l !important;
                }
                &-9 {
                    padding-right: $spacer--semi-large !important;
                }
                &-10 {
                    padding-right: $spacer--xl !important;
                }
                &-11 {
                    padding-right: $spacer--extra-large !important;
                }
                &-12 {
                    padding-right: $spacer--xxl !important;
                }
                &-13 {
                    padding-right: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-right: $spacer--larger !important;
                }
            }
            &b {
                &-0 {
                    padding-bottom: 0 !important;
                }
                &-1 {
                    padding-bottom: $spacer--extra-small !important;
                }
                &-2 {
                    padding-bottom: $spacer !important;
                }
                &-3 {
                    padding-bottom: $spacer--small !important;
                }
                &-4 {
                    padding-bottom: $spacer--medium !important;
                }
                &-5 {
                    padding-bottom: $spacer--m !important;
                }
                &-6 {
                    padding-bottom: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-bottom: $spacer--large !important;
                }
                &-8 {
                    padding-bottom: $spacer--l !important;
                }
                &-9 {
                    padding-bottom: $spacer--semi-large !important;
                }
                &-10 {
                    padding-bottom: $spacer--xl !important;
                }
                &-11 {
                    padding-bottom: $spacer--extra-large !important;
                }
                &-12 {
                    padding-bottom: $spacer--xxl !important;
                }
                &-13 {
                    padding-bottom: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-bottom: $spacer--larger !important;
                }
            }
            &l {
                &-0 {
                    padding-left: 0 !important;
                }
                &-1 {
                    padding-left: $spacer--extra-small !important;
                }
                &-2 {
                    padding-left: $spacer !important;
                }
                &-3 {
                    padding-left: $spacer--small !important;
                }
                &-4 {
                    padding-left: $spacer--medium !important;
                }
                &-5 {
                    padding-left: $spacer--m !important;
                }
                &-6 {
                    padding-left: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-left: $spacer--large !important;
                }
                &-8 {
                    padding-left: $spacer--l !important;
                }
                &-9 {
                    padding-left: $spacer--semi-large !important;
                }
                &-10 {
                    padding-left: $spacer--xl !important;
                }
                &-11 {
                    padding-left: $spacer--extra-large !important;
                }
                &-12 {
                    padding-left: $spacer--xxl !important;
                }
                &-13 {
                    padding-left: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-left: $spacer--larger !important;
                }
            }
        }
    }
}
@include min-screen($screen-xxxl) {
    .xxxl {
        &-p {
            &-0 {
                padding: 0 !important;
            }
            &-1 {
                padding: $spacer--extra-small !important;
            }
            &-2 {
                padding: $spacer !important;
            }
            &-3 {
                padding: $spacer--small !important;
            }
            &-4 {
                padding: $spacer--medium !important;
            }
            &-5 {
                padding: $spacer--m !important;
            }
            &-6 {
                padding: $spacer--semi-medium !important;
            }
            &-7 {
                padding: $spacer--large !important;
            }
            &-8 {
                padding: $spacer--l !important;
            }
            &-9 {
                padding: $spacer--semi-large !important;
            }
            &-10 {
                padding: $spacer--xl !important;
            }
            &-11 {
                padding: $spacer--extra-large !important;
            }
            &-12 {
                padding: $spacer--xxl !important;
            }
            &-13 {
                padding: $spacer--extra-extra-large !important;
            }
            &-14 {
                padding: $spacer--larger !important;
            }
            &-vc {
                &-0 {
                    padding-top: 0 !important;
                    padding-bottom: 0 !important;
                }
                &-1 {
                    padding-top: $spacer--extra-small !important;
                    padding-bottom: $spacer--extra-small !important;
                }
                &-2 {
                    padding-top: $spacer !important;
                    padding-bottom: $spacer !important;
                }
                &-3 {
                    padding-top: $spacer--small !important;
                    padding-bottom: $spacer--small !important;
                }
                &-4 {
                    padding-top: $spacer--medium !important;
                    padding-bottom: $spacer--medium !important;
                }
                &-5 {
                    padding-top: $spacer--m !important;
                    padding-bottom: $spacer--m !important;
                }
                &-6 {
                    padding-top: $spacer--semi-medium !important;
                    padding-bottom: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-top: $spacer--large !important;
                    padding-bottom: $spacer--large !important;
                }
                &-8 {
                    padding-top: $spacer--l !important;
                    padding-bottom: $spacer--l !important;
                }
                &-9 {
                    padding-top: $spacer--semi-large !important;
                    padding-bottom: $spacer--semi-large !important;
                }
                &-10 {
                    padding-top: $spacer--xl !important;
                    padding-bottom: $spacer--xl !important;
                }
                &-11 {
                    padding-top: $spacer--extra-large !important;
                    padding-bottom: $spacer--extra-large !important;
                }
                &-12 {
                    padding-top: $spacer--xxl !important;
                    padding-bottom: $spacer--xxl !important;
                }
                &-13 {
                    padding-top: $spacer--extra-extra-large !important;
                    padding-bottom: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-top: $spacer--larger !important;
                    padding-bottom: $spacer--larger !important;
                }
            }
            &-hz {
                &-0 {
                    padding-right: 0 !important;
                    padding-left: 0 !important;
                }
                &-1 {
                    padding-right: $spacer--extra-small !important;
                    padding-left: $spacer--extra-small !important;
                }
                &-2 {
                    padding-right: $spacer !important;
                    padding-left: $spacer !important;
                }
                &-3 {
                    padding-right: $spacer--small !important;
                    padding-left: $spacer--small !important;
                }
                &-4 {
                    padding-right: $spacer--medium !important;
                    padding-left: $spacer--medium !important;
                }
                &-5 {
                    padding-right: $spacer--m !important;
                    padding-left: $spacer--m !important;
                }
                &-6 {
                    padding-right: $spacer--semi-medium !important;
                    padding-left: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-right: $spacer--large !important;
                    padding-left: $spacer--large !important;
                }
                &-8 {
                    padding-right: $spacer--l !important;
                    padding-left: $spacer--l !important;
                }
                &-9 {
                    padding-right: $spacer--semi-large !important;
                    padding-left: $spacer--semi-large !important;
                }
                &-10 {
                    padding-right: $spacer--xl !important;
                    padding-left: $spacer--xl !important;
                }
                &-11 {
                    padding-right: $spacer--extra-large !important;
                    padding-left: $spacer--extra-large !important;
                }
                &-12 {
                    padding-right: $spacer--xxl !important;
                    padding-left: $spacer--xxl !important;
                }
                &-13 {
                    padding-right: $spacer--extra-extra-large !important;
                    padding-left: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-right: $spacer--larger !important;
                    padding-left: $spacer--larger !important;
                }
            }
            &t {
                &-0 {
                    padding-top: 0 !important;
                }
                &-1 {
                    padding-top: $spacer--extra-small !important;
                }
                &-2 {
                    padding-top: $spacer !important;
                }
                &-3 {
                    padding-top: $spacer--small !important;
                }
                &-4 {
                    padding-top: $spacer--medium !important;
                }
                &-5 {
                    padding-top: $spacer--m !important;
                }
                &-6 {
                    padding-top: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-top: $spacer--large !important;
                }
                &-8 {
                    padding-top: $spacer--l !important;
                }
                &-9 {
                    padding-top: $spacer--semi-large !important;
                }
                &-10 {
                    padding-top: $spacer--xl !important;
                }
                &-11 {
                    padding-top: $spacer--extra-large !important;
                }
                &-12 {
                    padding-top: $spacer--xxl !important;
                }
                &-13 {
                    padding-top: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-top: $spacer--larger !important;
                }
            }
            &r {
                &-0 {
                    padding-right: 0 !important;
                }
                &-1 {
                    padding-right: $spacer--extra-small !important;
                }
                &-2 {
                    padding-right: $spacer !important;
                }
                &-3 {
                    padding-right: $spacer--small !important;
                }
                &-4 {
                    padding-right: $spacer--medium !important;
                }
                &-5 {
                    padding-right: $spacer--m !important;
                }
                &-6 {
                    padding-right: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-right: $spacer--large !important;
                }
                &-8 {
                    padding-right: $spacer--l !important;
                }
                &-9 {
                    padding-right: $spacer--semi-large !important;
                }
                &-10 {
                    padding-right: $spacer--xl !important;
                }
                &-11 {
                    padding-right: $spacer--extra-large !important;
                }
                &-12 {
                    padding-right: $spacer--xxl !important;
                }
                &-13 {
                    padding-right: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-right: $spacer--larger !important;
                }
            }
            &b {
                &-0 {
                    padding-bottom: 0 !important;
                }
                &-1 {
                    padding-bottom: $spacer--extra-small !important;
                }
                &-2 {
                    padding-bottom: $spacer !important;
                }
                &-3 {
                    padding-bottom: $spacer--small !important;
                }
                &-4 {
                    padding-bottom: $spacer--medium !important;
                }
                &-5 {
                    padding-bottom: $spacer--m !important;
                }
                &-6 {
                    padding-bottom: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-bottom: $spacer--large !important;
                }
                &-8 {
                    padding-bottom: $spacer--l !important;
                }
                &-9 {
                    padding-bottom: $spacer--semi-large !important;
                }
                &-10 {
                    padding-bottom: $spacer--xl !important;
                }
                &-11 {
                    padding-bottom: $spacer--extra-large !important;
                }
                &-12 {
                    padding-bottom: $spacer--xxl !important;
                }
                &-13 {
                    padding-bottom: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-bottom: $spacer--larger !important;
                }
            }
            &l {
                &-0 {
                    padding-left: 0 !important;
                }
                &-1 {
                    padding-left: $spacer--extra-small !important;
                }
                &-2 {
                    padding-left: $spacer !important;
                }
                &-3 {
                    padding-left: $spacer--small !important;
                }
                &-4 {
                    padding-left: $spacer--medium !important;
                }
                &-5 {
                    padding-left: $spacer--m !important;
                }
                &-6 {
                    padding-left: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-left: $spacer--large !important;
                }
                &-8 {
                    padding-left: $spacer--l !important;
                }
                &-9 {
                    padding-left: $spacer--semi-large !important;
                }
                &-10 {
                    padding-left: $spacer--xl !important;
                }
                &-11 {
                    padding-left: $spacer--extra-large !important;
                }
                &-12 {
                    padding-left: $spacer--xxl !important;
                }
                &-13 {
                    padding-left: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-left: $spacer--larger !important;
                }
            }
        }
    }
}
@include min-screen($screen-4xl) {
    .x4l {
        &-p {
            &-0 {
                padding: 0 !important;
            }
            &-1 {
                padding: $spacer--extra-small !important;
            }
            &-2 {
                padding: $spacer !important;
            }
            &-3 {
                padding: $spacer--small !important;
            }
            &-4 {
                padding: $spacer--medium !important;
            }
            &-5 {
                padding: $spacer--m !important;
            }
            &-6 {
                padding: $spacer--semi-medium !important;
            }
            &-7 {
                padding: $spacer--large !important;
            }
            &-8 {
                padding: $spacer--l !important;
            }
            &-9 {
                padding: $spacer--semi-large !important;
            }
            &-10 {
                padding: $spacer--xl !important;
            }
            &-11 {
                padding: $spacer--extra-large !important;
            }
            &-12 {
                padding: $spacer--xxl !important;
            }
            &-13 {
                padding: $spacer--extra-extra-large !important;
            }
            &-14 {
                padding: $spacer--larger !important;
            }
            &-vc {
                &-0 {
                    padding-top: 0 !important;
                    padding-bottom: 0 !important;
                }
                &-1 {
                    padding-top: $spacer--extra-small !important;
                    padding-bottom: $spacer--extra-small !important;
                }
                &-2 {
                    padding-top: $spacer !important;
                    padding-bottom: $spacer !important;
                }
                &-3 {
                    padding-top: $spacer--small !important;
                    padding-bottom: $spacer--small !important;
                }
                &-4 {
                    padding-top: $spacer--medium !important;
                    padding-bottom: $spacer--medium !important;
                }
                &-5 {
                    padding-top: $spacer--m !important;
                    padding-bottom: $spacer--m !important;
                }
                &-6 {
                    padding-top: $spacer--semi-medium !important;
                    padding-bottom: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-top: $spacer--large !important;
                    padding-bottom: $spacer--large !important;
                }
                &-8 {
                    padding-top: $spacer--l !important;
                    padding-bottom: $spacer--l !important;
                }
                &-9 {
                    padding-top: $spacer--semi-large !important;
                    padding-bottom: $spacer--semi-large !important;
                }
                &-10 {
                    padding-top: $spacer--xl !important;
                    padding-bottom: $spacer--xl !important;
                }
                &-11 {
                    padding-top: $spacer--extra-large !important;
                    padding-bottom: $spacer--extra-large !important;
                }
                &-12 {
                    padding-top: $spacer--xxl !important;
                    padding-bottom: $spacer--xxl !important;
                }
                &-13 {
                    padding-top: $spacer--extra-extra-large !important;
                    padding-bottom: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-top: $spacer--larger !important;
                    padding-bottom: $spacer--larger !important;
                }
            }
            &-hz {
                &-0 {
                    padding-right: 0 !important;
                    padding-left: 0 !important;
                }
                &-1 {
                    padding-right: $spacer--extra-small !important;
                    padding-left: $spacer--extra-small !important;
                }
                &-2 {
                    padding-right: $spacer !important;
                    padding-left: $spacer !important;
                }
                &-3 {
                    padding-right: $spacer--small !important;
                    padding-left: $spacer--small !important;
                }
                &-4 {
                    padding-right: $spacer--medium !important;
                    padding-left: $spacer--medium !important;
                }
                &-5 {
                    padding-right: $spacer--m !important;
                    padding-left: $spacer--m !important;
                }
                &-6 {
                    padding-right: $spacer--semi-medium !important;
                    padding-left: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-right: $spacer--large !important;
                    padding-left: $spacer--large !important;
                }
                &-8 {
                    padding-right: $spacer--l !important;
                    padding-left: $spacer--l !important;
                }
                &-9 {
                    padding-right: $spacer--semi-large !important;
                    padding-left: $spacer--semi-large !important;
                }
                &-10 {
                    padding-right: $spacer--xl !important;
                    padding-left: $spacer--xl !important;
                }
                &-11 {
                    padding-right: $spacer--extra-large !important;
                    padding-left: $spacer--extra-large !important;
                }
                &-12 {
                    padding-right: $spacer--xxl !important;
                    padding-left: $spacer--xxl !important;
                }
                &-13 {
                    padding-right: $spacer--extra-extra-large !important;
                    padding-left: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-right: $spacer--larger !important;
                    padding-left: $spacer--larger !important;
                }
            }
            &t {
                &-0 {
                    padding-top: 0 !important;
                }
                &-1 {
                    padding-top: $spacer--extra-small !important;
                }
                &-2 {
                    padding-top: $spacer !important;
                }
                &-3 {
                    padding-top: $spacer--small !important;
                }
                &-4 {
                    padding-top: $spacer--medium !important;
                }
                &-5 {
                    padding-top: $spacer--m !important;
                }
                &-6 {
                    padding-top: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-top: $spacer--large !important;
                }
                &-8 {
                    padding-top: $spacer--l !important;
                }
                &-9 {
                    padding-top: $spacer--semi-large !important;
                }
                &-10 {
                    padding-top: $spacer--xl !important;
                }
                &-11 {
                    padding-top: $spacer--extra-large !important;
                }
                &-12 {
                    padding-top: $spacer--xxl !important;
                }
                &-13 {
                    padding-top: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-top: $spacer--larger !important;
                }
            }
            &r {
                &-0 {
                    padding-right: 0 !important;
                }
                &-1 {
                    padding-right: $spacer--extra-small !important;
                }
                &-2 {
                    padding-right: $spacer !important;
                }
                &-3 {
                    padding-right: $spacer--small !important;
                }
                &-4 {
                    padding-right: $spacer--medium !important;
                }
                &-5 {
                    padding-right: $spacer--m !important;
                }
                &-6 {
                    padding-right: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-right: $spacer--large !important;
                }
                &-8 {
                    padding-right: $spacer--l !important;
                }
                &-9 {
                    padding-right: $spacer--semi-large !important;
                }
                &-10 {
                    padding-right: $spacer--xl !important;
                }
                &-11 {
                    padding-right: $spacer--extra-large !important;
                }
                &-12 {
                    padding-right: $spacer--xxl !important;
                }
                &-13 {
                    padding-right: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-right: $spacer--larger !important;
                }
            }
            &b {
                &-0 {
                    padding-bottom: 0 !important;
                }
                &-1 {
                    padding-bottom: $spacer--extra-small !important;
                }
                &-2 {
                    padding-bottom: $spacer !important;
                }
                &-3 {
                    padding-bottom: $spacer--small !important;
                }
                &-4 {
                    padding-bottom: $spacer--medium !important;
                }
                &-5 {
                    padding-bottom: $spacer--m !important;
                }
                &-6 {
                    padding-bottom: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-bottom: $spacer--large !important;
                }
                &-8 {
                    padding-bottom: $spacer--l !important;
                }
                &-9 {
                    padding-bottom: $spacer--semi-large !important;
                }
                &-10 {
                    padding-bottom: $spacer--xl !important;
                }
                &-11 {
                    padding-bottom: $spacer--extra-large !important;
                }
                &-12 {
                    padding-bottom: $spacer--xxl !important;
                }
                &-13 {
                    padding-bottom: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-bottom: $spacer--larger !important;
                }
            }
            &l {
                &-0 {
                    padding-left: 0 !important;
                }
                &-1 {
                    padding-left: $spacer--extra-small !important;
                }
                &-2 {
                    padding-left: $spacer !important;
                }
                &-3 {
                    padding-left: $spacer--small !important;
                }
                &-4 {
                    padding-left: $spacer--medium !important;
                }
                &-5 {
                    padding-left: $spacer--m !important;
                }
                &-6 {
                    padding-left: $spacer--semi-medium !important;
                }
                &-7 {
                    padding-left: $spacer--large !important;
                }
                &-8 {
                    padding-left: $spacer--l !important;
                }
                &-9 {
                    padding-left: $spacer--semi-large !important;
                }
                &-10 {
                    padding-left: $spacer--xl !important;
                }
                &-11 {
                    padding-left: $spacer--extra-large !important;
                }
                &-12 {
                    padding-left: $spacer--xxl !important;
                }
                &-13 {
                    padding-left: $spacer--extra-extra-large !important;
                }
                &-14 {
                    padding-left: $spacer--larger !important;
                }
            }
        }
    }
}
//sass-lint:enable no-important
