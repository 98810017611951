.sidebar {
    .filter-wrap {
        .block.filter {
            width: 100%;
            padding: $indent__base;
            background: $brand__block__background;
            border-radius: 0;
            box-shadow: none;
            position: fixed;
            z-index: 999;
            top: 0;
            bottom: 0;
            left: -100%;
            transform: translate3d(0, 0, 0);
            margin-bottom: 0;
            overflow-x: hidden;
            transition: transform 0.2s ease;

            @include min-screen($screen__m) {
                @include brand-block();

                margin-bottom: $indent__xl;
                position: static;
                width: auto;
                min-width: unset;
                transform: none;
            }

            .filter-title {
                margin-bottom: $indent__base;
                display: flex;

                strong {
                    font-family: $font-family__brand;
                    color: $brand__secondary__color;
                    font-size: 2.2rem;
                    display: block;
                    flex-grow: 1;
                }

                .filter-close {
                    font-size: 1.5em;
                    text-align: center;
                    vertical-align: middle;
                    align-self: center;
                    cursor: pointer;

                    @include min-screen($screen__m) {
                        display: none;
                    }

                    i {
                        font-weight: bold;
                    }
                }
            }

            .filter-content {
                width: 100%;
            }
        }

        .filter-background {
            display: none;
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 998;
            background: $text__color;
            opacity: 0;
            transition: opacity 3s ease;
        }

        &.hidden {
            .block-filter {
                transform: translate3d(0, 0, 0);
            }

            .filter-background {
                opacity: 0;
            }
        }

        &.visible {
            display: block;

            .block.filter {
                transform: translate3d(100%, 0, 0);
            }

            .filter-background {
                transition: opacity 3s ease;
                display: block;
                opacity: 0.4;
            }
        }

        .block-subtitle {
            display: none;
        }
    }
}

.filter-current {
    .block-subtitle {
        display: none;
    }

    .filter-group-title {
        margin-top: 5px;
        margin-bottom: 0;
    }

    .filter-label {
        font-weight: $font-weight__semibold;
    }

    .filter-value {
        text-transform: lowercase;
    }

    .action.remove {
        color: $text__color;
        text-decoration: none;
        vertical-align: top;
        float: right;
        margin-left: $indent__xs;

        i {
            vertical-align: middle;
        }
        &:before {
            content: '\f00d';
            font-family: $font-family__awesome;
        }
        span {
            display: none
        }
    }

    + .filter-actions {
        margin-top: $indent__xs;
        margin-bottom: $indent__l;

        a {
            color: $brand__primary__color;
        }
    }
}
.filter-options-item {
    margin-bottom: 2rem;

    &[data-role="collapsible"] {
        position: relative;

        .filter-options-title {
            border-bottom: 1px solid #e6e6e8;

            &:after {
                font-family: $font-family__awesome;
                position: absolute;
                right: 0;
                top: 0;
                content: '\f106';
                font-size: 17px;
            }
        }
        &.active {
            .filter-options-title {
                &:after {
                    content: '\f107';
                }
            }
        }
    }
}

.layered-top-nav {
    margin-bottom: 2rem;

    @include min-screen($screen__m) {
        display: none;
    }

    .filter-current {
        @include brand-block(
            $padding: 1rem 1rem 0.7rem 1rem
        );

        margin-bottom: 1rem;

        .block-subtitle {
            font-weight: $font-weight__semibold;
            font-size: $font-size__base;
            margin-bottom: 0.8rem;
        }

        .item.am-shopby-item {
            padding: 0.4rem 0.8rem;
            border-radius: $brand__border-radius;
            border: 1px solid $brand__secondary__color;
            display: inline-block;
            cursor: pointer;
            margin: 0 0.5rem 0.5rem 0;

            &:hover {
                background: $brand__secondary__color;
                color: $color-white;

                .action.remove {
                    color: $color-white;
                }
            }
        }

        .filter-group-title {
            display: block;
            margin-top: 0;
            margin-bottom: $indent__xs;
        }
    }

    .layered-top-nav-actions {
        text-align: center;

        .btn {
            @include button-cta();

            display: block;
            width: 100%;
        }
    }
}

.filter-options-content {
    .filter-count-label {
        @extend .abs-visually-hidden;
    }

    .amshopby-slider-container .am-slider .ui-slider-handle {
        background: $brand__primary__color;

        &:hover {
            background: $brand__primary__color;
        }
    }
}

.filter-options {
    margin: 0;

    .filter-options-title {
        font-family: $font-family__brand;
        word-break: break-all;
        font-weight: $font-weight__heavier;
        margin: 0 0 0.7rem;
        display: flex;
        cursor: pointer;

        span {
            flex-grow: 1;
        }

        .filter-options-expand {
            cursor: pointer;
            color: $brand__secondary__color;
        }
    }

    .filter-options-content {
        margin: 0 0 $indent__m;
        line-height: 1.5;

        &:last-child {
            margin-bottom: 0;
        }

        .item {
            margin-bottom: 3px;
            text-decoration: none;

            a,
            .label {
                text-decoration: none;
                color: $text__color;
            }

            &.hidden {
                display: none;
            }

            &.item-more-less {
                padding: 0 0 0.4rem;
                font-weight: $font-weight__bold;
                cursor: pointer;

                .less {
                    display: none;
                }
            }
        }
    }

    .count {
        color: $text__color-muted;

        &:before {
            content: '(';
        }

        &:after {
            content: ')';
        }
    }
}

.items {
    @extend .abs-reset-list;
}

.filtered {
    .items {
        margin: 15px 0;
    }

    .item {
        margin-bottom: 6px;
        padding-left: 22px;
        position: relative;

        .label {
            font-weight: $font-weight__bold;
        }

        .action.remove {
            @extend .abs-remove-button-for-blocks;
            left: -6px;
            position: absolute;
            top: 0;
        }
    }

    & + .actions {
        margin-bottom: 35px;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .page-with-filter {
        .columns {
            .sidebar-main {
                @include lib-vendor-prefix-order(2);
            }
        }
    }
}
