.product-grid-item {
    &--slider-item {
        @media screen and (min-screen: #{$screen-l}) {
            padding: $spacer--medium;
            border: 1px solid $theme-primary;
            border-radius: 4px;
            box-shadow: $primary-box-shadow;
        }
    }
}
