//prevent document from scrolling
//sass-lint: disable no-important
.no-scroll {
    overflow: hidden !important;
    height: 100% !important;
}

// margin
.m-0 {
    margin: 0 !important;
}
.m-xs {
    margin: $spacer !important;

}
.m-s {
    margin: $spacer--small !important;

}
.m-m {
    margin: $spacer--medium !important;

}
.m-sm {
    margin: $spacer--semi-medium !important;

}
.m-l {
    margin: $spacer--large !important;

}
.m-sl {
    margin: $spacer--semi-large !important;

}
.m-xl {
    margin: $spacer--extra-large !important;

}
.m-auto {
    margin: auto !important;
}

// margin-top
.mt-0 {
    margin-top: 0 !important;
}
.mt-xs {
    margin-top: $spacer !important;

}
.mt-s {
    margin-top: $spacer--small !important;

}
.mt-m {
    margin-top: $spacer--medium !important;

}
.mt-sm {
    margin-top: $spacer--semi-medium !important;

}
.mt-l {
    margin-top: $spacer--large !important;

}
.mt-sl {
    margin-top: $spacer--semi-large !important;

}
.mt-xl {
    margin-top: $spacer--extra-large !important;

}
.mt-auto {
    margin-top: auto !important;
}

// margin-bottom
.mb-0 {
    margin-bottom: 0 !important;
}
.mb-xs {
    margin-bottom: $spacer !important;

}
.mb-s {
    margin-bottom: $spacer--small !important;

}
.mb-m {
    margin-bottom: $spacer--medium !important;

}
.mb-sm {
    margin-bottom: $spacer--semi-medium !important;

}
.mb-l {
    margin-bottom: $spacer--large !important;

}
.mb-sl {
    margin-bottom: $spacer--semi-large !important;

}
.mb-xl {
    margin-bottom: $spacer--extra-large !important;

}
.mb-auto {
    margin-bottom: auto !important;
}

// margin-left
.ml-0 {
    margin-left: 0 !important;
}
.ml-xs {
    margin-left: $spacer !important;

}
.ml-s {
    margin-left: $spacer--small !important;

}
.ml-m {
    margin-left: $spacer--medium !important;

}
.ml-sm {
    margin-left: $spacer--semi-medium !important;

}
.ml-l {
    margin-left: $spacer--large !important;

}
.ml-sl {
    margin-left: $spacer--semi-large !important;

}
.ml-xl {
    margin-left: $spacer--extra-large !important;

}
.ml-auto {
    margin-left: auto !important;
}

// margin-right
.mr-0 {
    margin-right: 0 !important;
}
.mr-xs {
    margin-right: $spacer !important;

}
.mr-s {
    margin-right: $spacer--small !important;

}
.mr-m {
    margin-right: $spacer--medium !important;

}
.mr-sm {
    margin-right: $spacer--semi-medium !important;

}
.mr-l {
    margin-right: $spacer--large !important;

}
.mr-sl {
    margin-right: $spacer--semi-large !important;

}
.mr-xl {
    margin-right: $spacer--extra-large !important;

}
.mr-auto {
    margin-right: auto !important;
}

// margin horizontal
.mx-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.mx-xs {
    margin-left: $spacer !important;
    margin-right: $spacer !important;

}
.mx-s {
    margin-left: $spacer--small !important;
    margin-right: $spacer--small !important;

}
.mx-m {
    margin-left: $spacer--medium !important;
    margin-right: $spacer--medium !important;
}
.mx-sm {
    margin-left: $spacer--semi-medium !important;
    margin-right: $spacer--semi-medium !important;

}
.mx-l {
    margin-left: $spacer--large !important;
    margin-right: $spacer--large !important;

}
.mx-sl {
    margin-left: $spacer--semi-large !important;
    margin-right: $spacer--semi-large !important;

}
.mx-xl {
    margin-left: $spacer--extra-large !important;
    margin-right: $spacer--extra-large !important;

}
.mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;

}

// margin vertical
.my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;

}
.my-xs {
    margin-top: $spacer !important;
    margin-bottom: $spacer !important;

}
.my-s {
    margin-top: $spacer--small !important;
    margin-bottom: $spacer--small !important;

}
.my-m {
    margin-top: $spacer--medium !important;
    margin-bottom: $spacer--medium !important;

}
.my-sm {
    margin-top: $spacer--semi-medium !important;
    margin-bottom: $spacer--semi-medium !important;

}
.my-l {
    margin-top: $spacer--large !important;
    margin-bottom: $spacer--large !important;

}
.my-sl {
    margin-top: $spacer--semi-large !important;
    margin-bottom: $spacer--semi-large !important;

}
.my-xl {
    margin-top: $spacer--extra-large !important;
    margin-bottom: $spacer--extra-large !important;

}
.my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

// padding
.padding-0 {
    padding: 0 !important;
}
.padding-xs {
    padding: $spacer !important;

}
.padding-s {
    padding: $spacer--small !important;

}
.padding-m {
    padding: $spacer--medium !important;

}
.padding-sm {
    padding: $spacer--semi-medium !important;

}
.padding-l {
    padding: $spacer--large !important;

}
.padding-sl {
    padding: $spacer--semi-large !important;

}
.padding-xl {
    padding: $spacer--extra-large !important;

}

// padding-top
.pt-0 {
    padding-top: 0 !important;
}
.pt-xs {
    padding-top: $spacer !important;

}
.pt-s {
    padding-top: $spacer--small !important;

}
.pt-m {
    padding-top: $spacer--medium !important;

}
.pt-sm {
    padding-top: $spacer--semi-medium !important;

}
.pt-l {
    padding-top: $spacer--large !important;

}
.pt-sl {
    padding-top: $spacer--semi-large !important;

}
.pt-xl {
    padding-top: $spacer--extra-large !important;

}

// padding-bottom
.pb-0 {
    padding-bottom: 0 !important;
}
.pb-xs {
    padding-bottom: $spacer !important;

}
.pb-s {
    padding-bottom: $spacer--small !important;

}
.pb-m {
    padding-bottom: $spacer--medium !important;

}
.pb-sm {
    padding-bottom: $spacer--semi-medium !important;

}
.pb-l {
    padding-bottom: $spacer--large !important;

}
.pb-sl {
    padding-bottom: $spacer--semi-large !important;

}
.pb-xl {
    padding-bottom: $spacer--extra-large !important;

}

// padding-left
.pl-0 {
    padding-left: 0 !important;
}
.pl-xs {
    padding-left: $spacer !important;

}
.pl-s {
    padding-left: $spacer--small !important;

}
.pl-m {
    padding-left: $spacer--medium !important;

}
.pl-sm {
    padding-left: $spacer--semi-medium !important;

}
.pl-l {
    padding-left: $spacer--large !important;

}
.pl-sl {
    padding-left: $spacer--semi-large !important;

}
.pl-xl {
    padding-left: $spacer--extra-large !important;

}

// padding-right
.pr-0 {
    padding-right: 0 !important;

}
.pr-xs {
    padding-right: $spacer !important;

}
.pr-s {
    padding-right: $spacer--small !important;

}
.pr-m {
    padding-right: $spacer--medium !important;

}
.pr-sm {
    padding-right: $spacer--semi-medium !important;

}
.pr-l {
    padding-right: $spacer--large !important;

}
.pr-sl {
    padding-right: $spacer--semi-large !important;

}
.pr-xl {
    padding-right: $spacer--extra-large !important;

}

// padding horizontal
.px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.px-xs {
    padding-left: $spacer !important;
    padding-right: $spacer !important;

}
.px-s {
    padding-left: $spacer--small !important;
    padding-right: $spacer--small !important;

}
.px-m {
    padding-left: $spacer--medium !important;
    padding-right: $spacer--medium !important;

}
.px-sm {
    padding-left: $spacer--semi-medium !important;
    padding-right: $spacer--semi-medium !important;

}
.px-l {
    padding-left: $spacer--large !important;
    padding-right: $spacer--large !important;

}
.px-sl {
    padding-left: $spacer--semi-large !important;
    padding-right: $spacer--semi-large !important;

}
.px-xl {
    padding-left: $spacer--extra-large !important;
    padding-right: $spacer--extra-large !important;

}

// padding vertical
.py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.py-xs {
    padding-top: $spacer !important;
    padding-bottom: $spacer !important;

}
.py-s {
    padding-top: $spacer--small !important;
    padding-bottom: $spacer--small !important;

}
.py-m {
    padding-top: $spacer--medium !important;
    padding-bottom: $spacer--medium !important;

}
.py-sm {
    padding-top: $spacer--semi-medium !important;
    padding-bottom: $spacer--semi-medium !important;

}
.py-l {
    padding-top: $spacer--large !important;
    padding-bottom: $spacer--large !important;

}
.py-sl {
    padding-top: $spacer--semi-large !important;
    padding-bottom: $spacer--semi-large !important;

}
.py-xl {
    padding-top: $spacer--extra-large !important;
    padding-bottom: $spacer--extra-large !important;

}
.py-xxl {
    padding-top: $spacer--extra-extra-large !important;
    padding-bottom: $spacer--extra-extra-large !important;

}

// dividers
.divider--1 {
    margin-bottom: 1rem !important;
}
.divider--2 {
    margin-bottom: 2rem !important;
}
.divider--3 {
    margin-bottom: 3rem !important;
}
.divider--4 {
    margin-bottom: 4rem !important;
}
.divider--5 {
    margin-bottom: 5rem !important;
}
.divider--6 {
    margin-bottom: 6rem !important;
}
.divider--7 {
    margin-bottom: 7rem !important;
}
.divider--8 {
    margin-bottom: 8rem !important;
}
.divider--9 {
    margin-bottom: 9rem !important;
}
.divider--10 {
    margin-bottom: 10rem !important;
}
// borders
.border-primary {
    border: $border-base !important;
}
.border-secondary {
    border: $border-secondary !important;
}
.border-color-primary {
    border-color: $color-primary !important;
}
.border-color-secondary {
    border-color: $color-secondary !important;
}
.border-color-light {
    border-color: $gray !important;
}
.border-none {
    border: none !important;
}
.border-width-1 {
    border-width: 1px !important;
}
.border-width-2 {
    border-width: 2px !important;
}

// text alignment
.text-center {
    text-align: center !important;
}
.text-left {
    text-align: left !important;
}
.text-right {
    text-align: right !important;
}
:hover {
    .underling {
        text-decoration: underline !important;
    }
    .no-underline {
        text-decoration: none !important;
    }
}
// text transform
.uppercase {
    text-transform: uppercase !important;
}

.lowercase {
    text-transform: lowercase !important;
}

.none-text-transform {
    text-transform: none !important;
}

// display none
.display-none {
    display: none;
}

.display-none-s {
    @include max-screen($screen-s - 1) {
        display: none !important;
    }
}

.display-none-m {
    @include max-screen($screen-m - 1) {
        display: none !important;
    }
}
.display-none-desktop {
    @include min-screen($screen-l) {
        display: none !important;
    }
}
.display-none-l {
    @include max-screen($screen-l - 1) {
        display: none !important;
    }
}

.display-none-xl {
    @include max-screen($screen-xl - 1) {
        display: none !important;
    }
}

.position-relative {
    position: relative !important;
}

.position-absolute {
    position: absolute !important;
}
@include min-screen($screen-m - 1) {
    .md-position-absolute {
        position: absolute !important;
    }
}
@include min-screen($screen-l - 1) {
    .lg-position-absolute {
        position: absolute !important;
    }
}

// icon size
.icon {
    &.size-xs {
        width: $spacer--extra-small !important;
        height: $spacer--extra-small !important;
    }

    &.size-normal {
        width: $spacer !important;
        height: $spacer !important;
    }

    &.size-small {
        width: $spacer--small !important;
        height: $spacer--small !important;
    }

    &.size-medium {
        width: $spacer--medium !important;
        height: $spacer--medium !important;
    }

    &.size-m {
        width: $spacer--m !important;
        height: $spacer--m !important;
    }

    &.size-sm {
        width: $spacer--semi-medium !important;
        height: $spacer--semi-medium !important;
    }

    &.size-large {
        width: $spacer--large !important;
        height: $spacer--large !important;
    }

    &.size-l {
        width: $spacer--l !important;
        height: $spacer--l !important;
    }

    &.size-sl {
        width: $spacer--semi-large !important;
        height: $spacer--semi-large !important;
    }

    &.size-xl {
        width: $spacer--xl !important;
        height: $spacer--xl !important;
    }

    &.size-extra-large {
        width: $spacer--extra-large !important;
        height: $spacer--extra-large !important;
    }

    &.size-xxl {
        width: $spacer--xxl !important;
        height: $spacer--xxl !important;
    }

    &.size-extra-extra-large {
        width: $spacer--extra-extra-large !important;
        height: $spacer--extra-extra-large !important;
    }
}

// font-size
.size {
    &-xs {
        font-size: $spacer--extra-small !important;
    }
    &-s {
        font-size: $spacer--small !important;
    }
    &-m {
        font-size: $spacer--medium !important;
    }
    &-sm {
        font-size: $spacer--semi-medium !important;
    }
    &-l {
        font-size: $spacer--large !important;
    }
    &-sl {
        font-size: $spacer--semi-large !important;
    }
    &-xl {
        font-size: $spacer--extra-large !important;
    }
    &-xxl {
        font-size: $spacer--xxl !important;
    }
}
.weight {
    &-300 {
        font-weight: 300 !important;
    }
    &-400 {
        font-weight: 400 !important;
    }
    &-500 {
        font-weight: 500 !important;
    }
    &-600 {
        font-weight: 600 !important;
    }
    &-700 {
        font-weight: 700 !important;
    }
    &-800 {
        font-weight: 800 !important;
    }
    &-900 {
        font-weight: 900 !important;
    }
}

.text-shadow {
    text-shadow: 1px 1px black;
    &-white {
        text-shadow: 1px 1px white;
    }
}

// text-transform
.normal-case {
    text-transform: none !important;
}
.capitalize {
    text-transform: capitalize !important;
}
.lowercase {
    text-transform: lowercase !important;
}
.uppercase {
    text-transform: uppercase !important;
}

// color
.color,
.fill {
    &-white {
        color: $white !important;
        fill: $white !important;
    }

    &-black {
        color: $black !important;
        fill: $black !important;
    }

    &-red {
        color: $red !important;
        fill: $red !important;
    }

    &-orange {
        color: $orange !important;
        fill: $orange !important;
    }

    &-orange-dark {
        color: $orange-dark !important;
        fill: $orange-dark !important;
    }

    &-yellow {
        color: $yellow !important;
        fill: $yellow !important;
    }

    &-green {
        color: $green !important;
        fill: $green !important;
    }

    &-blue {
        color: $blue !important;
        fill: $blue !important;
    }

    &-bright-sky-blue {
        color: $bright-sky-blue !important;
        fill: $bright-sky-blue !important;
    }

    &-brown {
        color: $brown !important;
        fill: $brown !important;
    }

    &-teal {
        color: $teal !important;
        fill: $teal !important;
    }

    &-pink {
        color: $pink !important;
        fill: $pink !important;
    }

    &-purple {
        color: $purple !important;
        fill: $purple !important;
    }

    &-seafoam-blue {
        color: $seafoam-blue !important;
        fill: $seafoam-blue !important;
    }

    &-gray-darkest {
        color: $gray-darkest !important;
        fill: $gray-darkest !important;
    }

    &-gray-darker {
        color: $gray-darker !important;
        fill: $gray-darker !important;
    }

    &-gray-dark {
        color: $gray-dark !important;
        fill: $gray-dark !important;
    }

    &-gray {
        color: $gray !important;
        fill: $gray !important;
    }

    &-gray-light {
        color: $gray-light !important;
        fill: $gray-light !important;
    }

    &-gray-lighter {
        color: $gray-lighter !important;
        fill: $gray-lighter !important;
    }

    &-gray-lightest {
        color: $gray-lightest !important;
        fill: $gray-lightest !important;
    }

    &-theme-gold {
        color: $theme-gold !important;
        fill: $theme-gold !important;
    }

    &-theme-primary {
        color: $theme-primary !important;
        fill: $theme-primary !important;
    }

    &-theme-second {
        color: $theme-second !important;
        fill: $theme-second !important;
    }

    &-theme-dark {
        color: $theme-dark !important;
        fill: $theme-dark !important;
    }

    &-primary {
        color: $color-primary !important;
        fill: $color-primary !important;
    }

    &-secondary {
        color: $color-secondary !important;
        fill: $color-secondary !important;
    }

    &-tertiary {
        color: $color-tertiary !important;
        fill: $color-tertiary !important;
    }

    &-success {
        color: $color-success !important;
        fill: $color-success !important;
    }

    &-info {
        color: $color-info !important;
        fill: $color-info !important;
    }

    &-warning {
        color: $color-warning !important;
        fill: $color-warning !important;
    }

    &-error {
        color: $color-error !important;
        fill: $color-error !important;
    }

    &-inverse {
        color: $color-inverse !important;
        fill: $color-inverse !important;
    }

    &-focus {
        color: $color-focus !important;
        fill: $color-focus !important;
    }
}

// background-color
.bg {
    &-white {
        background-color: $white !important;
    }

    &-black {
        background-color: $black !important;
    }

    &-red {
        background-color: $red !important;
    }

    &-orange {
        background-color: $orange !important;
    }

    &-orange-dark {
        background-color: $orange-dark !important;
    }

    &-yellow {
        background-color: $yellow !important;
    }

    &-green {
        background-color: $green !important;
    }

    &-blue {
        background-color: $blue !important;
    }

    &-bright-sky-blue {
        background-color: $bright-sky-blue !important;
    }

    &-brown {
        background-color: $brown !important;
    }

    &-teal {
        background-color: $teal !important;
    }

    &-pink {
        background-color: $pink !important;
    }

    &-purple {
        background-color: $purple !important;
    }

    &-seafoam-blue {
        background-color: $seafoam-blue !important;
    }

    &-gray-darkest {
        background-color: $gray-darkest !important;
    }

    &-gray-darker {
        background-color: $gray-darker !important;
    }

    &-gray-dark {
        background-color: $gray-dark !important;
    }

    &-gray {
        background-color: $gray !important;
    }

    &-gray-light {
        background-color: $gray-light !important;
    }

    &-gray-lighter {
        background-color: $gray-lighter !important;
    }

    &-gray-lightest {
        background-color: $gray-lightest !important;
    }

    &-theme-gold {
        background-color: $theme-gold !important;
    }

    &-theme-primary {
        background-color: $theme-primary !important;
    }

    &-theme-second {
        background-color: $theme-second !important;
    }

    &-theme-dark {
        background-color: $theme-dark !important;
    }

    &-primary {
        background-color: $color-primary !important;
    }

    &-secondary {
        background-color: $color-secondary !important;
    }

    &-tertiary {
        background-color: $color-tertiary !important;
    }

    &-success {
        background-color: $color-success !important;
    }

    &-info {
        background-color: $color-info !important;
    }

    &-warning {
        background-color: $color-warning !important;
    }

    &-error {
        background-color: $color-error !important;
    }

    &-inverse {
        background-color: $color-inverse !important;
    }

    &-focus {
        background-color: $color-focus !important;
    }
}
.box-shadow {
    box-shadow: 0 0 5px $gray !important;
    &-white {
        box-shadow: 0 0 5px $white !important;
    }
    &-light {
        box-shadow: 0 0 5px $gray-lighter !important;
    }
    &-dark {
        box-shadow: 0 0 5px $gray-dark !important;
    }
    &-black {
        box-shadow: 0 0 5px $black !important;
    }
}
.no-shadow {
    box-shadow: none !important;
}
.hover\: {
    &bg {
        &-white:hover {
            background-color: $white !important;
        }

        &-black:hover {
            background-color: $black !important;
        }

        &-red:hover {
            background-color: $red !important;
        }

        &-orange:hover {
            background-color: $orange !important;
        }

        &-orange-dark:hover {
            background-color: $orange-dark !important;
        }

        &-yellow:hover {
            background-color: $yellow !important;
        }

        &-green:hover {
            background-color: $green !important;
        }

        &-blue:hover {
            background-color: $blue !important;
        }

        &-bright-sky-blue:hover {
            background-color: $bright-sky-blue !important;
        }

        &-brown:hover {
            background-color: $brown !important;
        }

        &-teal:hover {
            background-color: $teal !important;
        }

        &-pink:hover {
            background-color: $pink !important;
        }

        &-purple:hover {
            background-color: $purple !important;
        }

        &-seafoam-blue:hover {
            background-color: $seafoam-blue !important;
        }

        &-gray-darkest:hover {
            background-color: $gray-darkest !important;
        }

        &-gray-darker:hover {
            background-color: $gray-darker !important;
        }

        &-gray-dark:hover {
            background-color: $gray-dark !important;
        }

        &-gray:hover {
            background-color: $gray !important;
        }

        &-gray-light:hover {
            background-color: $gray-light !important;
        }

        &-gray-lighter:hover {
            background-color: $gray-lighter !important;
        }

        &-gray-lightest:hover {
            background-color: $gray-lightest !important;
        }

        &-theme-gold:hover {
            background-color: $theme-gold !important;
        }

        &-theme-primary:hover {
            background-color: $theme-primary !important;
        }

        &-theme-second:hover {
            background-color: $theme-second !important;
        }

        &-theme-dark:hover {
            background-color: $theme-dark !important;
        }

        &-primary:hover {
            background-color: $color-primary !important;
        }

        &-secondary:hover {
            background-color: $color-secondary !important;
        }

        &-tertiary:hover {
            background-color: $color-tertiary !important;
        }

        &-success:hover {
            background-color: $color-success !important;
        }

        &-info:hover {
            background-color: $color-info !important;
        }

        &-warning:hover {
            background-color: $color-warning !important;
        }

        &-error:hover {
            background-color: $color-error !important;
        }

        &-inverse:hover {
            background-color: $color-inverse !important;
        }

        &-focus:hover {
            background-color: $color-focus !important;
        }
    }
    &color {
        &-white:hover {
            color: $white !important;
        }

        &-black:hover {
            color: $black !important;
        }

        &-red:hover {
            color: $red !important;
        }

        &-orange:hover {
            color: $orange !important;
        }

        &-orange-dark:hover {
            color: $orange-dark !important;
        }

        &-yellow:hover {
            color: $yellow !important;
        }

        &-green:hover {
            color: $green !important;
        }

        &-blue:hover {
            color: $blue !important;
        }

        &-bright-sky-blue:hover {
            color: $bright-sky-blue !important;
        }

        &-brown:hover {
            color: $brown !important;
        }

        &-teal:hover {
            color: $teal !important;
        }

        &-pink:hover {
            color: $pink !important;
        }

        &-purple:hover {
            color: $purple !important;
        }

        &-seafoam-blue:hover {
            color: $seafoam-blue !important;
        }

        &-gray-darkest:hover {
            color: $gray-darkest !important;
        }

        &-gray-darker:hover {
            color: $gray-darker !important;
        }

        &-gray-dark:hover {
            color: $gray-dark !important;
        }

        &-gray:hover {
            color: $gray !important;
        }

        &-gray-light:hover {
            color: $gray-light !important;
        }

        &-gray-lighter:hover {
            color: $gray-lighter !important;
        }

        &-gray-lightest:hover {
            color: $gray-lightest !important;
        }

        &-theme-gold:hover {
            color: $theme-gold !important;
        }

        &-theme-primary:hover {
            color: $theme-primary !important;
        }

        &-theme-second:hover {
            color: $theme-second !important;
        }

        &-theme-dark:hover {
            color: $theme-dark !important;
        }

        &-primary:hover {
            color: $color-primary !important;
        }

        &-secondary:hover {
            color: $color-secondary !important;
        }

        &-tertiary:hover {
            color: $color-tertiary !important;
        }

        &-success:hover {
            color: $color-success !important;
        }

        &-info:hover {
            color: $color-info !important;
        }

        &-warning:hover {
            color: $color-warning !important;
        }

        &-error:hover {
            color: $color-error !important;
        }

        &-inverse:hover {
            color: $color-inverse !important;
        }

        &-focus:hover {
            color: $color-focus !important;
        }
    }
}
.box-shadow {
    box-shadow: 0 0 5px $gray !important;
    &-white {
        box-shadow: 0 0 5px $white !important;
    }
    &-light {
        box-shadow: 0 0 5px $gray-lighter !important;
    }
    &-dark {
        box-shadow: 0 0 5px $gray-dark !important;
    }
    &-black {
        box-shadow: 0 0 5px $black !important;
    }
}
@include min-screen($screen-m) {
    .md-flex-column {
        flex-direction: column !important;
    }
    .md-flex-column-rev {
        flex-direction: column-reverse !important;
    }
    .md-flex-row-rev {
        flex-direction: row-reverse !important;
    }
}

@media screen and (max-width: $screen-m) {
    .xs-flex-column {
        flex-direction: column !important;
    }
    .xs-flex-column-rev {
        flex-direction: column-reverse !important;
    }
    .xs-flex-row-rev {
        flex-direction: row-reverse !important;
    }
}
.border-radius {
    border-radius: $border-radius !important;
}
.bg-transparent {
    background: transparent !important;

}
.top {
    top: 0 !important;
}
.left {
    left: 0 !important;
}
.right {
    right: 0 !important;
}
.bottom {
    bottom: 0 !important;
}
.rounded {
    border-radius: 50% !important;
    height: 100% !important;
    width: 100% !important;
    justify-content: center !important;
}
.rounded-left {
    border-radius: 0 50% 50% 0 !important;
    height: 100% !important;
    width: 40% !important;
    justify-content: center !important;
}
.rounded-right {
    border-radius: 50% 0 0 50% !important;
    height: 100% !important;
    width: 40% !important;
    justify-content: center !important;
}
.rounded-1 {
    border-radius: 1rem !important;
}
.rounded-15 {
    border-radius: 1.5rem !important;
}
.rounded-2 {
    border-radius: 2rem !important;
}
.rounded-25 {
    border-radius: 2.5rem !important;
}
.rounded-3 {
    border-radius: 3rem !important;
}
.rounded-35 {
    border-radius: 3.5rem !important;
}
.rounded-4 {
    border-radius: 4rem !important;
}
.rounded-45 {
    border-radius: 4.5rem !important;
}
.rounded-5 {
    border-radius: 5rem !important;
}
@include min-screen($screen-m - 1) {
    .md-px-xs {
        padding-left: $spacer !important;
        padding-right: $spacer !important;
    }
    .md-px-s {
        padding-left: $spacer--small !important;
        padding-right: $spacer--small !important;
    }
    .md-rounded {
        border-radius: 50% !important;
        height: 100% !important;
        width: 100% !important;
        justify-content: center !important;
    }
    .md-rounded-left {
        border-radius: 0 50% 50% 0 !important;
        height: 100% !important;
        width: 40% !important;
        justify-content: center !important;
    }
    .md-rounded-right {
        border-radius: 50% 0 0 50% !important;
        height: 100% !important;
        width: 30% !important;
        justify-content: center !important;
    }
}
@include min-screen($screen-l - 1) {
    .lg-px-xs {
        padding-left: $spacer !important;
        padding-right: $spacer !important;
    }
    .lg-px-s {
        padding-left: $spacer--small !important;
        padding-right: $spacer--small !important;
    }
    .lg-rounded {
        border-radius: 50% !important;
        height: 100% !important;
        width: 100% !important;
        justify-content: center !important;
    }
    .lg-rounded-left {
        border-radius: 0 50% 50% 0 !important;
        height: 100% !important;
        width: 40% !important;
        justify-content: center !important;
    }
    .lg-rounded-right {
        border-radius: 50% 0 0 50% !important;
        height: 100% !important;
        width: 30% !important;
        justify-content: center !important;
    }
}

.width-full {
    width: 100%;
}
.column-border {
    border-color: $gray-light !important;
    &--right {
        border-right: 1px solid !important;
    }
    &--left {
        border-left: 1px solid !important;
    }
    &--top {
        border-top: 1px solid !important;
    }
    &--bottom {
        border-bottom: 1px solid !important;
    }
    &--x {
        border-right: 1px solid !important;
        border-left: 1px solid !important;
    }
    &--y {
        border-top: 1px solid !important;
        border-bottom: 1px solid !important;
    }
    &--all {
        border: 1px !important;
    }
}
@include min-screen($screen-l - 1) {
    .lg {
        &-column-border {
            border-color: $gray-light !important;
            &--right {
                border-right: 1px solid !important;
            }
            &--left {
                border-left: 1px solid !important;
            }
            &--top {
                border-top: 1px solid !important;
            }
            &--bottom {
                border-bottom: 1px solid !important;
            }
            &--x {
                border-right: 1px solid !important;
                border-left: 1px solid !important;
            }
            &--y {
                border-top: 1px solid !important;
                border-bottom: 1px solid !important;
            }
            &--all {
                border: 1px !important;
            }
        }
    }
}
.aspect-auto-1\/1 {
    aspect-ratio: auto 1 / 1 !important;
}

//sass-lint: enable no-important

@import "background";
@import "color";
@import "container";
@import "flexbox";
@import "font-size";
@import "gap";
@import "margin";
@import "padding";
