.main .amrelated-pack-wrapper {
    padding: 0 $indent__base 0 $indent__base;

    .amrelated-title {
        font-family: $font-family__brand;
        font-weight: $font-weight__heavier;
        font-size: 2rem;
        color: $brand__secondary__color;

        @include min-screen($screen__m) {
            font-size: 2.4rem;
        }

        strong {
            font-weight: $font-weight__semibold;
        }

        &:after {
            display: none;
        }
    }

    .amrelated-content-wrapper {
        background-color: $color-white;
        margin-top: 0;
        padding-top: 0;

        @include  min-screen($screen__m) {
            margin-top: $indent__s;
        }

        .amrelated-pack-item {
            margin-bottom: $indent__s;
            padding: $indent__m;
            border: 0;

            &:not(:last-child) {
                border-bottom: 1px solid $brand__gray__color-lighter;
            }

            @include min-screen($screen__m) {
                min-width: 200px;
                width: 200px;
                padding: 0;
            }

            .amrelated-info {
                @include min-screen($screen__l) {
                    display: block;
                }
            }

            .price-box {
                margin: $indent__s 0;
                display: flex;
                align-items: center;
                justify-content: flex-start;

                @include min-screen($screen__m) {
                    justify-content: center;
                }

                .special-price {
                    order: 2;
                }

                .old-price {
                    margin-right: $indent__s;

                    .price,
                    .price-including-tax {
                        font-size: $font-size__base;
                        font-weight: $font-weight__regular;
                        color: $brand__gray__color;
                    }
                }

                .price {
                    font-size: $font-size__xl;
                    font-weight: $font-weight__bold;
                    color: $text__color-muted;
                }
            }
        }

        .amrelated-pack-list {
            max-width: unset;
            justify-content: unset;
            border: none;

            .amrelated-pack-items {
                justify-content: unset;

                .amrelated-background {
                    display: none;
                }
            }

            .amrelated-plus {
                padding: $indent__base;
                font-size: 5rem;
                color: $brand__secondary__color;

                @include min-screen($screen__m) {
                    display: flex;
                }
            }
        }

        .amrelated-photo-container {
            border: 0;
            height: 100%;
            width: 33%;
            min-width: 120px;
            max-width: 150px;
            margin-bottom: 0;

            @include min-screen($screen__m) {
                height: 190px;
                width: 100%;
                max-width: unset;
            }

            .amrelated-photo {
                height: 100%;
                width: 100%;

                @include max-screen($screen__m) {
                    @include brand-shadow-box();
                }
            }

            .amrelated-discount {
                background-color: $brand__primary__color;
                top: -$indent__base;
                right: 0;

                @include min-screen($screen__m) {
                    top: 0;
                    right: 0;
                }
            }
        }

        .amrelated-details {
            margin-left: 0;
            padding-left: $indent__s;
            display: block;

            @include min-screen($screen__m) {
                display: flex;
                padding: $indent__s;
            }

            .product-reviews-summary {
                display: flex;
                flex-direction: column;
                width: 100%;

                @include min-screen($screen__m) {
                    justify-content: center;
                    align-items: flex-end;
                }
            }
        }

        .amrelated-bottom {
            order: 1;
            display: flex;
            flex-direction: column;
            text-align: left;

            @include min-screen($screen__m) {
                text-align: center;
            }

            .amrelated-name {
                @include min-screen($screen__m) {
                    text-align: center;
                }
                a {
                    font-size: $font-size__l;
                    font-weight: $font-weight__semibold;
                    line-height: 1;
                    color: $text__color-muted;
                }
            }

            .price {
                color: $brand__primary__color;
            }
        }

        .amrelated-equal {
            padding: $indent__base;
            font-size: 5rem;
            color: $brand__secondary__color;
            margin-left: auto;

            @include min-screen($screen__m) {
                display: flex;
            }
        }
    }

    .amrelated-summary {
        max-height: 340px;
        width: 100%;
        border: 1px solid $brand__gray__color-lighter;

        @include min-screen($screen__m) {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 190px;
            margin-left: auto;
        }

        .amrelated-summary-content {
            height: 100%;
            width: unset;
            margin-top: 0;
            justify-content: unset;

            .amrelated-price-wrapper {
                border: 0;
                flex-direction: column;
                margin: 0;
                justify-content: unset;
                padding: $indent__base $indent__xl 0 0;
                width: 50%;

                @include min-screen($screen__m) {
                    justify-content: flex-end;
                    border-bottom: 1px solid $brand__accent;
                    height: 190px;
                    padding: $indent__base;
                    width: 100%;
                }

                .amrelated-discount {
                    position: unset;
                    background-color: $brand__primary__color;
                    top: $indent__l;
                    font-size: $font-size__s;
                    width: 80%;

                    @include min-screen($screen__m) {
                        position: absolute;
                    }
                }

                .discount-total-label {
                    display: none;

                    @include min-screen($screen__m) {
                        font-size: 1.6rem;
                        font-weight: $font-weight__regular;
                        color: $text__color-muted;
                        display: block;
                        align-self: flex-start;
                    }
                }

                .main.price {
                    font-size: 2.4rem;
                    color: $text__color-muted;

                    @include min-screen($screen__m) {
                        font-size: 3rem;
                        align-self: flex-start;
                    }
                }
            }

            .amrelated-tocart-wrapper {
                padding: $indent__s;
                display: flex;
                align-items: flex-end;
                width: 50%;

                @include min-screen($screen__m) {
                    width: 100%;
                }

                .action {
                    background-color: $brand__cta__color;
                    padding: $indent__s $indent__m;
                    width: 100%;
                    font-size: $font-size__s;

                    @include min-screen($screen__xs) {
                        font-size: $font-size__base;
                    }

                    @include min-screen($screen__m) {
                        margin-top: 33%;
                    }
                }
            }
        }
    }
}

// Related products, also concern the regular related products
body.catalog-product-view .amrelated-grid-wrapper {
    padding: 0 $indent__base;
    z-index: 1;
    position: relative;

    .products-grid {
        margin-top: $indent__l;
    }
}

body.catalog-product-view .content-bottom .products-grid .product-item,
body.catalog-product-view .product-bottom .products-grid .product-item {
    flex: 0 0 50%;
    max-width: 50%;

    @include min-screen($screen__l) {
        max-width: 100%;
    }

    .product-item-info {
        border-color: $brand__gray__color-lighter;

        .product-item-details {
            text-align: center;
            border: none;

            .product-item-main-info .product-item-name {
                min-height: 0;
            }
        }
    }

    .product-item-photo,
    .price-box {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .product-item-photo {
        &::before {
            background: $brand__secondary__color;
        }

        .product-image-container {
            width: 100% !important; //sass-lint:disable no-important
            max-height: 280px;

            .product-image-wrapper {
                height: auto;
                padding-bottom: 0 !important; //sass-lint:disable no-important
            }

            img {
                position: relative;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .product-item-primary .product-item-name a,
    .product-item-name a {
        font-size: $font-size__l;
        font-weight: $font-weight__semibold;
        line-height: 1;
        color: $text__color-muted;
    }

    .price-box {
        margin: $indent__s 0;

        .special-price {
            order: 2;
        }

        .old-price {
            margin-right: $indent__s;

            .price,
            .price-including-tax {
                font-size: $font-size__base;
                font-weight: $font-weight__regular;
                color: $brand__gray__color;
            }
        }

        .price {
            font-size: $font-size__xl;
            font-weight: $font-weight__bold;
            color: $text__color-muted;
        }
    }

    .action.primary {
        padding: 13px $indent__base $indent__base $indent__base;
        font-size: $font-size__base;
        background: $brand__cta__color;

        @include min-screen($screen__xs) {
            font-size: $font-size__l;
        }
    }

    .stock {
        display: none;
    }
}

// New design for product page (HV)
.product-info-bundle {
    padding: 0;
    border: 0;
    @include min-screen($screen__l) {
        @include brand-block();
        border: 0;
    }
    .amrelated-pack-wrapper {
        padding: 0;

        .amrelated-content-wrapper {
            display: flex;
            flex-direction: column;
            border: 1px solid #3498db;
            border-radius: 6px;
            padding: 3px;

            .amrelated-pack-list {
                display: flex;
                flex-direction: column;
                border: $brand__block__border;
                margin: 0;
                border: none;

                .amrelated-pack-item {
                    width: 100%;
                    max-width: 100%;
                    margin: 0;

                    .amrelated-info {
                        display: flex;
                        width: 100%;
                    }
                }
                .amrelated-plus {
                    justify-content: center;
                    padding: 10px;
                    font-size: 3rem;
                    margin-top: -20px;
                    line-height: 0;
                    display: none;
                }

                .amrelated-pack-items {
                    width: 100%;
                    max-width: 100%;
                    border: 0;
                    border-bottom: 1px solid #3498db;;
                }

                .amrelated-photo-container {
                    max-width: 100px;
                    width: 100%;
                    max-height: 100%;
                    height: 100%;
                }

                .amrelated-details {
                    width: 100%;
                    position: relative;

                    .amrelated-bottom {
                        width: 100%;
                        order: 0;

                        .amrelated-name {
                            text-align: left;
                        }
                    }
                    .price-box {
                        position: absolute;
                        bottom: 0;
                        justify-content: end;
                        right: 10px;
                    }
                }
            }
            .amrelated-equal {
                display: none;
            }
            .amrelated-summary {
                width: 100%;
                border: none;

                &-content {
                    display: flex;
                    width: 100%;

                    .amrelated-discount.-summary {
                        left: 0;
                        width: 100%;
                        top: 14px;
                    }
                    .amrelated-price-wrapper {
                        max-height: 120px;
                        border: 0;

                    }
                    .amrelated-tocart-wrapper {
                        .action {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}
