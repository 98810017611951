@import "filter/filter";
@import "filters/filters";

.header {
    .block-search {
        .form.minisearch {
            .actions {
                .action.search {
                    padding: 1.3rem 1rem 1rem;

                    &:before {
                        font-family: $font-family__awesome;
                        content: '\f002';
                        font-size: 1.4em;
                        font-weight: 700;
                    }

                    span {
                        display: none;
                    }
                }
            }
        }
    }
}
.search-autocomplete {
    display: none;
    background: #fff;
    padding: 1rem;
    z-index: 99;
    box-shadow: 0 15px 35px #999;
    border-radius: 6px;
    width: 100% !important; // sass-lint:disable-line no-important
    @include min-screen ($screen__m) {
        margin-top: 20px;
        min-width: 1024px;
        margin-left: -357px;
    }

    @include min-screen ($screen__xl) {
        min-width: 1200px;
        margin-left: -300px;
    }

    .smile-elasticsuite-autocomplete-result {
        @include min-screen ($screen__m) {
            display: flex;
            flex-direction: row;
            column-gap: 3rem;
        }
        .autocomplete-list {
            width: 100%;
            dd {
                display: flex;
                align-items: center;
                padding: 5px;
                margin: 0;
                &:hover {
                    cursor: pointer;
                    background: #f8f8f8;
                }
                .f-fix {
                    display: flex;
                    column-gap: 1rem;
                }
                .price-box {
                    .price-final_price {
                        display: flex;
                        flex-direction: column-reverse;

                        .special-price {
                            margin-top: 0.5rem;

                            .price {
                                font-size: 18px;
                            }
                        }
                    }
                }
                img {
                    width: 120px;
                }
            }
            dt.autocomplete-list-title.title-product {
                @include min-screen ($screen__m) {
                    & ~ dd {
                        border: 1px solid #d9d9d9;
                        margin-top: -1px;
                    }
                }
            }
            dt.autocomplete-list-title.title-category {
                @include min-screen ($screen__m) {
                    margin-bottom: 4rem;
                    & ~ dd {
                        display: block;
                        border-bottom: 1px solid #d9d9d9;
                    }
                }
            }
        }
    }
}
.layered-top-nav-actions {
    button.toggle-filter {
        padding: $spacer--15 $spacer--2;
    }
}
